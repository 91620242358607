import React, { Dispatch, SetStateAction } from 'react'

import Heading from 'components/basics/Heading/Heading'
import Pagination from 'components/blocks/Pagination/Pagination'
import PaginationRangeSelect from 'components/blocks/PaginationRangeSelect/PaginationRangeSelect'
import SortByMenu, { CRUISE_RESULT_SORT_OPTION_VALUES } from './SortByMenu/SortByMenu'
import Text from 'components/basics/Text/Text'

import styles from './ResultsControls.module.scss'
import allContent from 'content/content'

const content = allContent.cruise.resultsPage

type ResultsControlsType = {
    itemsLength: number
    filteredItemsLength: number
    itemsPerPage: number
    setItemsPerPage: Dispatch<SetStateAction<number>>
    onPageChange: (selectedPage: number) => void
    isTop: boolean
    currentPage: number
    sortBy: string
    setSortBy: Dispatch<SetStateAction<CRUISE_RESULT_SORT_OPTION_VALUES>>
}

const ResultsControls: React.FC<ResultsControlsType> = ({
    itemsLength,
    filteredItemsLength,
    itemsPerPage,
    setItemsPerPage,
    onPageChange,
    isTop,
    currentPage,
    sortBy,
    setSortBy,
}) => {
    const pageCount = Math.ceil(filteredItemsLength / itemsPerPage)
    const handlePageClick = (selected: number): void => {
        onPageChange(selected)
    }
    const placement = isTop ? 'top' : 'bottom'

    return (
        <div className={styles.container}>
            {isTop && (
                <div className={styles['header-container']}>
                    <Heading heading='1'>{content.title}</Heading>
                    <Text weight='bold'>{itemsLength}</Text>
                    <Text weight='normal'>
                        {itemsLength === 1
                            ? `${itemsLength}${content.counterTextSingular}${filteredItemsLength}`
                            : `${content.counterTextPlural}${filteredItemsLength}`}
                    </Text>
                </div>
            )}
            <div className={styles['sort-by-menu-container']}>
                <SortByMenu value={sortBy} onChange={setSortBy} />
            </div>
            <div className={styles['pagination-range-select-container']}>
                <PaginationRangeSelect
                    id={`results-pagination-size-select-${placement}`} //added this because it was failing accessibility saying they needed unique IDs but still failing :(
                    value={itemsPerPage}
                    options={[10, 15, 20, 30, 50, 100]} // These options can be customized as needed
                    onChange={(value: number): void => setItemsPerPage(value)}
                />
            </div>
            <div className={styles['pagination-container']}>
                <Pagination
                    numberOfPages={pageCount}
                    onPageChange={handlePageClick}
                    currentPage={currentPage}
                    pageRangeDisplayed={1}
                    size='large'
                />
            </div>
        </div>
    )
}

export default ResultsControls
