import React from 'react'

import Navigation from 'components/sections/admin/NavigationPannel/NavigationPanel'

import styles from './CompanyConfigurationLayout.module.css'
import CompanyConfigurationSection from 'components/sections/admin/CompanyConfiguration/CompanyConfiguration'

type CompanyConfigurationProps = {
    companyTier?: CompanyTierTypes
}

const CompanyConfigurationLayout = ({ companyTier }: CompanyConfigurationProps): JSX.Element => {
    return (
        <div className='general-container'>
            <div className={styles.container}>
                <Navigation companyTier={companyTier} />
                <CompanyConfigurationSection />
            </div>
        </div>
    )
}

export default CompanyConfigurationLayout
