import React, { useEffect, useRef, useState } from 'react'

import Button from 'components/basics/Button/Button'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import ErrorList from 'components/sections/app/ErrorList/ErrorList'
import Heading from 'components/basics/Heading/Heading'
import { useRest } from 'components/hooks/useRest'
import { HTTP_METHODS } from 'utils/constants'

import styles from './SubscriptionManage.module.css'
import allContent from 'content/content'

const content = allContent.admin.subscriptionsPage

type SubscriptionManageProps = { financeSystemId?: string }

const SubscriptionManage: React.FC<SubscriptionManageProps> = ({
    financeSystemId,
}: SubscriptionManageProps) => {
    /** Chargebee Instance is what opens the widget. Requires chargbee script to be loaded. */
    const cbInstance = useRef<any>(null)

    const [settingUp, setSettingUp] = useState(true)
    const [scriptSetupError, setScriptSetupError] = useState(false)

    /** PortalSession for the customer is returned from chargbee (via our api `/chargebee/portal/session`) and passed to the Chargbee Instance to allow the widget to work.
     * Reference API Doc — https://apidocs.chargebee.com/docs/api/portal_sessions?lang=curl#create_a_portal_session */
    const {
        result: portalSessionResponse,
        loading,
        error,
    } = useRest({
        url: process.env.REACT_APP_CONNECT_MANAGER_SERVICE_URL + '/chargebee/portal/session',
        variables: { finance_system_id: financeSystemId },
        source: 'subscription-manage get portal session - api',
        method: HTTP_METHODS.POST,
    })

    const handlePortalFetch = (): void => {
        /** Try to open the portal (widget) if the api has returned a portal session and cbInstance has been fetched from CB script */
        if (cbInstance.current?.setPortalSession && portalSessionResponse) {
            // eslint-disable-next-line no-console
            console.log('GOING TO CALL createChargebeePortal().open()')
            cbInstance.current.setPortalSession(portalSessionResponse)
            cbInstance.current.createChargebeePortal().open()
        } else {
            // eslint-disable-next-line no-console
            console.log('NOT able to call createChargebeePortal().open()')
        }
    }

    /** On component load, initialise Chargebee (assume script has loaded) - then wait and try to get Chargebee instance */
    useEffect(() => {
        /** THIS VAR REACT_APP_CHARGEBEE_SITE is only in DEVELOPMENT and STAGE, not PROD. */
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window?.Chargebee?.init({
            site: process.env.REACT_APP_CHARGEBEE_SITE,
            publishableKey: process.env.REACT_APP_CHARGEBEE_PUBLIC_KEY,
            isItemsModel: true,
        })
        setTimeout(() => {
            try {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const chargebeeInstance = window?.Chargebee?.getInstance() ?? null
                if (chargebeeInstance) {
                    cbInstance.current = chargebeeInstance
                    // eslint-disable-next-line no-console
                    console.log('** GOT cbInstance:', chargebeeInstance)
                    setSettingUp(false)
                } else {
                    setSettingUp(false)
                    setScriptSetupError(true)
                    // eslint-disable-next-line no-console
                    console.log(
                        '** cbInstance not fetched - has Chargbee.init finished, was script not finished loading first?'
                    )
                }
            } catch (error) {
                setScriptSetupError(true)
                setSettingUp(false)
                // eslint-disable-next-line no-console
                console.log('** Chargebee.getInstance() - error:', error)
            }
        }, 800) /** Chargebee still initialising... how long to wait */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /** Trigger auto open - once scrip is loaded and cbInstance fetched, and portalSessionResponse from api returned */
    useEffect(() => {
        if (!settingUp && !loading && !error && portalSessionResponse) {
            // eslint-disable-next-line no-console
            console.log('Auto starting/opening widget!')
            handlePortalFetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingUp, loading, error, portalSessionResponse])

    return (
        <div className={styles.container}>
            <Heading heading='1' title='stuff'>
                {content.title}
            </Heading>
            {settingUp || loading ? (
                <LargeSpinner />
            ) : (
                <Button
                    disabled={!!error || scriptSetupError}
                    onClick={handlePortalFetch}
                    text={content.openPortalButton}
                />
            )}
            {scriptSetupError && (
                <div>
                    <InfoBanner
                        id='script-load-banner'
                        bannerType='error'
                        text='Sorry, Chargebee script has failed to load'
                    />
                </div>
            )}
            {!!error && (
                <ErrorList
                    errorsList={error}
                    source='subscription-manage get portal session - banner'
                />
            )}
        </div>
    )
}

export default SubscriptionManage
