import React, { useRef, useState } from 'react'
import styles from './ImagePicker.module.css'
import { Upload } from 'components/basics/Icon/svgs'
import Text from 'components/basics/Text/Text'
import * as self from './ImagePicker'

export const handleImageUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFile: (v: string) => void,
    resizeTo?: number
): void => {
    const input = event.target

    if (!input.files?.length) {
        setFile('')
        return
    }

    const fileType = input.files[0]?.type || 'image/png'

    const reader = new FileReader()

    reader.onload = (e): void => {
        if (e?.target?.result) {
            const img = new Image()
            if (typeof e.target.result === 'string') {
                img.src = e.target.result
            }
            img.onload = (): void => {
                const canvas = document.createElement('canvas')
                const ctx = canvas.getContext('2d')
                let newWidth = img.width
                let newHeight = img.height

                // Resize image while maintaining aspect ratio
                if (resizeTo && (img.width > resizeTo || img.height > resizeTo)) {
                    const aspectRatio = img.width / img.height
                    if (aspectRatio > 1) {
                        newWidth = resizeTo
                        newHeight = resizeTo / aspectRatio
                    } else {
                        newHeight = resizeTo
                        newWidth = resizeTo * aspectRatio
                    }
                }

                canvas.width = newWidth
                canvas.height = newHeight

                ctx?.drawImage(img, 0, 0, newWidth, newHeight)

                // Convert canvas content to base64 data URL
                const resizedImageDataUrl = canvas.toDataURL(fileType, 0.7) // Adjust the quality as needed

                // Set the preview image
                setFile(resizedImageDataUrl)
            }
        }
    }

    reader.readAsDataURL(input.files[0])
}

export interface ImagePickerProps {
    label?: string // basic label for input
    subText?: string // sub text tu shown under label
    defaultValue?: string | null // value that could be available on initialize, accept url string or base64
    onChange?: (value: string) => void // returned value will be base64
    resizeTo?: number // optional image max size for width and height. If indicated image will be resized depends on it's ratio
}

const ImagePicker: React.FC<ImagePickerProps> = ({
    label,
    subText,
    onChange,
    defaultValue = null,
    resizeTo,
}) => {
    const [file, setFile] = useState<string | null>(defaultValue)

    const inputFile = useRef<HTMLInputElement | null>(null)

    const onImageClick = (): void => {
        inputFile?.current?.click()
    }

    const handleChangeFile = (fileUrl: string): void => {
        if (onChange) onChange(fileUrl)
        setFile(fileUrl)
    }

    return (
        <div className={styles.wrapper}>
            <button onClick={onImageClick} className={styles['img-placeholder']}>
                <span className={'visually-hidden'}>{`${label}-hidden`}</span>
                <div>
                    {file ? (
                        <img
                            alt={label + '_img'}
                            src={file}
                            style={{ width: '100%', height: '100%', maxWidth: 300, maxHeight: 150 }}
                        />
                    ) : (
                        <div className={styles['img-placeholder-content']}>
                            <Upload width={25} height={25} />
                            <Text size='S'>{'Click here to upload image'}</Text>
                            <Text size='XS'>{'.jpg  .png .svg'}</Text>
                        </div>
                    )}
                </div>
                <input
                    aria-label={label}
                    data-testid='image-picker-input'
                    autoComplete='off'
                    onChange={(e): void => {
                        self.handleImageUpload(e, handleChangeFile, resizeTo)
                    }}
                    accept='image/*'
                    type='file'
                    id='file'
                    ref={inputFile}
                    style={{ display: 'none' }}
                />
            </button>
            <div className={styles['label-block']}>
                <Text size='S' weight='bold'>
                    {label}
                </Text>
                <Text>{subText}</Text>
            </div>
        </div>
    )
}

export default ImagePicker
