import React, { useEffect, useState } from 'react'
import classnames from 'classnames'

import Button from 'components/basics/Button/Button'
import Card from 'components/blocks/Card/Card'
import ErrorList from 'components/sections/app/ErrorList/ErrorList'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import Link from 'components/basics/Link/Link'
import Text from 'components/basics/Text/Text'
import {
    AlternativeSailingDate,
    AlternativeSailingDatesFromApi,
    AlternativeSailingDatesFunction,
} from 'api-data-models/AlternativeSailingDatesModel'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { useRest, UseRestOptions } from 'components/hooks/useRest'

import styles from './AlternativeSailingDates.module.scss'
import allContent from 'content/content'

const content = allContent.cruise.sharedSections.alternativeSailingDates

/** findClosestSailingDateIndex: returns the cruiseId that has the closest date to the selected one
 @param { string } selectedDate - Date for which we want to find index of the closest sailing 
 @param { AlternativeSailingDate } alternativeSailDatesData - array of AltenativeSailingDate objects
 @returns { number }
 */
export function findClosestSailingDateId(
    selectedDate: string,
    alternativeSailDatesData: AlternativeSailingDate[]
): string {
    const chosenDateTime = new Date(selectedDate).getTime()
    let closestIndex = 0
    let smallestDifference = Math.abs(
        new Date(alternativeSailDatesData[0].embarkDate).getTime() - chosenDateTime
    )
    let currentDateTime = new Date(alternativeSailDatesData[0].embarkDate).getTime()
    for (let i = 1; i < alternativeSailDatesData.length && currentDateTime < chosenDateTime; i++) {
        currentDateTime = new Date(alternativeSailDatesData[i].embarkDate).getTime()
        const difference = Math.abs(currentDateTime - chosenDateTime)

        if (difference < smallestDifference && currentDateTime < chosenDateTime) {
            smallestDifference = difference
            closestIndex = i
        }
    }
    return alternativeSailDatesData[closestIndex].cruiseId
}

/** scrollToSelectedRow: scrolls to a given row inside AlternativeSailingDates component
 @param { string } rowId - Prefix for a row containing cruiseId
 @param { string } parentId - Id of the parent element - tbody in this case
 @returns { void }
 */

type ScrollToSelectRowVars = {
    rowId: string
    parentId: string
}
export function scrollToSelectedRow(scrollToSelectRowVars: ScrollToSelectRowVars): void {
    const firstRowToDisplay = document.getElementById(scrollToSelectRowVars.rowId)
    const parentElement = document.getElementById(scrollToSelectRowVars.parentId)
    if (firstRowToDisplay && parentElement) {
        const firstRowTopPosition = firstRowToDisplay.offsetTop
        parentElement.scrollTop = firstRowTopPosition
    }
}

const AlternativeSailingDates: React.FC<{
    cruiseId: string
    supplierCode: string
    originallyChosenDate: string
    roundedTopCorners?: boolean
    isStarterTier: boolean
    cruiseAvailableToBook: boolean
}> = ({
    cruiseId,
    supplierCode,
    originallyChosenDate,
    roundedTopCorners = false,
    isStarterTier,
    cruiseAvailableToBook,
}): JSX.Element | null => {
    const [alternativeSailDatesData, setAlternativeSailDatesData] =
        useState<AlternativeSailingDate[]>()
    const tableBodyId = `table-parent${cruiseId}`

    const fetchOptions: UseRestOptions = {
        url:
            process.env.REACT_APP_CRUISE_SEARCH_SERVICE_URL +
            `/alternative_sailings?cruise_id=${cruiseId}&supplier_code=${supplierCode}`,
        source: 'ResultsPage - ALTERNATIVE_SAILINGS',
        method: 'GET',
    }
    const { result, loading, error } = useRest(fetchOptions)
    useEffect(() => {
        if (result) {
            setAlternativeSailDatesData(
                AlternativeSailingDatesFunction(result as AlternativeSailingDatesFromApi[])
            )
        }
    }, [result])

    useEffect(() => {
        if (alternativeSailDatesData && alternativeSailDatesData.length > 4) {
            const closestCruiseId = findClosestSailingDateId(
                originallyChosenDate,
                alternativeSailDatesData
            )
            scrollToSelectedRow({
                rowId: `alt-sail-date-cruiseId-${closestCruiseId}`,
                parentId: tableBodyId,
            })
        }
    }, [alternativeSailDatesData, originallyChosenDate, tableBodyId])

    const alternativeSailingRows = alternativeSailDatesData
        ? alternativeSailDatesData.map((alternativeSailingDate: AlternativeSailingDate) => {
              const selectButton = !cruiseAvailableToBook ? (
                  <Button
                      text={content.buttons.select}
                      type='button'
                      flavour='secondary'
                      disabled={true}
                  />
              ) : (
                  <Link
                      noUnderLine={true}
                      to={`${ROUTES.CRUISE_SAILING}/?cruiseId=${alternativeSailingDate.cruiseId}&supplierCode=${supplierCode}`}
                  >
                      <Button text={content.buttons.select} type='button' flavour='secondary' />
                  </Link>
              )
              return (
                  <tr
                      className={styles['table-body-row']}
                      key={alternativeSailingDate.cruiseId}
                      id={`alt-sail-date-cruiseId-${alternativeSailingDate.cruiseId}`}
                  >
                      <td>
                          <Text>{alternativeSailingDate.embarkDate}</Text>
                      </td>
                      <td>
                          <Text weight='bold'>
                              {alternativeSailingDate.pricing.inside ?? content.notAvailable}
                          </Text>
                      </td>
                      <td>
                          <Text weight='bold'>
                              {alternativeSailingDate.pricing.outside ?? content.notAvailable}
                          </Text>
                      </td>
                      <td>
                          <Text weight='bold'>
                              {alternativeSailingDate.pricing.balcony ?? content.notAvailable}
                          </Text>
                      </td>
                      <td>
                          <Text weight='bold'>
                              {alternativeSailingDate.pricing.suite ?? content.notAvailable}
                          </Text>
                      </td>
                      {!isStarterTier && <td className={styles['table-button']}>{selectButton}</td>}
                  </tr>
              )
          })
        : null

    const tableHeadClassNames = classnames({
        [styles['rounded-top-border-radius']]: roundedTopCorners,
    })

    return (
        <div className={styles['table-container']}>
            {loading && <LargeSpinner text={content.spinnerText} />}
            {error && (
                <ErrorList
                    errorsList={error}
                    source={`Alternative Sailing Dates ${cruiseId}`}
                    isCloseable={true}
                />
            )}
            {!!alternativeSailDatesData && alternativeSailDatesData.length === 0 && (
                <Text weight='bold'>{content.notFound}</Text>
            )}
            {!!alternativeSailDatesData && alternativeSailDatesData.length > 0 && (
                <div className={styles.container}>
                    <Card>
                        <table className={styles['table']}>
                            <thead className={tableHeadClassNames}>
                                <tr>
                                    <td>
                                        <Text weight='bold' color='white'>
                                            {content.date}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text weight='bold' color='white'>
                                            {content.grades.inside}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text weight='bold' color='white'>
                                            {content.grades.outside}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text weight='bold' color='white'>
                                            {content.grades.balcony}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text weight='bold' color='white'>
                                            {content.grades.suite}
                                        </Text>
                                    </td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody id={tableBodyId}>{alternativeSailingRows}</tbody>
                        </table>
                    </Card>
                </div>
            )}
        </div>
    )
}

export default AlternativeSailingDates
