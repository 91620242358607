import { format } from 'date-fns'
import {
    DATE_FORMAT_USA_LONG,
    DATE_FORMAT_USA_SHORT,
    DATE_FORMAT_USA_LONG_WITH_TIME,
    DATE_FORMAT_Y_M_D_HYPHEN,
    DATE_FORMAT_D_M_Y_SLASH,
} from './constants'

type CheckDateIsValidProps = {
    day: number
    month: number
    year: number
}

type TimeFormatsType = 'short' | 'long' | 'longWithTime' | 'Y_M_D_HYPHEN' | 'D_M_Y_SLASH'

export const TIME_FORMATS: {
    SHORT: TimeFormatsType
    LONG: TimeFormatsType
    LONG_WITH_TIME: TimeFormatsType
} = {
    SHORT: 'short',
    LONG: 'long',
    LONG_WITH_TIME: 'longWithTime',
}

export const checkDateIsValid = ({ day, month, year }: CheckDateIsValidProps): boolean => {
    // Return false if day or year are below 1, or month is outside 1-12
    if (day < 1 || month < 1 || month > 12 || year < 1) return false

    /** For new Date(yy, mm, dd), the month param is 0-11 for Jan to Dec */
    const monthIndex = month - 1

    // Return false if value passed in for day invalid for the month chosen i.e. > 28 for Feb (non-leap year)
    const lastDayOfMonth = new Date(year, monthIndex + 1, 0).getDate() // Use day index 0 for next month to get last day of input month.

    // Return true if day is less than or equal to days in month
    return day <= lastDayOfMonth
}

/** NOTE: this function avoids date-fn issues with local timezone by using date-fn parseISO:
 * https://stackoverflow.com/questions/48172772/time-zone-issue-involving-date-fns-format
 * IT DOES NOT TRANSFORM THE DATE TO THE LOCAL DATE*/
export const getFormattedDate = (
    inputDateString: string,
    variant: TimeFormatsType = TIME_FORMATS.LONG
): string => {
    const newDate = new Date(inputDateString)
    const isValid = newDate instanceof Date && !isNaN(Number(newDate))
    if (!isValid) return 'Invalid date'

    const formattedDate = newDate.valueOf() + newDate.getTimezoneOffset() * 60 * 1000

    switch (variant) {
        case 'D_M_Y_SLASH':
            return format(newDate, DATE_FORMAT_D_M_Y_SLASH)
        case 'Y_M_D_HYPHEN':
            return format(newDate, DATE_FORMAT_Y_M_D_HYPHEN)
        case 'short':
            return format(formattedDate, DATE_FORMAT_USA_SHORT)
        case 'long':
        default:
            return format(formattedDate, DATE_FORMAT_USA_LONG)
    }
}

export const getFormattedDateTimeToLocalTimezone = (
    dateTime: string,
    dateFormat = DATE_FORMAT_USA_LONG_WITH_TIME
): string => {
    // TODO: transform to the datetime in user local timezone
    const newDate = new Date(dateTime)
    const isValid = newDate instanceof Date && !isNaN(Number(newDate))
    if (!isValid) return dateTime
    const formattedDateTime = format(newDate, dateFormat)
    return formattedDateTime
}

export const formatDateOnSubmit = ({
    day,
    month,
    year,
}: {
    day: string
    month: string
    year: string
}): string => {
    if (day && month && year) {
        const formattedDay = day.length === 1 ? `0${day}` : day
        const formattedMonth = month.length === 1 ? `0${month}` : month
        return `${year}-${formattedMonth}-${formattedDay}`
    }
    return ''
}

export const calculateHourDifference = (
    targetDate: Date | string,
    startDate = new Date()
): number => {
    const target = new Date(targetDate)
    const now = new Date(startDate)
    const differenceMs = +target - +now
    // Convert milliseconds to hours
    const differenceHours = Math.floor(differenceMs / (1000 * 60 * 60))
    return differenceHours
}
