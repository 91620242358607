import React, { useEffect, useState } from 'react'
import { datadogLogs } from '@datadog/browser-logs'

import ErrorList from 'components/sections/app/ErrorList/ErrorList'
import Icon from 'components/basics/Icon/Icon'
import InlineAccordion from 'components/blocks/Accordions/InlineAccordion/InlineAccordion'
import SpinnerCruiseLogo from 'components/blocks/SpinnerCruiseLogo/SpinnerCruiseLogo'
import Spacing from 'components/basics/Spacing/Spacing'
import Text from 'components/basics/Text/Text'

import { CabinGrade } from 'api-data-models/cruise-detail/CabinGradesModel'
import { RateCode } from 'api-data-models/cruise-detail/RateCodesModel'
import {
    BreakdownPerPassenger as BreakdownPerPassengerCruiseDetail,
    Breakdown,
    getBreakDownItemsByPassenger,
} from 'api-data-models/cruise-detail/BreakdownModel'
import { BreakdownPerPassenger } from 'api-data-models/CabinContentModel'
import {
    capitalizeEachWord,
    combineCurrencyAndPrice,
    insertDecimal2CharsFromEnd,
} from 'utils/string-helpers'
import { getRefundPolicyBadgeText } from '../RateCodes/RateCodes'
import { useRest, UseRestOptions } from 'components/hooks/useRest'
import { usePassengersInfo } from 'components/hooks/usePassengersInfo'
import { CURRENCY_CODES, SupplierCodes } from 'utils/constants'

import styles from './PriceBreakdownView.module.css'
import allContent from 'content/content'

const content = allContent.cruise.sailingPage.cabinGradesView.breakdown

export interface PriceBreakdownViewProps {
    currency: string
    breakdownPerPassenger: BreakdownPerPassenger | BreakdownPerPassengerCruiseDetail // remove BreakdownPerPassenger when all endpoints are moved to REST
    totalGrossPrice: string
    commission: string
    cabinGradeCode: string
    rateCode: string
    cabinGradeDescription: string
    rateCodeDescription: string
    priceProps?: {
        military?: boolean
        residency?: boolean
        wifi?: boolean
        refundPolicy?: RefundPolicyType
        onBoardCredits?: number | string
    }
}

export type PriceBreakdownProps = {
    cruiseId: string
    supplierCode: string
    cabinGradeData: CabinGrade
    rateCodeData: RateCode
    setIsLoading?: (value: boolean) => void
}

export const PriceBreakdown = ({
    cruiseId,
    supplierCode,
    rateCodeData,
    cabinGradeData,
    setIsLoading,
}: PriceBreakdownProps): JSX.Element => {
    const { passengerConfigurationDataRestRequestBody } = usePassengersInfo()
    const [breakdownPerPassenger, setBreakdownPerPassenger] = useState<null | Record<
        string,
        Breakdown
    >>(null)
    const fetchOptions: UseRestOptions = {
        url:
            process.env.REACT_APP_CRUISE_DETAIL_SERVICE_URL +
            `/cruise/${cruiseId}/rates/${rateCodeData.code}/cabin-grades/${cabinGradeData.cabinGradeCode}/price-information?supplier_code=${supplierCode}`,
        source: 'SailingPage - PRICE BREAKDOWN',
        method: 'POST',
        variables: passengerConfigurationDataRestRequestBody,
    }

    const { result, loading, error } = useRest(fetchOptions)

    useEffect(() => {
        if (result?.breakdown_items) {
            const breakdownItems = result.breakdown_items
            setBreakdownPerPassenger(getBreakDownItemsByPassenger(breakdownItems))
        }
    }, [result])

    useEffect(() => {
        if (setIsLoading) setIsLoading(loading)
    }, [setIsLoading, loading])

    if (loading) {
        return (
            <SpinnerCruiseLogo
                text={content.breakdownLogoSpinner}
                supplierCode={supplierCode as SupplierCodes}
            />
        )
    }

    if (error) {
        return <ErrorList errorsList={error} source='CabinPage-PriceBreakdown' />
    }

    if (breakdownPerPassenger && result) {
        return (
            <PriceBreakdownView
                commission={insertDecimal2CharsFromEnd(result?.commission)}
                breakdownPerPassenger={breakdownPerPassenger}
                currency={CURRENCY_CODES[result?.currency]}
                totalGrossPrice={insertDecimal2CharsFromEnd(
                    breakdownPerPassenger?.totals?.GROSS?.price
                )}
                cabinGradeCode={cabinGradeData.cabinGradeCode}
                rateCode={rateCodeData.code}
                cabinGradeDescription={cabinGradeData.description}
                rateCodeDescription={rateCodeData.description}
                priceProps={{
                    military: rateCodeData?.military,
                    residency: rateCodeData?.residency,
                    refundPolicy: rateCodeData?.refundPolicy,
                    onBoardCredits: result?.total_obc_price ?? 0,
                }}
            />
        )
    }
    return <div /> // needed for the ansyc state update of priceData which is called after loading becomes false
}

const PriceBreakdownView: React.FC<PriceBreakdownViewProps> = ({
    breakdownPerPassenger,
    totalGrossPrice,
    commission,
    currency,
    cabinGradeCode,
    rateCode,
    cabinGradeDescription,
    rateCodeDescription,
    priceProps,
}) => {
    const renderRefundPolicy = getRefundPolicyBadgeText(priceProps?.refundPolicy)
    const renderOnBoardCredits = priceProps?.onBoardCredits
        ? `${currency}${insertDecimal2CharsFromEnd(priceProps?.onBoardCredits)}`
        : null
    const showPriceIncluded =
        priceProps?.military || priceProps?.residency || renderRefundPolicy || renderOnBoardCredits

    const passengers = Object.keys(breakdownPerPassenger || [])
    const emptyPassengerCells =
        passengers.length !== 0
            ? Array(passengers.length - 1)
                  .fill(null)
                  .map((_, index) => <td key={`${passengers[index]}-empty`} />)
            : null

    const userContext = datadogLogs.getGlobalContext()

    try {
        return (
            <>
                <div className={styles['breakdown-header-wrapper']}>
                    <div className={styles['breakdown-header-column']}>
                        <Text weight='bold'>{content.rateCode}</Text>
                        <Text weight='bold'>
                            {rateCode} <Text>{rateCodeDescription}</Text>
                        </Text>
                    </div>
                    <div className={styles['breakdown-header-column']}>
                        <Text weight='bold'>{content.grade}</Text>
                        <Text weight='bold'>
                            {cabinGradeCode} <Text>{cabinGradeDescription}</Text>
                        </Text>
                    </div>
                    {showPriceIncluded ? (
                        <div className={styles['breakdown-header-wrapper']}>
                            <Text weight='bold'>{content.includedInPrice}</Text>
                            <div className={styles['breakdown-header-included']}>
                                {renderOnBoardCredits ? (
                                    <div className={styles['breakdown-header-included-item']}>
                                        <Icon iconName='OnBoardCredit' />
                                        <Text size='XS' weight='bold'>
                                            {renderOnBoardCredits}
                                        </Text>
                                        <Text size='XS'>{content.onBoardCredit}</Text>
                                    </div>
                                ) : null}
                                {renderRefundPolicy && (
                                    <div className={styles['breakdown-header-included-item']}>
                                        <Icon iconName='NonRefundable' />
                                        <Text size='XS'>{renderRefundPolicy}</Text>
                                    </div>
                                )}
                                {priceProps?.military && (
                                    <div className={styles['breakdown-header-included-item']}>
                                        <Icon iconName='MilitaryRate' />
                                        <Text size='XS'>{content.militaryRate}</Text>
                                    </div>
                                )}
                                {priceProps?.residency && (
                                    <div className={styles['breakdown-header-included-item']}>
                                        <Icon iconName='ResidentialRate' />
                                        <Text size='XS'>{content.residentialRate}</Text>
                                    </div>
                                )}
                                {priceProps?.wifi && (
                                    <div className={styles['breakdown-header-included-item']}>
                                        <Icon iconName='WiFi' />
                                        <Text size='XS'>{content.wifi}</Text>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : null}
                </div>
                <table className={styles['breakdown-table']}>
                    <thead>
                        <tr>
                            <th scope='col' />
                            {passengers.map((passengerNumber) => {
                                const label =
                                    passengerNumber === 'totals'
                                        ? content.totalCol
                                        : `${content.passenger} ${passengerNumber}`
                                return (
                                    <th key={passengerNumber} scope='col'>
                                        <Text weight='bold'>{label}</Text>
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody key='body'>
                        {Object.keys(breakdownPerPassenger[passengers[0]]).map((fareTypeName) => {
                            // Passenger number 0 contains the totals (of all passengers) for each fare type that make up the other passenger breakdowns
                            // SKIP 'GROSS' as we place it manually at the bottom of the list
                            if (fareTypeName !== 'GROSS') {
                                return (
                                    <tr key={fareTypeName}>
                                        <th>
                                            {capitalizeEachWord(
                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore
                                                breakdownPerPassenger?.[passengers[0]][fareTypeName]
                                                    .name
                                            )}
                                        </th>
                                        {passengers.map((passengerNumber) => {
                                            const price =
                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore
                                                breakdownPerPassenger[passengerNumber][fareTypeName]
                                                    ?.price
                                            return (
                                                <td key={passengerNumber + fareTypeName}>
                                                    {price
                                                        ? combineCurrencyAndPrice(price, currency)
                                                        : '-'}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            } else {
                                return null
                            }
                        })}
                        <tr key='GROSS'>
                            <th>Gross</th>
                            {passengers.map((passengerNumber) => {
                                return (
                                    <td key={passengerNumber + 'GROSS'}>
                                        {currency}
                                        {breakdownPerPassenger[passengerNumber].GROSS?.price}
                                    </td>
                                )
                            })}
                        </tr>
                        <tr key='total'>
                            <th>{content.totalRow}</th>
                            {emptyPassengerCells}
                            <td>
                                {currency}
                                {totalGrossPrice}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <InlineAccordion
                    baseId={content.commission}
                    title={
                        <Text size='S' weight='bold'>
                            {content.agentViewAccordionTitle}
                        </Text>
                    }
                >
                    <Text className={styles['accordion-text']}>
                        <div>{content.commission}</div>
                        <div>
                            {currency}
                            {commission}
                        </div>
                    </Text>
                </InlineAccordion>
                <Spacing size='triple' />
            </>
        )
    } catch (error: any) {
        datadogLogs.logger.error('PriceBreakdownView.tsx js Error', { userContext }, error)
        return <p>{content.noData}</p>
    }
}

export default PriceBreakdownView
