import React, { useState } from 'react'

import Button from 'components/basics/Button/Button'
import Card from 'components/blocks/Card/Card'
import ErrorList from 'components/sections/app/ErrorList/ErrorList'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import Link from 'components/basics/Link/Link'
import TablePagination from 'components/blocks/TablePagination/TablePagination'
import Text from 'components/basics/Text/Text'
import SendQuoteModal, { QuoteTemplateDynamicFields } from '../SendQuoteModal/SendQuoteModal'
import { Order } from 'api-data-models/order/AllOrdersContentModel'
import { ROUTES } from 'components/sections/app/AppRoutes'

import styles from './AllOrdersLayout.module.scss'
import allContent from 'content/content'

const content = allContent.order.allOrdersPage
const tableContent = content.tableContent

type PaginationValues = 5 | 10 | 15 | 20 | 30 | 50 | 100
const tablePaginationOptions: PaginationValues[] = [5, 10, 15, 20, 30, 50, 100]

const OrdersTable: React.FC<{
    orders: Order[]
    hasSupplierRef?: boolean
    onSendQuoteModalOpen?: (order: Order) => void
}> = ({ orders, hasSupplierRef, onSendQuoteModalOpen }): JSX.Element | null => {
    const currentPageOfOrders = [...orders]

    return currentPageOfOrders.length ? (
        <table className={styles['table']}>
            <thead>
                <tr>
                    <th>
                        <Text weight='bold'>{tableContent.reference}</Text>
                    </th>
                    <th>
                        {hasSupplierRef ? (
                            <Text weight='bold'>{tableContent.dateBooked}</Text>
                        ) : (
                            <Text weight='bold'>{tableContent.dateCreated}</Text>
                        )}
                    </th>
                    <th>
                        <Text weight='bold'>{tableContent.supplier}</Text>
                    </th>
                    <th>
                        <Text weight='bold'>{tableContent.departureDate}</Text>
                    </th>
                    <th>
                        <Text weight='bold'>{tableContent.passenger}</Text>
                    </th>
                    <th>
                        <Text weight='bold'>{tableContent.total}</Text>
                    </th>
                    {hasSupplierRef && (
                        <th>
                            <Text weight='bold'>{tableContent.imported}</Text>
                        </th>
                    )}
                    <th />
                </tr>
            </thead>
            <tbody>
                {currentPageOfOrders.map((order: Order) => {
                    const redirectUrl = `${
                        order.isImported ? ROUTES.ORDER_IMPORT : ROUTES.ORDER
                    }/?orderId=${order.orderId}`

                    return (
                        <tr key={order.orderId}>
                            <td>
                                <Text weight='bold'>{order.trimmedOrderId}</Text>
                            </td>
                            {hasSupplierRef ? (
                                <td>{order.bookedAt}</td>
                            ) : (
                                <td>{order.createdAt}</td>
                            )}
                            <td width={250}>{order.supplierName}</td>
                            <td>{order.departureDate}</td>
                            <td>{order.passengerContactDetailsLastname}</td>
                            <td>
                                <Text font='mono'>
                                    {order.currencySymbol}
                                    {order.totalPrice}
                                </Text>
                            </td>
                            {hasSupplierRef && (
                                <th>
                                    <Text weight='bold'>{order.isImported ? 'Yes' : '-'}</Text>
                                </th>
                            )}
                            <td style={{ padding: 0 }}>
                                {!hasSupplierRef ? (
                                    <div className={styles['links-wrapper']}>
                                        <Link to={redirectUrl}>
                                            <Button
                                                flavour='text'
                                                type='button'
                                                text={tableContent.viewQuote}
                                            />
                                        </Link>
                                        <Button
                                            flavour='text'
                                            type='button'
                                            onClick={(): void => {
                                                onSendQuoteModalOpen && onSendQuoteModalOpen(order)
                                            }}
                                            text={tableContent.emailQuote}
                                        />
                                    </div>
                                ) : (
                                    <Link to={redirectUrl}>
                                        <Button
                                            flavour='text'
                                            type='button'
                                            text={tableContent.orderLinkText}
                                        />
                                    </Link>
                                )}
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    ) : null
}

const AllOrdersLayout: React.FC<{
    loading: boolean
    error: CustomApiError[] | null
    orders: Order[]
    isBooked: boolean
    pageSize: number
    pageNumber: number
    pagesTotal: number
    setPageSize: (value: number) => void
    setPageNumber: (value: number) => void
}> = ({
    loading,
    error,
    orders,
    isBooked,
    pagesTotal,
    setPageNumber,
    pageNumber,
    pageSize,
    setPageSize,
}): JSX.Element => {
    const [isSendQuoteModalOpen, setIsSendQuoteModalOpen] = useState(false)
    const [quoteTemplateData, setQuoteTemplateData] = useState<QuoteTemplateDynamicFields | null>(
        null
    )

    const handleSendQuoteModalOpen = (order: Order): void => {
        setIsSendQuoteModalOpen(true)
        setQuoteTemplateData(order.templateData)
    }

    const loaderHeight = pageSize * 59 + 56.5 /** pageSize * each line height + header height */

    return (
        <div>
            {error && <ErrorList errorsList={error} source='all-orders-page' />}
            {!error && orders && (
                <div className={styles.content}>
                    <Card withoutBottomBorder>
                        {loading && (
                            <LargeSpinner
                                text={isBooked ? content.fetchingOrder : content.fetchingQuote}
                                minHeight={loaderHeight}
                            />
                        )}
                        {orders.length > 0 ? (
                            <OrdersTable
                                orders={orders}
                                hasSupplierRef={isBooked}
                                onSendQuoteModalOpen={
                                    isBooked ? undefined : handleSendQuoteModalOpen
                                }
                            />
                        ) : (
                            <Text className={styles['text']}>
                                {isBooked
                                    ? tableContent.noBookedOrdersFoundText
                                    : tableContent.noUnfulfilledOrdersFoundText}
                            </Text>
                        )}
                        <TablePagination
                            id={isBooked ? 'bookedOrders' : 'quotes'}
                            page={pageNumber}
                            itemsPerPage={pageSize}
                            totalPages={pagesTotal}
                            onPageChange={setPageNumber}
                            onItemsPerPageChange={setPageSize}
                            className={styles['orders-pagination']}
                            itemsPerPageOptions={tablePaginationOptions}
                            paginationType='full'
                        />
                    </Card>
                </div>
            )}
            <SendQuoteModal
                isOpen={isSendQuoteModalOpen}
                onClose={(): void => setIsSendQuoteModalOpen(false)}
                returnFocusId={'sendQuoteButton'}
                templateData={quoteTemplateData}
            />
        </div>
    )
}

export default AllOrdersLayout
