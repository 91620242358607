import React, { useState, useEffect, createContext } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom'
import { Auth } from '@aws-amplify/auth'
import { Helmet } from 'react-helmet'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import { CognitoUser } from 'amazon-cognito-identity-js'

import ErrorBoundary from 'components/blocks/ErrorBoundary/ErrorBoundary'
import GenericLayout from 'components/layouts/app/GenericLayout/GenericLayout'
import { getReactAppToggles } from 'utils/get-feature-toggles'
import { emptyUserData, extractCognitoFields } from 'utils/cognito-helpers/extract-cognito-fields'
import AppRoutes, {
    ROUTES,
    ROUTES_NONE_LOGGED_IN_USER_CAN_ACCESS,
} from 'components/sections/app/AppRoutes'
import { VITALLY_EVENTS, USER_ROLES, EMAILS_NOT_TO_SESSION_RECORD } from 'utils/constants'
import CustomerSuccess, {
    CustomerSuccessUserData,
} from 'services/customerSuccess/customerSuccess.service'
import { UserAnalytics } from 'services/userAnalytics/userAnalytics.service'
import { extractGlobalContextUserData } from 'utils/user-data-helpers/extract-user-data-fields'
import {
    APICruisesMetaData,
    CruisesMetaData,
    getCruisesMetaData,
} from './api-data-models/CruisesContentModel'
import callApi, { CallApiOptions } from './services/callApi/callApi.service'

import content from './content/content'

const featureToggles = getReactAppToggles(
    process.env.REACT_APP_FEATURE_TOGGLES ? process.env.REACT_APP_FEATURE_TOGGLES : `"{}"`
)
export const FeatureToggleContext = createContext(featureToggles)

function App({
    apiClient,
}: Readonly<{ apiClient: ApolloClient<NormalizedCacheObject> }>): JSX.Element {
    const navigate: NavigateFunction = useNavigate()
    const [userData, setUserData] = useState<GlobalContextUserData>(emptyUserData)
    const [isFetchingUserData, setIsFetchingUserData] = useState<boolean>(true)
    const location = useLocation().pathname

    /** Variables for use within App.tsx and AppRoutes only to set up Vitally, datadog rum, googleAnalytics setup.
     *  If userData is needed in lower components use datadog.global context
     *  (except maybe AppRoutes where these are passed as 'allData' and used to drive which page the user should be on). */
    const {
        // allData: userContext,
        companyName,
        companyTier,
        financeSystemId,
        tenantId,
        userEmail,
        userId,
        userName,
        userRoles,
        // userExpiry // DO NOT USE FROM userData state - userExpiry doesn't get updated when session is refreshed, use datadog global context
    } = extractGlobalContextUserData(userData)
    // -----------------------------------------------------------------------------------------------------------------
    /** Function for logging out user and clean up app state */
    function cleanUpAppOnLogout(): void {
        Auth.signOut().then(() => {
            datadogLogs.setGlobalContext({}) // clear datadog user context
            datadogRum.setUser({ id: '', name: '', email: '', plan: '', tenantId: '' }) // clear datadog user
            setUserData(extractCognitoFields(emptyUserData)) // set app state userData to 'un-fetched' state of undefined
        })
    }

    // -----------------------------------------------------------------------------------------------------------------

    /** On APP LOAD, if cognito Auth has valid user load that user to app state */
    useEffect(() => {
        /** On load of app we check if user was logged in already - refreshed the page */
        Auth.currentAuthenticatedUser()
            .then((currentAuthenticatedUser: CognitoUser) => {
                const userData = extractCognitoFields(currentAuthenticatedUser)
                setUserData(userData)
                datadogLogs.setGlobalContext(userData)
                setIsFetchingUserData(false)
            })
            .catch((error) => {
                setIsFetchingUserData(false)
                /** Go to LOGIN Page - unless page doesn't require it */
                if (!ROUTES_NONE_LOGGED_IN_USER_CAN_ACCESS.includes(location)) {
                    navigate(ROUTES.LOGIN)
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // only want this to trigger on app load - do not add navigate (re-running this on renders will block pages that can be used before logging on - forgot password etc.)

    // -----------------------------------------------------------------------------------------------------------------

    /** Setup DataDog Rum  - need tenantId */
    useEffect(() => {
        if (tenantId && datadogRum.getUser()?.id !== userId && userEmail) {
            datadogRum.setUser({
                id: userId,
                name: userName,
                email: userEmail,
                userRoles: userRoles,
                companyTier: companyTier,
                tenantId: tenantId,
                companyName: companyName,
            })

            /** STOP recording BugBug sessions */
            if (EMAILS_NOT_TO_SESSION_RECORD.includes(userEmail)) {
                datadogRum.stopSessionReplayRecording()
            }
        }
    }, [companyName, companyTier, tenantId, userRoles, userId, userName, userEmail])

    /** Setup Analytics User - need userId */
    useEffect(() => {
        if (userId) UserAnalytics.sendUserId(userId)
    }, [userId])

    /** Setup Analytics Tenant - need tenantId */
    useEffect(() => {
        if (tenantId) UserAnalytics.sendCustomProperty('tenant_id', tenantId)
    }, [tenantId])

    /** Setup Analytics FinanceId - need financeSystemId */
    useEffect(() => {
        if (financeSystemId) UserAnalytics.sendCustomProperty('finance_system_id', financeSystemId)
    }, [financeSystemId])

    /** Setup CustomerSuccess - must have FinanceId*/
    useEffect(() => {
        if (financeSystemId) {
            CustomerSuccess.logAccount({
                tenantInfo: {
                    financeSystemId,
                    companyName,
                    companyTier: companyTier ?? '',
                    id: tenantId,
                },
            })
            const userData: CustomerSuccessUserData = {
                userName,
                userEmail,
                userId,
                companyTier: companyTier,
                companyName: companyName,
            }
            CustomerSuccess.logUser(userData)
            CustomerSuccess.survey()
            CustomerSuccess.track({
                eventName: VITALLY_EVENTS.APP_LOAD,
                userId, // Need to pass userId because the service gets undefined from global user context on app load. TODO: check still true
            })
        }
    }, [companyName, companyTier, tenantId, userId, userName, userEmail, financeSystemId])

    // -----------------------------------------------------------------------------------------------------------------
    // Data for search bar auto-suggest
    const [metaDataFetched, setMetaDataFetched] = useState<boolean>(false)
    const [metaDataResult, setMetaDataResult] = useState<Record<string, any> | undefined>(undefined)

    const [cruisesMetaData, setCruisesMetaData] = useState<CruisesMetaData>({
        allDeparturePorts: [],
        allArrivalPorts: [],
        allCountries: [],
        allPorts: [],
        allShips: [],
        allSuppliers: [],
        allUnPorts: [],
        allRegions: [],
        numberOfResults: 0,
    })

    const fetchOptions: CallApiOptions = {
        url: process.env.REACT_APP_CRUISE_SEARCH_SERVICE_URL + '/metadata',
        method: 'GET',
        cache: true,
        cacheMaxAge: 60 * 60 * 3, // 3 hours cache is in seconds not ms
        source: 'MetaData',
    }

    if (!!userId && companyTier && !metaDataFetched) {
        callApi(fetchOptions)
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                setMetaDataFetched(true)
                if (data) {
                    setMetaDataResult(data)
                }
            })
    }

    useEffect(() => {
        if (metaDataResult && metaDataResult?.numberOfResults > 0) {
            setCruisesMetaData(getCruisesMetaData(metaDataResult as APICruisesMetaData))
        }
    }, [metaDataResult])

    // -----------------------------------------------------------------------------------------------------------------

    return (
        <ErrorBoundary cleanUpAppOnRestart={cleanUpAppOnLogout}>
            <Helmet>
                <title>{content.app.tabTitle}</title>
                {userRoles.includes(USER_ROLES.ADMIN) &&
                    featureToggles.TURN_ON_SUBSCRIPTION_MANAGEMENT && (
                        <script src='https://js.chargebee.com/v2/chargebee.js' />
                    )}
            </Helmet>
            <GenericLayout
                apiClient={apiClient}
                logUserOut={cleanUpAppOnLogout}
                userData={userData}
                isFetchingUserData={isFetchingUserData}
            >
                <AppRoutes
                    apiClient={apiClient}
                    cleanUpAppOnLogout={cleanUpAppOnLogout}
                    cruisesMetaData={cruisesMetaData}
                    setUserData={setUserData}
                    userData={userData}
                    isFetchingUserData={isFetchingUserData}
                />
            </GenericLayout>
        </ErrorBoundary>
    )
}

export default App
