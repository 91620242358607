import { capitalizeEachWord, formatPricePenceToPounds } from 'utils/string-helpers'

export type DisplayAs = 'PERPERSON' | 'PERCABIN' | 'PerPassenger' | 'PerCabin' // not used currently
export type PriceType = 'PERPERSON' | 'PERCABIN' | 'PerPassenger' | 'PerCabin' // types to define total price (need get rid of old graphql types)
export type ExtraType = 'SPECIALSERVICE' | 'OPTIONALEXTRA' | 'FARECODE'
export type SubExtraType =
    | 'MEDICAL'
    | 'OCCASION'
    | 'DININGSERVICES'
    | 'HOUSEKEEPING'
    | 'SPECIALSERVICE'
    | 'UPGRADE'
    | 'GRATUITIES'
    | 'INSURANCE'
export type CurrencyEnum = 'GBP' | 'USD' | 'AUD' // TODO: think we have this elsewhere... should probably have a global currency type?

export type OptionalExtra = {
    code: string
    description: string
    renderName: string
    displayAs: DisplayAs
    name: string
    prices: {
        amount: number
        currency: CurrencyEnum // TODO: rename this field 'currencyCode' because its not clear once using in components if the value is the symbol or code otherwise
        pricePer: PriceType
        rules: {
            dateRequired: boolean
            maxAge: number | null
            minAge: number | null
            travellersAvail: number[]
        }
    }[]
    subType: SubExtraType
    type: ExtraType
    formattedPrice?: string
    totalPrice?: number
}

export type OptionalExtraFromApi = {
    description: string
    name: string
    code: string
    display_as: string
    type: string
    sub_type: string
    prices: [
        {
            amount: number
            currency: string
            rules: {
                max_age: number
                min_age: number
                date_required: boolean
                travellers_avail: number[]
            }
            price_per: string
        },
    ]
}
export type OptionalExtrasModel = {
    optionalExtras: {
        General: OptionalExtra[]
        Insurance: OptionalExtra[]
    }
}
export const OptionalExtrasModelTransformFunction = (
    optionalExtrasApiData: OptionalExtraFromApi[]
): OptionalExtrasModel => {
    const optionalExtras =
        optionalExtrasApiData?.map((optionalExtra) => ({
            code: optionalExtra.code,
            description: optionalExtra.description,
            renderName: capitalizeEachWord(optionalExtra.name),
            displayAs: optionalExtra.display_as.toUpperCase() as DisplayAs,
            name: optionalExtra.name,
            prices: optionalExtra.prices.map((price) => ({
                amount: price.amount,
                currency: price.currency as CurrencyEnum,
                pricePer: price.price_per.toUpperCase() as PriceType,
                rules: {
                    dateRequired: price.rules?.date_required,
                    maxAge: price.rules?.max_age,
                    minAge: price.rules?.min_age,
                    travellersAvail: price.rules?.travellers_avail,
                },
            })),
            subType: optionalExtra.sub_type?.toUpperCase() as SubExtraType,
            type: optionalExtra.type.toUpperCase() as ExtraType,
            formattedPrice: formatPricePenceToPounds(optionalExtra.prices[0].amount),
            totalPrice: optionalExtra.prices[0].amount,
        })) || []
    const extraInsurance = optionalExtras?.filter((extra) => extra.subType === 'INSURANCE')
    const extraOther = optionalExtras?.filter((extra) => extra.subType !== 'INSURANCE')
    return {
        optionalExtras: {
            General: extraOther,
            Insurance: extraInsurance,
        },
    }
}
