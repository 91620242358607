import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import Breadcrumb from 'components/basics/Breadcrumb/Breadcrumb'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import OrderImportLayout from 'components/layouts/order/OrderImportLayout/OrderImportLayout'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { CruiseProduct, OrderContent } from 'api-data-models/order/OrderContentModel'
import CustomerSuccess from 'services/customerSuccess/customerSuccess.service'
import { VITALLY_EVENTS } from 'utils/constants'

import allContent from 'content/content'
import { useRest } from 'components/hooks/useRest'
import { orderServiceUrls } from 'utils/order-service-urls'
import { convertKeysToDirectCase } from 'utils/camel-case-helpers'

const content = allContent.order.orderPage
const breadcrumbContent = allContent.app.breadcrumbs

const OrderImportPage: React.FC = (): JSX.Element => {
    /** parse query params for api call below */
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const orderId = queryParams.get('orderId') ?? ''

    const navigate = useNavigate()

    const hasCorrectParams = Boolean(orderId)

    const [orderResult, setOrderResult] = useState<OrderContent | undefined>()
    const {
        result: getOrderResult,
        loading: getOrderLoading,
        error: getOrderError,
    } = useRest({
        url: orderServiceUrls.getOrder(orderId),
        method: 'GET',
        source: 'OrderPage - GET_ORDER',
    })

    useEffect(() => {
        if (getOrderResult && !getOrderLoading) {
            const orderResultParsed = getOrderResult && convertKeysToDirectCase(getOrderResult)
            const order = new OrderContent(orderResultParsed)
            /** If order isn't imported then we assume it's just a quote/booked order */
            if (order.isImported) {
                const product = order.orderItem.product as CruiseProduct
                CustomerSuccess.track({
                    properties: { supplierCode: product.supplierCode, orderId: orderId },
                    eventName: VITALLY_EVENTS.IMPORT_BOOKING,
                })
                setOrderResult(order)
            } else {
                navigate(`${ROUTES.ORDER}/?orderId=${orderId}`)
            }
        }
    }, [getOrderLoading, getOrderResult, orderId, navigate])

    const breadcrumbItems = [
        { text: breadcrumbContent.search, url: ROUTES.CRUISE_SEARCH },
        { text: breadcrumbContent.allOrders, url: ROUTES.ALL_ORDERS },
        { text: breadcrumbContent.import },
    ]

    return (
        <div className='general-container'>
            <Breadcrumb urlList={breadcrumbItems} />
            {getOrderLoading && <LargeSpinner text={content.fetchingOrder} />}
            {getOrderError && (
                <InfoBanner
                    id='api-error-banner'
                    bannerType='error'
                    text={`${content.errorOrderApi} ${orderId}`}
                    isCloseable={false}
                />
            )}
            {!hasCorrectParams && (
                <InfoBanner
                    id='api-error-banner'
                    bannerType='error'
                    text={`${content.incorrectParams} ${JSON.stringify(orderId)}`}
                    isCloseable={false}
                    logType='warn'
                    source='order-page'
                />
            )}
            {!getOrderError && orderResult && !getOrderLoading && (
                <OrderImportLayout orderData={orderResult} />
            )}
        </div>
    )
}

export default OrderImportPage
