import { datadogLogs } from '@datadog/browser-logs'

type FeatureToggleValue = 'on' | 'off'

/** This Default feature toggle object needs to be kept inline with current production toggle values */
export const DEFAULT_REACT_APP_FEATURE_TOGGLES: {
    TURN_ON_COMPANY_CONFIG: FeatureToggleValue
    TURN_ON_PAYMENT_SCHEDULE: FeatureToggleValue
    TURN_ON_ORDERS_ADDITIONAL_INFO: FeatureToggleValue
    TURN_ON_SUBSCRIPTION_MANAGEMENT: FeatureToggleValue
} = {
    TURN_ON_COMPANY_CONFIG: 'off', // ADD LOGO ETC NOT RELEASED
    TURN_ON_PAYMENT_SCHEDULE: 'off', // DO NOT SHOW EVER
    TURN_ON_ORDERS_ADDITIONAL_INFO: 'off', // Imported orders 'additional' info like flights etc... never finished?
    TURN_ON_SUBSCRIPTION_MANAGEMENT: 'off', // CHAREGBEE NOT IN PROD
}

const allTrueForTest = true
/** This feature toggle object has all toggles on for testing purposes */
export const testingAllOn = {
    TURN_ON_COMPANY_CONFIG: allTrueForTest,
    TURN_ON_PAYMENT_SCHEDULE: allTrueForTest,
    TURN_ON_ORDERS_ADDITIONAL_INFO: allTrueForTest,
    TURN_ON_SUBSCRIPTION_MANAGEMENT: allTrueForTest,
}

type DefaultFeatureToggleKeys = keyof typeof DEFAULT_REACT_APP_FEATURE_TOGGLES

export function getReactAppToggles(
    featureTogglesProcessEnvString: string
): Record<string, boolean> {
    let newFeatureToggleObject: Record<string, FeatureToggleValue> = {}
    const userContext = datadogLogs.getGlobalContext()
    try {
        if (featureTogglesProcessEnvString) {
            newFeatureToggleObject = JSON.parse(featureTogglesProcessEnvString)
        } else {
            newFeatureToggleObject = { ...DEFAULT_REACT_APP_FEATURE_TOGGLES }
        }
    } catch (error: any) {
        datadogLogs.logger.error('Error parsing feature toggles:', { userContext }, error)
    }
    //filter out anything that has value which isn't 'on' or 'off' and keys that are misspelled/different to defaults
    const filteredOutWrongKeysAndValues = Object.fromEntries(
        Object.entries(newFeatureToggleObject).filter(
            ([key, value]) =>
                (value === 'on' || value === 'off') &&
                DEFAULT_REACT_APP_FEATURE_TOGGLES.hasOwnProperty(key) //this checks if newFeatureToggleObject key is also a key in DEFAULT_REACT_APP_FEATURE_TOGGLES
        )
    )
    //identify missing keys by comparing the keys in default and result
    const missingKeys = Object.keys(DEFAULT_REACT_APP_FEATURE_TOGGLES).filter(
        (key) => !filteredOutWrongKeysAndValues.hasOwnProperty(key)
    )
    // Create the final object with boolean values
    return {
        ...Object.fromEntries(
            Object.entries(filteredOutWrongKeysAndValues).map(([key, value]) => [
                key,
                value === 'on', // Convert 'on' to true and 'off' to false
            ])
        ),
        ...Object.fromEntries(
            missingKeys.map((key) => [
                key,
                DEFAULT_REACT_APP_FEATURE_TOGGLES[key as DefaultFeatureToggleKeys] === 'on',
            ])
        ),
    }
}
