import React from 'react'

import { RateCode } from 'api-data-models/cruise-detail/RateCodesModel'

import CardAccordion from 'components/blocks/Accordions/CardAccordion/CardAccordion'
import ColoredLine from 'components/basics/ColoredLine/ColoredLine'
import Icon from 'components/basics/Icon/Icon'

import Text from '../../../basics/Text/Text'
import styles from '../RateCodes/RateCodes.module.css'
import { REFUND_POLICIES } from 'utils/constants'
import allContent from 'content/content'

const content = allContent.cruise.sailingPage.rateCodeSection

type RateCodesProps = {
    rateCodesData: RateCode[]
}

export const getRefundPolicyBadgeText = (refundPolicy?: RefundPolicyType): string | null => {
    switch (refundPolicy) {
        case REFUND_POLICIES.NON_REFUNDABLE_DEPOSIT:
            return content.nonRefundableDeposit
        case REFUND_POLICIES.NON_REFUNDABLE_FARE:
            return content.nonRefundable
        default:
            return null
    }
}

const RateCodes = ({ rateCodesData }: RateCodesProps): JSX.Element => {
    const maxLen = rateCodesData?.length
        ? Math.max(
              ...rateCodesData.map((rateCode) => {
                  return rateCode.code.length
              })
          )
        : null

    const ratesTable = (
        <CardAccordion baseId='ratecode-section' title={content.heading} darkHeading={false}>
            <div className={styles.wrapper}>
                {rateCodesData.map((rateCode, i) => {
                    const width = maxLen ? maxLen * 0.67 + 'rem' : 'inherit'
                    const refundPolicyBadge = getRefundPolicyBadgeText(rateCode?.refundPolicy)
                    const rateExtraInfo =
                        !!refundPolicyBadge || rateCode?.military || rateCode?.residency
                    return (
                        <div key={`${rateCode.code}`}>
                            {i !== 0 && <ColoredLine className={styles.divider} />}
                            <div className={styles.column}>
                                <div className={styles.row}>
                                    <div style={{ minWidth: width }}>
                                        <Text weight='bold'>{rateCode.code}</Text>
                                    </div>
                                    <div>
                                        <Text>{rateCode.description}</Text>
                                    </div>
                                </div>
                                {rateExtraInfo && (
                                    <div className={styles.row}>
                                        {refundPolicyBadge && (
                                            <div className={styles['row-small']}>
                                                <Icon iconName='NonRefundable' />
                                                <Text size='XS'>{refundPolicyBadge}</Text>
                                            </div>
                                        )}
                                        {rateCode?.military && (
                                            <div className={styles['row-small']}>
                                                <Icon iconName='MilitaryRate' />
                                                <Text size='XS'>{content.militaryRate}</Text>
                                            </div>
                                        )}
                                        {rateCode?.residency && (
                                            <div className={styles['row-small']}>
                                                <Icon iconName='ResidentialRate' />
                                                <Text size='XS'>{content.residentialRate}</Text>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
        </CardAccordion>
    )

    return (
        <>
            {!!rateCodesData && rateCodesData.length === 0 && (
                <Text weight='bold'>{content.notFound}</Text>
            )}
            {rateCodesData && ratesTable}
        </>
    )
}
export default RateCodes
