import React, {
    useContext,
    useEffect,
    useReducer,
    useState,
    ReactNode,
    SetStateAction,
    Dispatch,
} from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { datadogLogs } from '@datadog/browser-logs'

import CardAccordion from 'components/blocks/Accordions/CardAccordion/CardAccordion'
import Button from 'components/basics/Button/Button'
import ContactDetailsAddForm from 'components/sections/order/ContactDetailsAddForm/ContactDetailsAddForm'
import ErrorList from 'components/sections/app/ErrorList/ErrorList'
import Heading from 'components/basics/Heading/Heading'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import Icon from 'components/basics/Icon/Icon'
import OrderConfirmation from 'components/sections/order/OrderConfirmation/OrderConfirmation'
import OrderItem from 'components/sections/order/OrderItem/OrderItem'
import OrderPaymentScheduleSection from 'components/sections/order/OrderPaymentScheduleSection/OrderPaymentScheduleSection'
import OrderPricingSection from 'components/sections/order/OrderPricingSection/OrderPricingSection'
import PassengerSummarySection from 'components/sections/order/PassengerSummarySection/PassengerSummarySection'
import PaymentForm from 'components/sections/order/PaymentForm/PaymentForm'
import Stepper from 'components/blocks/Stepper/Stepper'
import Text from 'components/basics/Text/Text'
import TravellerAddForm from 'components/sections/order/TravellerAddForm/TravellerAddForm'
import OrderBookedPassengerDetails from 'components/sections/order/OrderBookedPassengerDetails/OrderBookedPassengerDetails'
import OptionalExtras from 'components/sections/order/OptionalExtras/OptionalExtras'
import { ORDER_ACTIONS, OrderDispatchProps, orderReducer } from 'components/reducers/orderReducer'
import getRescardHtmlComment from 'utils/trams-rescard/getResCardHtmlComment'
import { checkAndPerformUserSessionRefreshIfNeeded } from 'utils/cognito-helpers/check-and-perform-user-session-refresh-if-needed'
import callApi from 'services/callApi/callApi.service'
import { orderServiceUrls } from 'utils/order-service-urls'
import { HTTP_METHODS, PRODUCT_TYPES } from 'utils/constants'
import { convertKeysToDirectCase } from 'utils/camel-case-helpers'
import { getRestErrorMessage } from 'utils/api-errors-helpers/get-rest-error-message'
import { formatPricePenceToPounds } from 'utils/string-helpers'
import { FeatureToggleContext } from 'App'
import { OptionalExtrasArguments } from 'components/pages/order/OrderPage'
import {
    ContactDetailsAddress,
    EmergencyContact,
    CruiseProduct,
    FlightProduct,
    OptionalExtra,
    OrderContent,
    Passport,
    PhoneNumber,
    CruisePaymentItem,
    Advisory,
} from 'api-data-models/order/OrderContentModel'
import * as self from './OrderLayout'

import styles from './OrderLayout.module.css'
import allContent from 'content/content'
import { useRest, UseRestOptions } from '../../../hooks/useRest'
import { usePassengersInfo } from '../../../hooks/usePassengersInfo'
import {
    OptionalExtraFromApi,
    OptionalExtrasModel,
    OptionalExtrasModelTransformFunction,
} from '../../../../api-data-models/cruise-detail/OptionalExtrasModel'

const content = allContent.order.orderPage

export const ORDER_VIEWS = {
    ORDER: 'order',
    CONTACT: 'contact',
    TRAVELLER: 'traveller',
    PAYMENT: 'payment',
    BOOKED: 'booked',
}

type SubmitOptionalExtrasProps = {
    setIsSubmittingOptionalExtras: (value: React.SetStateAction<boolean>) => void
    dispatchOrderState: (props: OrderDispatchProps) => void
    setOptionalExtrasSelectedError: (value: React.SetStateAction<any>) => void
    setOptionalExtrasSelectedSuccess: () => void
    navigate: NavigateFunction
    variables: {
        orderId: string
        itemId: string
        supplierCode: string
        optionalExtraCodes: string[]
    }
}

export const submitOptionalExtras = async ({
    setIsSubmittingOptionalExtras,
    dispatchOrderState,
    setOptionalExtrasSelectedError,
    setOptionalExtrasSelectedSuccess,
    navigate,
    variables,
}: SubmitOptionalExtrasProps): Promise<void> => {
    setIsSubmittingOptionalExtras(true)
    const handleUserSession = checkAndPerformUserSessionRefreshIfNeeded(navigate)
    const source = 'SetOptionalExtras'
    const requestUrl = orderServiceUrls.updateOrderOptionalExtras(
        variables.orderId,
        variables.itemId,
        variables.supplierCode
    )
    await handleUserSession.then(() =>
        callApi({
            url: requestUrl,
            variables: variables.optionalExtraCodes,
            method: HTTP_METHODS.POST,
            source: source,
        })
            .then((response) => response.json())
            .then((response: any) => {
                if (response) {
                    const updateOptionalExtrasResultParsed = convertKeysToDirectCase(response)
                    dispatchOrderState({
                        type: ORDER_ACTIONS.UPDATE_ORDER_OPTIONAL_EXTRAS,
                        payload: updateOptionalExtrasResultParsed,
                    })
                    setOptionalExtrasSelectedSuccess()
                }
            })
            .catch((error: any) => {
                setOptionalExtrasSelectedError(
                    getRestErrorMessage({
                        errors: error.cause,
                        source: source,
                        variables: variables.optionalExtraCodes,
                    })
                )
            })
            .finally(() => {
                setIsSubmittingOptionalExtras(false)
            })
    )
}

export type Order_Keys = keyof typeof ORDER_VIEWS

export type CruiseSummaryPrices = {
    optionalExtrasSelected: OptionalExtra[]
    cruisePrice: string
    totalPrice: string
}

export const getSummaryPrices = (
    totalPrice: number,
    selectedOptionalExtras: OptionalExtra[]
): CruiseSummaryPrices => {
    const summaryCruisePrice = selectedOptionalExtras?.length
        ? totalPrice -
          selectedOptionalExtras.reduce(
              (acc, item) => (item?.totalPrice ? item?.totalPrice + acc : acc),
              0
          )
        : totalPrice

    const summaryTotalPrice = selectedOptionalExtras?.length
        ? summaryCruisePrice +
          selectedOptionalExtras.reduce(
              (acc, item) => (item?.totalPrice ? item?.totalPrice + acc : acc),
              0
          )
        : summaryCruisePrice

    return {
        optionalExtrasSelected: selectedOptionalExtras,
        cruisePrice: formatPricePenceToPounds(summaryCruisePrice),
        totalPrice: formatPricePenceToPounds(summaryTotalPrice),
    }
}

export const findNextMissingPassengerNumber = (
    passengerNumbersAsStrings: string[],
    numberOfPassengers: number
): string | undefined => {
    let nextPassengerNumber
    for (let i = 2; i <= numberOfPassengers; i++) {
        if (passengerNumbersAsStrings.indexOf(i.toString()) === -1) {
            nextPassengerNumber = i.toString()
            break
        }
    }
    return nextPassengerNumber
}

export const populatePassengersBanner = (
    numberOfPassengers: number,
    travellers: Record<string, any>,
    contactDetails: Record<string, any>,
    setPassengerBeingEdited: Dispatch<SetStateAction<string>>,
    setView: Dispatch<SetStateAction<(typeof ORDER_VIEWS)[Order_Keys]>>
): ReactNode | null => {
    const contactDetailsHasData = !!contactDetails.firstName && !!contactDetails.lastName
    if (numberOfPassengers === Object.keys(travellers).length && contactDetailsHasData) return null

    let bannerText = ''
    let buttonText
    let passengerEdited = ''
    let view: (typeof ORDER_VIEWS)[Order_Keys] = ORDER_VIEWS.TRAVELLER

    // check if lead passenger details are missing
    if (!travellers['1']) {
        bannerText = content.missingPassengerDetailsBanner.leadPassenger
        buttonText = content.missingPassengerDetailsBanner.leadPassengerButton
        passengerEdited = '1'
    }
    // if lead passenger is added then check which remaining passengers are missing
    if (travellers['1'] && numberOfPassengers > Object.keys(travellers).length) {
        const nextPassenger = findNextMissingPassengerNumber(
            Object.keys(travellers),
            numberOfPassengers
        )
        bannerText = `${content.missingPassengerDetailsBanner.passenger} ${nextPassenger}.`
        if (nextPassenger) {
            buttonText = `${content.missingPassengerDetailsBanner.passengerButton} ${nextPassenger}`
            passengerEdited = nextPassenger
        }
    }
    // finally check if contact details are added if all the passengers details are added
    if (numberOfPassengers === Object.keys(travellers).length && !contactDetailsHasData) {
        bannerText = content.missingPassengerDetailsBanner.contactDetails
        buttonText = content.missingPassengerDetailsBanner.contactDetailsButton
        passengerEdited = '0'
        view = ORDER_VIEWS.CONTACT
    }
    return (
        <InfoBanner
            text={bannerText}
            bannerType='info'
            id='passengers-info-banner'
            isCloseable={false}
            buttonText={buttonText}
            onButtonClick={(): void => {
                window.scrollTo(0, 0)
                setPassengerBeingEdited(passengerEdited)
                setView(view)
            }}
        />
    )
}

export function getAdvisoryMessages(advisory: Advisory[]): ReactNode {
    if (advisory?.length) {
        return (
            <CardAccordion
                title={
                    <div className={styles['advisory-messages-header']}>
                        <Icon iconName='CircleExclamationSolid' iconColor='white' iconSize='M' />
                        <Heading heading='2' size='2' onDarkBackground={true}>
                            {content.supplierAdvisoryMessages.header}
                        </Heading>
                    </div>
                }
                baseId='advisoryMessagesAccordion'
                startOpen={true}
            >
                <div>
                    {
                        <ul className={styles['advisory-messages-container']}>
                            {advisory.map((adv: { message: string; code: string }) => (
                                <li className={styles['advisory-messages-message']} key={adv.code}>
                                    <Text>{adv.message}</Text>
                                </li>
                            ))}
                        </ul>
                    }
                </div>
            </CardAccordion>
        )
    }
    return null
}

export type LeadTravellerPersonalInformation = {
    contactTitle?: string
    contactFirstName?: string
    contactMiddleName?: string
    contactLastName?: string
    noMiddleNameChecked?: boolean
}

type TravellerDetails = {
    travellerNumber?: number
    title: string
    firstName: string
    middleName?: string
    lastName: string
    dateOfBirth?: string
    nationality?: string
    accessibilityNeeds?: boolean
    address?: ContactDetailsAddress
    emergencyContact?: EmergencyContact
    passport?: Passport
    phoneNumber?: PhoneNumber
}

type OrderLayoutProps = {
    optionalExtrasVariables: OptionalExtrasArguments
    orderData: OrderContent
    lastPriceUpdateTimeStamp: string
}

const OrderLayout = ({
    optionalExtrasVariables,
    orderData,
    lastPriceUpdateTimeStamp,
}: OrderLayoutProps): JSX.Element | null => {
    const navigate = useNavigate()
    const featureToggles = useContext(FeatureToggleContext)

    const [optionalExtrasLoading, setOptionalExtrasLoading] = useState<boolean>(true) // start true - either component isn't shown or it instantly fetches
    const [optionalRestExtrasData, setOptionalRestExtrasData] = useState<
        OptionalExtrasModel | undefined
    >(undefined)
    const [isSubmittingOptionalExtras, setIsSubmittingOptionalExtras] = useState(false)
    const [orderState, dispatchOrderState] = useReducer(orderReducer, orderData)
    const [passengerBeingEdited, setPassengerBeingEdited] = useState('')

    const orderItem = orderState.orderItem
    const orderId = orderState.orderId
    const orderIdTrimmed = orderId ? orderId.slice(0, 8) : ''
    const passengersCriteria = orderItem?.passengersCriteria || []

    const userContext = datadogLogs.getGlobalContext()

    const defaultCruiseExtras = passengersCriteria?.[0]?.assignedOptionalExtras || []

    const [optionalExtrasSelected, setOptionalExtrasSelected] =
        useState<OptionalExtra[]>(defaultCruiseExtras)
    const [optionalExtrasSelectedError, setOptionalExtrasSelectedError] = useState<
        CustomApiError[] | null
    >(null)

    const travellers = { ...orderState.travellers }
    const supplierOrderItemReference = orderItem?.supplierOrderItemReference
    const orderItemStatus = orderItem?.orderItemStatus
    const orderItemPaymentOption = orderItem?.orderItemPaymentOption

    const orderItemId = orderState.orderItem?.orderItemId
    const assignedTravellers = orderState.orderItem?.assignedTravellers
    const leadTraveller = orderState.orderItem?.leadTraveller
    const numberOfPassengers = orderState.orderItem?.numberOfTravellers
    const contactDetails = orderState.contactDetails
    const travellerRequirements = orderState.travellerRequirements
    const cruiseProduct = orderState.orderItem?.product as CruiseProduct
    const cruiseId = cruiseProduct?.cruiseId
    const rateCode = cruiseProduct?.rateCode
    const cabinGradeCode = cruiseProduct?.cabinGradeCode
    const supplierCode = cruiseProduct?.supplierCode
    const cabinNumber = cruiseProduct?.cabinNumber

    const passengers = [...passengersCriteria].sort((a, b) =>
        a.travellerNumber > b.travellerNumber ? 1 : -1
    )

    const nonLeadPassengers = passengers.filter(({ travellerNumber }) => travellerNumber !== 1)

    const [activeStep, setActiveStep] = useState(0)

    const handleGoToCartPage = (): void => {
        window.scrollTo(0, 0)
        setView(ORDER_VIEWS.ORDER)
        setPassengerBeingEdited('0')
        setActiveStep(0)
    }
    const handleGoToTravelerPage = (travellerNumber: string): void => {
        window.scrollTo(0, 0)
        setPassengerBeingEdited(travellerNumber)
        setView(ORDER_VIEWS.TRAVELLER)
        setActiveStep(+travellerNumber)
    }
    const handleGoToContactPage = (): void => {
        window.scrollTo(0, 0)
        setPassengerBeingEdited('0')
        setView(ORDER_VIEWS.CONTACT)
        setActiveStep(nonLeadPassengers.length + 2)
    }
    const handleGoToPaymentPage = (): void => {
        window.scrollTo(0, 0)
        setView(ORDER_VIEWS.PAYMENT)
        setActiveStep(nonLeadPassengers.length + 3)
    }

    const steps = [
        {
            label: content.stepper.cartLabel,
            iconName: 'Basket',
            onClick: () => handleGoToCartPage(),
        },
        {
            label: content.stepper.leadPassengerLabel,
            iconName: 'User',
            onClick: () => handleGoToTravelerPage('1'),
            goToButtonText: content.stepper.leadPassengerButton,
        },
        ...(nonLeadPassengers?.length
            ? nonLeadPassengers.map((el) => ({
                  label: `${content.stepper.passengerLabel} ${el.travellerNumber}`,
                  iconName: 'User',
                  onClick: () => handleGoToTravelerPage(String(el.travellerNumber)),
                  goToButtonText: `${content.stepper.passengerButton} ${el.travellerNumber}`,
              }))
            : []),
        {
            label: content.stepper.contactLabel,
            iconName: 'Contact',
            onClick: () => handleGoToContactPage(),
            goToButtonText: content.stepper.contactButton,
        },
        {
            label: content.stepper.paymentLabel,
            iconName: 'Payment',
            onClick: () => handleGoToPaymentPage(),
            goToButtonText: content.stepper.paymentButton,
        },
    ]

    const contactDetailsStepIndex = steps.length - 2
    const paymentStepIndex = steps.length - 1

    const getDefaultCompletedStep = (): number => {
        if (!travellers[1]) return 0
        if (nonLeadPassengers.length) {
            if (!travellers[2]) return 1
            if (nonLeadPassengers.length > 1 && !travellers[3]) return 2
            if (nonLeadPassengers.length > 2 && !travellers[4]) return 3
        }
        if (!contactDetails.firstName) return contactDetailsStepIndex - 1
        return paymentStepIndex - 1
    }

    const [completedStep, setCompletedStep] = useState(getDefaultCompletedStep())

    const getContinueButtonText = (): string => {
        return steps[completedStep + 1]?.goToButtonText ?? content.continueButton
    }

    const handleGoNextStep = (step: number, isStepCompleted = false): any => {
        if (isStepCompleted) setCompletedStep(step)
        return steps[step + 1]?.onClick()
    }

    let product
    let cruiseSummaryPrices = null
    let cruiseSupplierCode: string | undefined
    let grossTotal = '0'
    let paymentScheduleItems: [] | CruisePaymentItem[] = []
    if (orderItem?.product?.productType === PRODUCT_TYPES.CRUISE) {
        product = orderItem.product as CruiseProduct
        cruiseSummaryPrices = getSummaryPrices(
            product.pricing.totalGrossPriceCent,
            optionalExtrasSelected
        )
        grossTotal = product.pricing.totalGrossPrice
        cruiseSupplierCode = product.supplierCode
        paymentScheduleItems = product.paymentScheduleItems
    } else if (orderItem?.product?.productType === PRODUCT_TYPES.FLIGHT) {
        product = orderItem.product as FlightProduct
        grossTotal = product.pricing.price
    }

    const hasSupplierRef = !!orderItem?.supplierOrderItemReference

    const initialView = hasSupplierRef ? ORDER_VIEWS.BOOKED : ORDER_VIEWS.ORDER
    const [view, setView] = useState<(typeof ORDER_VIEWS)[Order_Keys]>(initialView)

    const { passengerConfigurationDataRestRequestBody } = usePassengersInfo()
    const optionalExtrasFetchOptions: UseRestOptions = {
        url:
            process.env.REACT_APP_CRUISE_DETAIL_SERVICE_URL +
            `/cruise/${cruiseId}/rates/${rateCode}/cabin-grades/${cabinGradeCode}/cabins/${cabinNumber}/optional-extras?supplier_code=${supplierCode}`,
        source: 'CabinPage - Cabins List',
        method: 'POST',
        variables: passengerConfigurationDataRestRequestBody,
        skip: hasSupplierRef || !optionalExtrasVariables,
    }
    const {
        result: optionalExtrasResult,
        loading: isOptionalExtrasLoading,
        error: optionalExtrasError,
    } = useRest(optionalExtrasFetchOptions)

    useEffect(() => {
        if (optionalExtrasResult) {
            const transformedData = OptionalExtrasModelTransformFunction(
                optionalExtrasResult as OptionalExtraFromApi[]
            )
            setOptionalRestExtrasData(transformedData)
        }
        setOptionalExtrasLoading(isOptionalExtrasLoading)
    }, [optionalExtrasResult, isOptionalExtrasLoading, optionalExtrasError])

    /** Do not show any components if no cruiseProduct or flightProduct is present as orderItem as they will break */
    if (!product || !orderState.orderItem) {
        return <p>{content.noProductOnOrder}</p>
    }

    const handleSubmitOptionalExtras = (optionalExtras: OptionalExtra[]): void => {
        if (cruiseSupplierCode) {
            const optionalExtrasSelectedCodes = optionalExtras
                ?.map((extra) => extra.code)
                .filter((code) => code)
            self.submitOptionalExtras({
                setIsSubmittingOptionalExtras,
                dispatchOrderState,
                setOptionalExtrasSelectedError,
                setOptionalExtrasSelectedSuccess: () => setOptionalExtrasSelected(optionalExtras),
                variables: {
                    orderId: orderId,
                    itemId: orderItemId,
                    supplierCode: cruiseSupplierCode,
                    optionalExtraCodes: optionalExtrasSelectedCodes,
                },
                navigate,
            })
        }
    }

    const pricing = product.pricing
    const paymentChoices = product.paymentChoices

    let rescardComment

    const leadTravellerDetails: TravellerDetails | undefined = travellers['1']

    const extractLeadTravellerInfo = (
        leadTravellerDetails: TravellerDetails | undefined
    ): LeadTravellerPersonalInformation | undefined => {
        if (leadTravellerDetails) {
            const noMiddleNameChecked = !leadTravellerDetails.middleName // Check if the middle name field is empty
            return {
                contactTitle: leadTravellerDetails?.title ?? '',
                contactFirstName: leadTravellerDetails?.firstName ?? '',
                contactLastName: leadTravellerDetails?.lastName ?? '',
                contactMiddleName: leadTravellerDetails?.middleName ?? '',
                noMiddleNameChecked: noMiddleNameChecked,
            }
        } else return {}
    }

    const extractedLeadTravellerContactDetails = extractLeadTravellerInfo(leadTravellerDetails)

    try {
        rescardComment = getRescardHtmlComment({ order: orderState })
    } catch (error: any) {
        datadogLogs.logger.error(`error creating RESCARD:`, { userContext }, error)
    }

    let title = content.title
    if (view === ORDER_VIEWS.TRAVELLER) title = content.travellerAddForm.headingTitle
    if (view === ORDER_VIEWS.CONTACT) title = content.contactDetailsAddForm.headingTitle
    if (view === ORDER_VIEWS.PAYMENT) title = content.paymentForm.headingTitle
    if (view === ORDER_VIEWS.BOOKED) title = content.bookedView.headingTitle

    const advisoryMessages = orderItem?.advisory ? getAdvisoryMessages(orderItem.advisory) : null

    return (
        <div className={styles.container}>
            {view !== ORDER_VIEWS.BOOKED && (
                <Stepper
                    steps={steps}
                    activeStep={activeStep}
                    lastCompletedStep={completedStep}
                    setActiveStep={setActiveStep}
                />
            )}
            {optionalExtrasError && (
                <ErrorList
                    errorsList={optionalExtrasError}
                    source='order-layout-get-optional-extras'
                />
            )}
            <div className={styles.heading}>
                <Heading heading='1'>{title}</Heading>
            </div>
            {view === ORDER_VIEWS.BOOKED && (
                <InfoBanner
                    id='info-booked-banner'
                    isCloseable={false}
                    text={`${content.bookedView.bookedInfoBanner} ${lastPriceUpdateTimeStamp}`}
                    bannerType='info'
                />
            )}
            {advisoryMessages}
            <div className={styles.content}>
                <div className={styles['content-left']}>
                    {view === ORDER_VIEWS.ORDER && (
                        <>
                            <OrderItem
                                product={product}
                                numberOfPassengers={numberOfPassengers}
                                supplierOrderItemReference={supplierOrderItemReference}
                                orderItemStatus={orderItemStatus}
                                orderItemPaymentOption={orderItemPaymentOption}
                                orderId={orderIdTrimmed}
                            />
                            <OptionalExtras
                                data={optionalRestExtrasData}
                                optionalExtrasLoading={optionalExtrasLoading}
                                optionalExtrasSubmitting={isSubmittingOptionalExtras}
                                optionalExtrasSelected={optionalExtrasSelected}
                                handleSubmitOptionalExtras={handleSubmitOptionalExtras}
                                optionalExtrasSelectedError={optionalExtrasSelectedError}
                            />
                        </>
                    )}
                    {view === ORDER_VIEWS.TRAVELLER && (
                        <TravellerAddForm
                            supplierCode={cruiseSupplierCode}
                            passengersCriteria={passengersCriteria}
                            dispatchOrderState={dispatchOrderState}
                            assignedTravellers={assignedTravellers}
                            orderId={orderId}
                            orderItemId={orderItemId}
                            passengerBeingEdited={passengerBeingEdited}
                            backToOrder={handleGoToCartPage}
                            setCompletedStep={(step): void =>
                                handleGoNextStep(step ?? completedStep, !!step)
                            }
                            travellers={travellers}
                            travellerRequirements={travellerRequirements}
                        />
                    )}
                    {view === ORDER_VIEWS.CONTACT && (
                        <ContactDetailsAddForm
                            backToOrder={handleGoToCartPage}
                            extractedLeadTravellerContactDetails={
                                extractedLeadTravellerContactDetails
                            }
                            dispatchOrderState={dispatchOrderState}
                            orderId={orderId}
                            contactDetails={contactDetails}
                            setCompletedStep={(): void =>
                                handleGoNextStep(contactDetailsStepIndex, true)
                            }
                            setView={setView}
                        />
                    )}
                    {view === ORDER_VIEWS.PAYMENT && (
                        <PaymentForm
                            backToOrder={handleGoToCartPage}
                            orderState={orderState}
                            itemId={orderItemId} //TODO: hard coded as the items[0] orderItemId but once there's more items this will need to be an array
                            orderId={orderId}
                            paymentChoices={paymentChoices}
                            currencySymbol={pricing.currencySymbol}
                            currencyCode={pricing.currencyCode}
                        />
                    )}
                    {view === ORDER_VIEWS.BOOKED && supplierOrderItemReference && (
                        <>
                            <OrderConfirmation
                                numberOfTravellers={numberOfPassengers}
                                orderItemStatus={orderItemStatus}
                                orderItemPaymentOption={orderItemPaymentOption}
                                product={product}
                                supplierOrderItemReference={supplierOrderItemReference}
                                orderId={orderIdTrimmed}
                            />
                            {!!rescardComment && rescardComment}
                        </>
                    )}
                    <div className={styles.bottom}>
                        {view === ORDER_VIEWS.ORDER && view !== ORDER_VIEWS.BOOKED && (
                            <Button
                                text={getContinueButtonText()}
                                onClick={(): void => handleGoNextStep(completedStep)}
                                disabled={isSubmittingOptionalExtras}
                            />
                        )}
                    </div>
                </div>

                <div className={styles['content-right']}>
                    <OrderPricingSection
                        lastPriceUpdateTimeStamp={lastPriceUpdateTimeStamp}
                        product={product}
                        orderId={orderIdTrimmed}
                        cruiseSummaryPrices={cruiseSummaryPrices as CruiseSummaryPrices}
                    />
                    {paymentScheduleItems.length > 0 && featureToggles.TURN_ON_PAYMENT_SCHEDULE && (
                        <OrderPaymentScheduleSection
                            paymentScheduleItems={paymentScheduleItems}
                            grossTotal={grossTotal}
                        />
                    )}
                    {view !== ORDER_VIEWS.PAYMENT && view !== ORDER_VIEWS.BOOKED && (
                        <PassengerSummarySection
                            passengersCriteria={passengersCriteria}
                            passengerBeingEdited={passengerBeingEdited}
                            handleGoToTravelerPage={handleGoToTravelerPage}
                            handleGoToContactPage={handleGoToContactPage}
                            travellers={travellers}
                            view={view}
                            contactDetails={contactDetails}
                        />
                    )}
                    {view === ORDER_VIEWS.BOOKED && supplierOrderItemReference && (
                        <OrderBookedPassengerDetails
                            travellers={travellers}
                            assignedTravellerNumbers={assignedTravellers}
                            contactDetails={contactDetails}
                            leadTravellerNumber={leadTraveller as number}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
export default OrderLayout
