const content = {
    app: {
        links: {
            marketingTierChoiceWebsite: 'https://www.traveltek.com/products/cruiseconnect/#pricing',
        },
        supplierLogos: {
            AMAlogoUrl:
                'https://www.traveltek.com/wp-content/uploads/2024/10/SupplierLogos/AMA.png',
            AZAlogoUrl:
                'https://www.traveltek.com/wp-content/uploads/2024/10/SupplierLogos/AZA.png',
            CCLlogoUrl:
                'https://www.traveltek.com/wp-content/uploads/2024/10/SupplierLogos/CCL.png',
            CELlogoUrl:
                'https://www.traveltek.com/wp-content/uploads/2024/10/SupplierLogos/CEL.png',
            CUNlogoUrl:
                'https://www.traveltek.com/wp-content/uploads/2024/10/SupplierLogos/CUN.png',
            DCLlogoUrl:
                'https://www.traveltek.com/wp-content/uploads/2024/10/SupplierLogos/DCL.png',
            EXPlogoUrl:
                'https://www.traveltek.com/wp-content/uploads/2024/10/SupplierLogos/EXP.png',
            HALlogoUrl:
                'https://www.traveltek.com/wp-content/uploads/2024/10/SupplierLogos/HAL.png',
            MSClogoUrl:
                'https://www.traveltek.com/wp-content/uploads/2024/10/SupplierLogos/MSC.png',
            NCLlogoUrl:
                'https://www.traveltek.com/wp-content/uploads/2024/10/SupplierLogos/NCL.png',
            OCIlogoUrl:
                'https://www.traveltek.com/wp-content/uploads/2024/10/SupplierLogos/OCI.png',
            PCLlogoUrl:
                'https://www.traveltek.com/wp-content/uploads/2024/10/SupplierLogos/PCL.png',
            RCLlogoUrl:
                'https://www.traveltek.com/wp-content/uploads/2024/10/SupplierLogos/RCL.png',
            RSSClogoUrl:
                'https://www.traveltek.com/wp-content/uploads/2024/10/SupplierLogos/RSSC.png',
            SBNlogoUrl:
                'https://www.traveltek.com/wp-content/uploads/2024/10/SupplierLogos/SBN.png',
            SSClogoUrl:
                'https://www.traveltek.com/wp-content/uploads/2024/10/SupplierLogos/SSC.png',
            VVClogourl:
                'https://www.traveltek.com/wp-content/uploads/2024/10/SupplierLogos/VVC.png',
            VKGlogoUrl:
                'https://www.traveltek.com/wp-content/uploads/2024/10/SupplierLogos/VKG.png',
        },
        tabTitle: 'CruiseConnect - TravelTek',
        timeoutPage: {
            message: 'Session has expired due to inactivity. Please log in again.',
            goToLogin: 'Your session has expired.',
            loginButtonText: 'Log In',
        },
        inactiveTenantPage: {
            heading: 'Company account no longer active.',
            message1:
                'Please contact support if you think this has been done in error - include company name and email.',
            message2: 'You can use the "Service Desk" link in the footer to raise a ticket.',
            goToLogin: 'Go to log in page',
        },
        apiUserWarningPage: {
            heading: 'Your API user is active:',
            message1:
                'This user account has permissions to obtain the Authorization Tokens for accessing our api from our auth endpoint using:',
            message2:
                'curl --location \'https://apiusers-auth.cruiseconnect.traveltek.net/tokens\' --header \'Content-Type: application/json\' --data \'{ "email": "xxx", "password": "xxx" }\'',
            goToLogin: 'Return to log in page',
        },
        breadcrumbs: {
            search: 'Search',
            results: 'Results',
            sailing: 'Sailing',
            roomDetails: 'Room',
            import: 'Import',
            cabin: 'Cabin',
            allOrders: 'Orders',
            cart: 'Cart',
            confirmation: 'Confirmation',
        },
        protectedRouteWrapper: {
            fetchingUserData: 'Fetching user data...',
        },
        errors: {
            noRefreshTokenFound: 'No refresh token found, sorry.',
        },
    },
    header: {
        logo: 'https://www.traveltek.com/wp-content/uploads/2024/08/Traveltek_CruiseConnect_Logo_White.svg',
        signUpLinkUrl: 'https://cruiseconnect.traveltek.net/sign-up',
        ordersButton: 'Orders and Quotes',
        loginButton: 'Log In',
        signupButton: 'Sign Up',
        adminSettingsButton: 'Admin Settings',
        currency: 'Currency (USD)',
        userMenu: {
            label: 'UserMenu',
            logoutButton: 'Log Out',
        },
        importButton: 'Import',
        cruiseSearchButton: 'Cruise Search',
        importModal: {
            title: 'Import',
            subtitle: 'Fill out the information below to import.',
            supplierLabel: 'Supplier',
            supplierError: 'Please select supplier',
            bookingLabel: 'Booking Reference',
            bookingError: 'This field is required',
            cancelButton: 'Cancel',
            submitButton: 'Import',
            errorImport: 'Failed to retrieve reference, please try again',
            unavailable: '(not available)',
        },
    },
    footer: {
        heading1: 'Quick Links',
        cruiseText: 'Cruise Search',
        feedback: 'Give us your feedback',
        changelog: "What's New",
        import: 'Import',
        importButton: 'Import',
        admin: 'Admin Settings',
        ordersText: 'Orders and Quotes',
        heading2: 'Support',
        serviceDeskText: 'Service Desk',
        serviceDeskUrl: 'https://traveltek.atlassian.net/servicedesk/customer/portal/29',
        privacyPolicyText: 'Privacy Policy',
        privacyPolicyUrl: 'https://www.traveltek.com/privacy-policy/',
        termsOfServiceText: 'Terms of Use',
        termsOfServiceUrl: 'https://www.traveltek.com/terms-of-service/',
        faqText: 'FAQ',
        faqUrl: 'https://www.traveltek.com/products/cruiseconnect/#faq',
        logo: 'https://www.traveltek.com/wp-content/uploads/2024/08/Traveltek_CruiseConnect_Logo_White.svg',
        companyInfoText:
            'Registered in Scotland No. SC169803. Registered Office: Level 3, 38 Queen Street, Glasgow, G1 3DX, United Kingdom. Copyright 2024 Traveltek Group Ltd. All Rights Reserved.',
    },
    feedbackBanner: {
        title: 'We want your feedback!',
        subtitle:
            "We'd love to hear your thoughts on our app and invite you to vote on existing suggestions to help us improve!",
        button: 'Give feedback',
    },
    landingPage: {
        subscriptionSetup: {
            updatingTenant: 'Updating account...',
            processingCheckout: 'Processing checkout...',
            setupIssue: {
                heading: 'Setup issue',
                message:
                    'Sorry, your account needs support to continue working, please contact us for support.',
                supportLinkText: 'Raise a support ticket',
            },
        },
        fetchingUserData: 'Fetching user data...',
        notOnASubscription:
            "Somehow you are do not have a subscription setup. Please contact whoever manages your account if this is unexpected, or get in touch with our support team if you manage your company's account.",
    },
    signUpPage: {
        title: 'Sign up to CruiseConnect',
        signUpForm: {
            firstNameInput: 'First Name',
            lastNameInput: 'Last Name',
            emailInput: 'Email',
            companyNameInput: 'Company Name',
            companyLocationInput: 'Company Location',
            companyLocationInputPlaceHolder: 'Please select...',
            subscriptionTier: 'Subscription Tier',
            passInput: 'Password',
            confirmationCheckLabel: 'Confirm you have read and agree to our',
            tcsLinkText: 'terms and conditions',
            tcsLinkUrl: 'https://www.traveltek.com/terms-of-service',
            submitButton: 'Sign up',
            submitButtonProcessing: 'Processing...',
            loginText: 'Already have an account?',
            loginLinkText: 'Log In',
            errors: {
                firstNameInput: 'Please enter between 1 and 30 letters.',
                lastNameInput: 'Please enter between 1 and 30 letters.',
                emailInput: '(Is this a valid email? contact us if we are wrong)',
                companyNameInput: 'Please enter between 1 and 30 characters',
                companyLocationInput: 'Please make a selection',
                passInput:
                    'Please enter between 10 and 30 characters, at least one lowercase letter, one uppercase letter, one number and a special character',
                signupError:
                    'Registration unsuccessful. Please check your information and try again or contact our support team for further assistance',
                companyError: 'This company name is already in use. Please select another one.',
                emailError: 'An Account with this email already exists.',
            },
        },
        tempPasswordForm: {
            successSignUpMessage1: 'Password Setup',
            successSignUpMessage2:
                'Please input the temporary password from the email sent to you.',
            submitButton: 'Next',
            userEmailLabel: 'Email:',
            tempPasswordInput: 'Temporary Password',
            errors: {
                tempPasswordInput: 'Please check, 8-10 chars long.',
            },
        },
    },
    admin: {
        navigationPanel: {
            dashboard: 'Admin Settings',
            productHeading: 'Sections',
            apiDocumentation: 'API Documentation',
            apiDocumentationURL: 'https://schema.cruiseconnect.traveltek.net/',
            cruiseConnect: 'CruiseConnect - API',
            supplierConfiguration: 'Supplier Configuration',
            companyConfiguration: 'Company Configuration',
            reporting: 'MI/Reporting',
            reportingBooking: 'Booking Data',
            userManagement: 'User Management',
            usersManagement: 'Users Management',
            groupManagement: 'Groups Management',
            subscriptionManagement: 'Subscription Management',
            salesChannel: 'Sales Channels',
            salesChannelManagement: 'Sales Channels Management',
        },
        adminLanding: {
            title: 'Admin Settings',
            mustSubscribeMessage:
                'Please subscribe to a payment tier before you can access admin settings',
            subscribeButton: 'Choose tier',
            upgrade: {
                title: 'Want more features?',
                appUpgradeSection: {
                    heading: 'Pay for the full version:',
                    info1: '• Make, email and reprice quotes.',
                    info2: '• Make, import and export bookings.',
                    info3: '• Get your agency rates and prices.',
                    upgradeButton: 'Request our Paid Tier',
                    submittingButton: 'Processing...',
                    upgradeModal: {
                        heading: 'Confirm purchase request',
                        text1: 'Paying for CruiseConnect will allow you to see live pricing, get your own rates, and make/import/export cruise quotes and bookings.',
                        text2: 'You can also manage user in groups and with sales channels (for different rates and grades from the suppliers).',
                        text3: 'This request will let our team know you want to pay for the full version - they will contact you regarding payment soon.',
                        cancelButton: 'Cancel',
                        confirmButton: 'Yes, request',
                    },
                    inProgressMessage:
                        'Request for payment currently in progress. A member of our team will be in touch shortly to discuss payment.',
                },
                apiUpgradeSection: {
                    heading: 'Request CruiseConnect API purchase?',
                    info1: 'Use your own supplier credentials to make bookings and get your own rates and prices.',
                    upgradeButton: 'Request',
                    submittingButton: 'Processing...',
                    upgradeModal: {
                        heading: 'Confirm purchase request',
                        text1: 'Paying for CruiseConnect - API will allow you to view rates, prices and make bookings using your own supplier credentials.',
                        text2: 'This request will let our team know you want to buy the full version and they will contact you regarding payment soon.',
                        text3: 'Please note: TravelTek credentials will not be available once you start paying for the API.',
                        cancelButton: 'Cancel',
                        confirmButton: 'Yes, request',
                    },
                    inProgressMessage:
                        'Purchase for CruiseConnect API currently in progress. A member of our team will be in touch shortly to discuss payment.',
                },
            },
            apiCredentialsSection: {
                setupTitle: 'Setup your Authentication',
                setupDescriptionNoUsers:
                    'Activate your API login credentials by clicking the button below. You will receive an email with your token and instructions. These same credentials are used to access API after purchase.',
                setupDescriptionWithUsers:
                    'If you need to regenerate your API credentials, just click "Regenerate API user" button. You will receive an email with your token and instructions. These same credentials are used to access API after purchase.',
                setupDescription2a: 'Read our',
                setupDescription2b: 'for more information.',
                loading: 'Fetching user credentials...',
                generateApiCredsButton: 'Generate API Login',
                regenerateApiCredsButton: 'Regenerate',
                buttonSuccess: 'API Login Created',
                buttonCopyPassword: 'Copy password',
                username: 'Username',
                password: 'Password',
                passwordCopyWarning:
                    'Your password will only be shown once!\nMake sure you copy it!',
                errors: {
                    userExists: 'API User already exists!',
                    apiGeneric: 'Sorry, there has been an error. Please try again later.',
                    apiPassword: 'Your password will only be shown once! Make sure you copy it!',
                    apiCredentials: 'Cannot get API credentials',
                },
            },
            gettingStartedSection: {
                paid: {
                    title: 'Getting started',
                    credentialsManagement: {
                        addSalesChannels: '3. Add you Supplier Credentials in your Sales Channels:',
                        salesChannelManagementLink: 'Sales Channels Management',
                        salesChannelVideoLinkText:
                            'Watch help video for information about this step',
                        salesChannelVideoLinkUrl: 'https://www.youtube.com/watch?v=BR6CSCQ9GwU',
                        salesChannelConfluenceLinkUrl:
                            'https://traveltek.atlassian.net/wiki/spaces/TCSD/pages/2048458801/You+have+signed+up+to+a+CruiseConnect+Trial+what+next',
                        salesChannelConfluenceText: 'Read more here about step 3',
                    },
                    groupsManagement: {
                        manageGroups: '2. Manage your user groups:',
                        groupsManagementLink: 'Group Management',
                    },
                    usersManagement: {
                        addUsers: '1. Add more users:',
                        userManagementLink: 'Users Management',
                    },
                    cruiseSearch: '4. Get started by searching for a cruise',
                    productLinkText: 'Cruise Search',
                },
                free: {
                    title: 'Getting started on Free Tier',
                    description1a: 'To add more users, go to ',
                    description1aLinkText: 'Users Management',
                    description2a: 'Start searching for cruises on our ',
                    description2aLinkText: 'Cruise Search page',
                    description3:
                        'For more features you will need to pay for the full version - live pricing, your own rates and quotes/bookings.',
                },
            },
        },
        companyConfigurationPage: {
            title: 'Company Configuration',
            cardHeader: 'Company Profile',
            loading: 'Loading...',
            logoField: {
                label: 'Company Logo',
                description:
                    'Upload your company logo. This will be featured on all your quotation documentation.',
            },
            nameField: {
                label: 'Company Name',
            },
        },
        reportingPage: {
            title: 'Reporting',
            cardHeader: 'Booking report',
            loading: 'Loading...',
            export: 'Export',
            dateFrom: 'From',
            dateTo: 'To',
            errorFetchingReport: 'Sorry, something went wrong.',
        },
        subscriptionsPage: {
            title: 'Manage Subscription',
            openPortalButton: 'Access Manage Subscription widget',
        },
        userManagementPage: {
            title: 'Users Management',
            userAddSuccessMessage1: 'User ',
            userAddSuccessMessage2: ' has been invited to use CruiseConnect',
            fetchingUsers: 'Fetching users...',
            fetchingGroups: 'Fetching groups data',
            errors: {
                addingUser: 'Sorry, there has been an error adding the user.',
                fetchingUsers: 'Sorry, failed to fetch users. Please try again later.',
            },
            filter: {
                title: 'Filter Users by First Name',
                button: 'Clear filter',
            },
            list: {
                heading: 'User Accounts',
                refresh: 'Refresh list',
                userColumn: 'User',
                resetPassword: 'Reset Password',
                actionMenu: 'Actions',
                actionMenuProcessing: 'Processing...',
                edit: 'Edit',
                fetchingGroupsData: 'Fetching group data...',
                disable: 'Disable',
                enable: 'Enable',
                passwordResetSpinner: 'Resetting Password...',
                resetPasswordSuccess: 'was sent NEW pass code (valid for 7 days):',
                updateUserDetailsSuccessMessage: 'The user details have been changed successfully',
                updateUserGroupSuccessMessage: 'The user has been assigned a new group',
                assignUserError: 'There was an error assigning a group to the user',
                userRoleColumn: 'Role',
                confirmationStatusColumn: 'Status',
                linkedGroupsColumn: 'Linked Group',
                noUsers:
                    'No users found. Please use "Add User" button above to send email invites.',
                userStatus: {
                    CONFIRMED: 'Confirmed',
                    FORCE_CHANGE_PASSWORD: 'Pending Confirmation',
                    DISABLED: 'Disabled',
                },
                userRoles: {
                    ADMIN: 'Admin',
                    AGENT: 'User',
                    API: 'API access',
                },
                errors: {
                    resetPassword: 'Sorry, there has been an error resetting the password.',
                    disableUser: 'Sorry, there has been an error disabling the user.',
                    enableUser: 'Sorry, there has been an error enabling the user.',
                    updateUser: 'Sorry, there has been an error on updating user.',
                },
                resetPasswordModal: {
                    close: 'Close',
                    confirm: 'Confirm',
                    title: 'Confirm Password Reset',
                    text1: 'Are you sure you want to reset the password for user:',
                    text2: 'They will be sent a new confirmation code (valid for 7 days).',
                },
            },
            addUserButton: {
                text1: 'Create new users by providing their name and email address.',
                text2: 'An email will be sent to them with their initial access code and link to CruiseConnect.',
                text3: 'Their initial code will expire in 7 days, you will need to reset it for them if this passes.',
                addUserButton: 'Add Agent User',
                addApiUserButton: 'Create API Access User',
                modal: {
                    loadingGroupsData: 'Loading groups...',
                    title: 'Add CruiseConnect user',
                    titleApi: 'Add CruiseConnect Api user',
                    email: 'E-mail',
                    firstName: 'First Name',
                    lastName: 'Last Name',
                    groupsLabelText: 'Group(s)',
                    groupsPlaceHolderText: '-- Select Group --',
                    fetchingGroupsData: 'Fetching groups data...',
                    cancelButton: 'Cancel',
                    confirmButton: 'Confirm',
                    submittingButton: 'Submitting...',
                },
                errors: {
                    emailInput: 'Please enter a valid email address.',
                    firstNameInput: 'Please errors from 1 - 30 letters.',
                    lastNameInput: 'Please errors from 1 - 30 letters.',
                    defaultApiError: 'Sorry, failed to add user. Please try again later.',
                    groupId: 'Please choose a group.',
                },
            },
            updateUserModal: {
                text1: 'Edit user information in the form below.',
                title: 'Edit User',
                email: 'Email',
                firstName: 'First Name',
                lastName: 'Last Name',
                isAdminLabelText: 'Is Admin User',
                makeAdminLabelText: 'Make Admin User',
                groupsLabelText: 'Group(s)',
                groupsPlaceHolderText: '-- Select Group --',
                fetchingGroupsData: 'Loading groups...',
                cancelButton: 'Cancel',
                confirmButton: 'Update',
                submittingButton: 'Submitting...',
                errors: {
                    emailInput: 'Please enter a valid email address.',
                    firstNameInput: 'Please enter a valid name from 1 - 30 letters.',
                    lastNameInput: 'Please enter a valid name from 1 - 30 letters.',
                    defaultApiError: 'Sorry, failed to add user. Please try again later.',
                    groupsId: 'Please choose a group.',
                    isAdmin: 'You can not remove your own admin status.',
                },
            },
        },
        groupManagementPage: {
            title: 'Group Management',
            description:
                'Groups are used to manage users and their access to supplier credentials.',
            filter: {
                title: 'Filter by Group Name',
                button: 'Clear Filter',
            },
            list: {
                fetchingSalesChannels: 'Fetching Sales Channels...',
                heading: 'Group Accounts',
                refresh: 'Refresh list',
                noGroupsCreated:
                    'No groups created yet, use Add Group button above to create a group.',
                groupColumn: 'Group Name',
                salesChannelColumn: 'Linked Sales Channel',
                actionMenu: 'Actions',
                actionMenuProcessing: 'Processing...',
                edit: 'Edit',
            },
            errors: {
                fetchingSalesChannels:
                    'Sorry, failed to fetch Sales Channels. Please try again later. ',
                fetchingGroups: 'Sorry, failed to fetch groups. Please try again later.',
            },
            groupAddSuccessMessage: 'Group has been added to use CruiseConnect',
            fetchingGroups: 'Fetching groups data...',
            addGroupButton: {
                addGroupButtonText: 'Add Group',
                modal: {
                    modalTitle: 'Add CruiseConnect group',
                    titleField: 'Group Name',
                    descriptionField: 'Group Description',
                    phoneNumberField: 'Phone Number',
                    homeCityCodeField: 'Home City Code',
                    countryCallingCodeLabel: 'Select',
                    salesChannel: 'Sales Channel',
                    cancelButton: 'Cancel',
                    confirmButton: 'Confirm',
                    submittingButton: 'Submitting...',
                },
                errors: {
                    defaultApiError: 'Sorry, failed to add group. Please try again later.',
                    groupNameInput:
                        "You may use 1-30 characters including letters, numbers, space and ',.!#£$&@%'*+=?^,-",
                    cityCodeValueMissing: 'Please enter a your city code',
                },
            },
            updateGroupModal: {
                title: 'Edit Group',
                text1: 'Edit group information in the form below.',
                groupTitleInput: 'Group Name',
                salesChannelInput: 'Add Group to Sales Channel',
                phoneNumberInput: 'Phone Number',
                homeCityCodeField: 'Home City Code',
                countryCallingCodeLabel: 'Country Calling Code',
                salesChannelPlaceholderText: '-- Select Sales Channel --',
                cancelButton: 'Cancel',
                confirmButton: 'Update',
                submittingButton: 'Submitting...',
                errors: {
                    groupTitle:
                        "You may use 1-30 characters including letters, numbers, space and ',.!#£$&@%'*+=?^,-",
                    salesChannelId: 'A group must be associated with a sales channel',
                    apiError: 'Sorry, failed to update group. Please try again later.',
                    cityCodeValueMissing: 'Please enter a your city code',
                },
                instructionToCreateSalesChannel:
                    'To assign a group to a Channel Please go to create your Sales Channel first',
            },
        },
        salesChannelManagementPage: {
            title: 'Sales Channels Management',
            description: 'Sales Channels are used to create and manage supplier credentials.',
            addSalesChannelButton: 'Add Sales Channel',
            modal: {
                header: 'Add Sales Channel',
                titleLabel: 'New Sales Channel Name:',
                cancelButton: 'Cancel',
                confirmButton: 'Confirm',
            },
            filter: {
                title: 'Filter by Sales Channel Name',
                button: 'Clear Filters',
            },
            list: {
                heading: 'Sales Channels',
                salesChannelColumn: 'Sales Channel Name',
                groupColumn: 'Linked Group',
                noSalesChannels: 'No sales channels found.',
                noLinkedGroups: 'No linked groups found.',
                actions: {
                    actionMenu: 'Actions',
                    edit: 'Edit',
                },
            },
            fetchingSalesChannels: 'Fetching sales channels...',
            salesChannelCreated: 'sales channel has been created.',
            errors: {
                fetchingSalesChannels:
                    'Sorry, failed to fetch sales channels. Please try again later.',
                createSalesChannel: 'Sorry, there has been an error adding sales channel.',
                title: 'Please provide a valid name.',
            },
            editSalesChannel: {
                title: 'Edit Sales Channel',
                loading: 'Loading...',
                backToSalesChannelsManagement: 'Back to Sales Channels Management',
                fetchingSuppliersData: 'Fetching sales channels...',
                noSalesChannelId: 'No sales channelId found.',
                form: {
                    supplier: 'Supplier',
                    enable: 'Enable',
                    disable: 'Disable',
                    add: 'Add',
                    edit: 'Edit',
                    save: 'Save',
                    cancel: 'Cancel',
                    supplierCredentialsSaved: 'Supplier credentials saved.',
                    supplierCredentialsUpdated: 'Supplier credentials updated.',
                    supplierToggleOn: 'Supplier toggled on.',
                    supplierToggleOff: 'Supplier toggled off.',
                },
                errors: {
                    loadingSupplierList: 'Cannot load suppliers list, please try again later.',
                    loadingFieldsItems:
                        'Cannot load supplier configuration, please try again later.',
                    savingSupplierCredentials:
                        'Sorry, there has been an error saving your supplier credentials.',
                    supplierCredentialsDoNotExist:
                        'Please ensure that you have added your supplier credentials, as they do not currently exist.',
                },
            },
        },
    },
    components: {
        fieldsRequired: {
            fieldsRequired1a: 'Fields marked with',
            fieldsRequired1b: ' * ',
            fieldsRequired1c: 'are required.',
        },
        passwordInput: { showPassword: 'Show Password', hidePassword: 'Hide Password' },
        closeButton: 'close',
        sticky: {
            closeButton: 'close',
        },
        monthStrings: {
            jan: 'January',
            feb: 'February',
            mar: 'March',
            apr: 'April',
            may: 'May',
            jun: 'June',
            jul: 'July',
            aug: 'August',
            sep: 'September',
            oct: 'October',
            nov: 'November',
            dec: 'December',
        },
        errorBoundary: {
            actionButton: 'Refresh the app to try again',
            heading: 'Sorry, something has gone wrong...',
            message: 'This has been logged! We will be working to fix it shortly.',
        },
        modal: {
            close: 'close',
        },
        checkbox: {
            adding: 'Adding',
            removing: 'Removing',
        },
        datePicker: {
            years: {
                select: 'Select year',
            },
            months: {
                select: 'Select month',
                nextButton: 'Next month',
                previousButton: 'Previous month',
            },
        },
        nudger: {
            decreaseCounter: 'Decrease Value',
            increaseCounter: 'Increase Value',
        },
        infoBanner: {
            closeButton: 'close',
        },
        dateTextInput: {
            // shouldn't be here
            errors: {
                generalError: 'Please check date is valid.',
                year: 'Please check year (1900-2099).',
                month: 'Please check month is between 1-12.',
                day: 'Please check day is between 1-31.',
            },
        },
        phoneInput: {
            label: 'Phone Number',
            countryCallingCodePlaceholder: 'Select...',
            errors: {
                phoneNumber: 'Please check phone number (digits only).',
            },
        },
        pagination: {
            previousPageButtonText: 'Previous',
            nextPageButtonText: 'Next',
        },
        paginationRangeSelect: {
            entriesPerPageLabel: 'Show:',
        },
        tablePaginationButtons: {
            previousPageButtonText: 'Previous',
            nextPageButtonText: 'Next',
        },
        errorList: {
            errorCode: 'Error code',
            details: 'details',
            copyButton: 'Copy error details',
        },
    },
    cruise: {
        searchPage: {
            headerTitlePrefix: 'Cruise',
            headerTitleBold: 'Search',
            subheading1: 'cruises available over',
            subheading2a: 'Cruise Lines',
            subheading2b: 'Ships',
            subheading2c: 'Ports',
            subheading2d: 'Countries.',
            searchBar: {
                destination: 'Search by Destination, Cruise Line, Ship Name, or Port:',
                destinationPlaceHolder: "e.g. 'Miami' or 'Msc Cruise Line'",
                departure: 'Departure Date Range',
                departurePlaceHolder: 'Any...',
                duration: 'Select Duration',
                durationPlaceHolder: 'Any...',
                resultFilter: 'result filter',
                searchButton: 'Search',
            },
            advanced: {
                advancedSearchButton: 'Advanced Search',
                supplier: 'Cruise Lines',
                ship: 'Cruise Ships',
                cruiseId: 'Cruise Id',
                embarkPort: 'Port of Departure',
                shipPlaceHolderText: 'Enter your selection',
                cruiseLinePlaceHolderText: 'Enter your selection',
                cruiseIdPlaceHolderText: 'Enter your selection',
            },
        },
        resultsPage: {
            fetchingResultsText: 'Fetching cruises...',
            emptyResults: 'No results found matching your search, please refine.',
            filteredOutAll:
                'Try tweaking your filters options, no cruises matches current filter options',
            genericError: 'Sorry, something has gone wrong fetching cruises',
            title: 'Search Results',
            priceBasedOnTwoAdultsInfo:
                'Prices are based on 2 adults in a cabin. Prices include all taxes and fees.',
            counterTextPlural: ' results found, showing ',
            counterTextSingular: ' result found, showing ',
            sortLabelText: 'Sort:',
            sortOptionsText: {
                priceAscending: 'Price (low to high)',
                priceDescending: 'Price (high to low)',
                durationAscending: 'Duration (shortest)',
                durationDescending: 'Duration (longest)',
                sailDateAscending: 'Sail date (earliest)',
                sailDateDescending: 'Sail date (latest)',
            },
            filter: {
                sectionTitle: 'Filter',
                clearButton: 'Clear Filters',
                headings: {
                    toggleShowAvailable: 'Hide unavailable',
                    supplier: 'Cruise Line',
                    ship: 'Cruise Ship',
                    cabin: 'Cabin Type',
                    priceRange: 'Price',
                    cruiseName: 'Cruise Title',
                    departurePort: 'Embarkation Port',
                    arrivalPort: 'Disembarkation Port',
                    visitingPort: 'Visiting Port',
                    region: 'Region',
                },
                inputLabels: {
                    toggleShowAvailableLabel: 'Hide un-bookable cruises',
                    cruiseNameInputLabel: 'Cruise Name filter',
                    departurePortInputLabel: 'Embarkation Port filter',
                    arrivalPortInputLabel: 'Disembarkation Port filter',
                    visitingPortInputLabel: 'Visiting Port filter',
                    regionInputLabel: 'Region Port filter',
                },
            },
            resultsListItem: {
                detailsSection: {
                    nights: 'Nights',
                    itineraryListLabel: 'Itinerary:',
                    showMore: 'view full itinerary',
                    supplierNotFullyIntegratedMessage:
                        'Live fares and Cabin availability not available.',
                    supplierNotFullyIntegratedMessages: {
                        VKG: 'Live fares and Cabin availability not available for Viking Cruises.',
                        VVC: 'Live fares and Cabin availability not available for Virgin Voyages.',
                        DCL: 'Live fares and Cabin availability not available for Disney Cruises.',
                        AZA: 'Live fares and Cabin availability not available for Azamara Cruises.',
                        EXP: 'Live fares and Cabin availability not available for Explora Journeys.',
                        SSC: 'Live fares and Cabin availability not available for Silversea Cruises.',
                        AMA: 'Live fares and Cabin availability not available for AMA Waterways.',
                        RSSC: 'Live fares and Cabin availability not available for Regent Seven Seas Cruises.',
                        OCI: 'Live fares and Cabin availability not available for Oceania Cruises.',
                    },
                },
                cabinCategory: {
                    Inside: 'Inside',
                    Outside: 'Outside',
                    Balcony: 'Balcony',
                    Suite: 'Suite',
                },
                pricingSection: {
                    callToBook: 'Call us to book...',
                    callToBookWithin48hours: 'Sailing within 48 hours, please call us to book',
                    header: 'PRICES FROM:',
                    selectButton: {
                        select: 'Select',
                        call: 'Call to book',
                    },
                    taxMessage: 'Tax is NOT included in pricing displayed',
                },
                fullPricingSection: {
                    title: 'Prices',
                    close: 'close',
                    grossPrice: 'Gross',
                    militaryPrice: 'Military',
                    seniorPrice: 'OAPs',
                    taxIncluded: 'Tax is not included on the pricing display.',
                },
            },
            passengerInfoModal: {
                title: 'Passengers',
                passengerNumberNudgerHeading: 'Passengers',
                ageMessage: 'Input age of each passenger on return date',
                ratesHeading: 'Rates',
                cabinsHeading: 'Cabins',
                cancelButton: 'Cancel',
                submitButton: 'Continue',
                passenger1: 'Passenger 1',
                passenger2: 'Passenger 2',
                passenger3: 'Passenger 3',
                passenger4: 'Passenger 4',
                passenger5: 'Passenger 5',
                age: ' age',
                yearsOld: 'years old',
                message: 'Please fill out the following information for more accurate pricing',
                errors: {
                    ageInput: 'Please enter the passenger age as a valid number (1-110)',
                    minOneAdult:
                        'At least one adult (25 years old) must be present for this booking',
                },
            },
            modifySearchBar: {
                noDestinationSpecifiedText: 'Anywhere',
                destinationPlaceHolder: 'Enter destinations, cruise lines, ships or ports here...',
                durationUnits: 'Nights',
                durationPlaceHolder: 'Any duration',
            },
        },
        passengerConfigurationPage: {
            title: 'Passenger Configuration',
        },
        sailingInfo: {
            roundTripYes: 'Yes',
            roundTripNo: 'No',
            nights: 'Nights',
        },
        sailingPage: {
            spinnerCruiseLogo: 'Fetching cruise data from',
            noData: 'Previously entered session data not found. Did you copy and paste the URL??',
            title: 'Cruise Details Page',
            tabs: {
                gradesTabTitle: 'Grades',
                itineraryTabTitle: 'Itinerary',
                altSailingDatesTabTitle: 'Alt Sailing Dates',
            },
            informationSection: {
                heading: 'Information',
                informationKeys: {
                    cruiseId: 'Voyage Code',
                    departingDate: 'Departing Date',
                    returningDate: 'Returning Date',
                    duration: 'Duration',
                    seaDays: 'Sea Days',
                    roundTrip: 'Round Trip',
                    supplierName: 'Cruise Line',
                    shipName: 'Cruise Ship',
                    nights: 'Nights',
                },
            },
            rateCodeSection: {
                notFound: 'Sorry, looks like there are no rate codes to show for this cruise.',
                heading: 'Rate Codes',
                nonRefundable: 'Non Refundable',
                nonRefundableDeposit: 'Non Refundable Deposit',
                militaryRate: 'Military Rate',
                residentialRate: 'Residential Rate',
            },
            cabinGradesView: {
                priceInfoBanner:
                    'Prices displayed include discounts and associated taxes and fees. Please select the “i” indicator for a detailed price breakdown.',
                title: 'Cabin Grades',
                fareCodeHeading: 'Fare Codes',
                netFare: 'Net Fare',
                priceHeading: 'Pricing',
                displayPricing: 'Display pricing per person',
                rateGradeUnavailable: 'The rate on this grade is unavailable',
                rateGradeDoesNotExist: 'The rate on this grade does not exist',
                militaryRateHighlightText: 'Military',
                residentialRateHighlightText: 'Residential',
                cabinGradesLogoSpinner: 'Fetching Price data from',
                breakdown: {
                    noData: 'Data is in a unexpected format... This has been logged.',
                    breakdownLogoSpinner: 'Fetching price details from',
                    fetching: 'Fetching data...',
                    header: 'Price Breakdown',
                    grade: 'Cabin Grade',
                    rateCode: 'Rate code',
                    includedInPrice: 'Included in the price:',
                    onBoardCredit: 'On-Board Credit',
                    nonRefundable: 'Non Refundable',
                    militaryRate: 'Military Rate',
                    residentialRate: 'Residential Rate',
                    wifi: 'WiFi',
                    passenger: 'Guest',
                    totalCol: 'Fare Totals',
                    totalRow: 'Grand Total',
                    footNote: '* Included in fare:',
                    commission: 'Agent Commission: ',
                    agentViewAccordionTitle: 'Agent View',
                    close: 'Close',
                },
                errors: {
                    noPricesAvailable:
                        'Live pricing data is not accessible for this cruise at the moment. Click the link to return to the cruise search results to select another cruise.',
                },
            },
            itineraryView: {
                headerText: 'Itinerary',
                daysColumnHeader: 'Days',
                dateTimeColumnHeader: 'Date/Time',
                itineraryColumnHeader: 'Itinerary',
                dayNumberText: 'Day ',
                departText: 'Depart: ',
                arriveText: 'Arrive: ',
                copyButton: 'Copy Itinerary',
                copiedAfterClickText: 'Copied!',
                spinnerLogoText: 'Fetching Itinerary from',
                notFound: 'Sorry, looks like there is no itinerary to show for this cruise.',
            },
            errors: {
                incorrectParams:
                    'It appears there are no results matching your search. If you would like, you can alter your search terms and give it another shot.',
            },
        },
        sharedSections: {
            passengerDetailsModal: {
                title: 'Passenger Information',
                passengerAges: 'Passenger Ages',
                ratesHeading: 'Rates',
                cabinsHeading: 'Cabins',
                close: 'Close',
                passenger: 'Passenger ',
                accessibleCabins: 'Accessible Cabins',
                militaryRatesCabins: 'Military Rates',
                residentialRatesCabins: 'Residential Rates',
                state: 'State',
                noData: 'Previously entered session data not found. Did you copy and paste the URL??',
                true: 'Yes',
                false: 'No',
            },
            passengerConfigurationSection: {
                title: 'Passenger Configuration',
                passengersHeading: 'Passengers',
                ratesHeading: 'Rates',
                adultHeading: 'Adult',
                ratesMilitary: 'Military',
                ratesResidential: 'Residential',
                ratesNone: 'None',
                childrenHeading: 'Children',
                adultAges: 'Ages 18+',
                childrenAges: 'Ages 1 - 17',
                buttonCancel: 'Cancel',
                buttonContinue: 'Continue',
                buttonModify: 'Modify Passengers',
                passengerItem: {
                    leadPassengerHeading: 'Lead Passenger',
                    adultHeading: 'Adult',
                    childHeading: 'Child',
                    submitPastPassengerButton: 'Confirm',
                    pastPassengerLabel: 'Past Passenger',
                    pastPassengerPlaceHolder: 'Enter number here',
                    childAgeLabel: 'Child Age',
                    childAgeInstructionMessage: '(at time of sailing)',
                    childAgePlaceHolder: 'Age',
                    closeButton: 'Close',
                },
                additionalInformationSection: {
                    title: 'Additional Information',
                    militaryRatesHeader: 'Military Rates',
                    militaryRateCodesCheckBoxText: 'Include military rates',
                    residentialRatesHeader: 'Residential Rates',
                    residentialRatesCheckboxText: 'Include residential rates',
                    residencyInputPlaceholder: 'Where are you flying from?',
                    residencyInputLabel: 'residency-autosuggest',
                },
                errors: {
                    childAge: 'Please enter a valid child age!',
                    residentialValueMissing:
                        'Please enter a residential location or uncheck this option.',
                },
            },
            alternativeSailingDates: {
                buttons: {
                    select: 'Select',
                    hideDates: 'Hide Dates',
                    showDates: 'Show Dates',
                    tooltip: 'Live pricing from this supplier coming soon!',
                },
                grades: {
                    inside: 'Inside',
                    outside: 'Outside',
                    balcony: 'Balcony',
                    suite: 'Suite',
                },
                date: 'Date',
                notAvailable: '-',
                spinnerText: 'Fetching alternative sailing dates...',
                notFound:
                    'Sorry, looks like there are no alternative sailing dates for this cruise.',
            },
        },
        cabinPage: {
            title: 'Cabins',
            spinnerCruiseLogo: 'Fetching cabin data from',
            errorFetch: 'Sorry, something has gone wrong fetching the cabin data.',
            errorSubmit: 'Sorry, something has gone wrong adding cruise to basket.',
            incorrectParams:
                'It appears there are no results matching your search. If you would like, you can alter your search terms and give it another shot.',
            noCabins: 'Sorry, no available cabins were provided from the supplier',
            nonRefundable:
                'This cabin rate is non-refundable. We recommend continuing only if you are sure of this purchase.',
            informationSection: {
                spinnerText: 'Fetching cruise detail...',
                pricingButton: 'Pricing Breakdown',
                pricingTable: {
                    cabinGrade: 'Cabin Grade',
                    rateCode: 'Rate Code',
                    heading: 'Price Breakdown',
                    passenger: 'Guest',
                    totalColumn: 'Total',
                    totalRow: 'Grand Total',
                    footNote: '* Included in fare:',
                    commission: 'Commission',
                    agentViewAccordionTitle: 'Agent View',
                },
                heading: 'Information',
                informationKeys: {
                    cruiseId: 'Voyage Code',
                    embarkDate: 'Departing Date',
                    disembarkDate: 'Returning Date',
                    duration: 'Duration',
                    nights: 'Nights',
                    region: 'Region(s)',
                    supplierName: 'Cruise Line',
                    shipName: 'Cruise Ship',
                    cabinGrade: 'Cabin Grade',
                    gradeDescription: 'Grade Description',
                    cabinRate: 'Cabin Rate',
                    rateDescription: 'Rate Description',
                },
            },
            cabinPickerSection: {
                cabinsHeading: 'Cabins',
                extrasHeader: 'Dining and options',
                fieldsRequired1a: 'Fields marked with',
                fieldsRequired1b: ' * ',
                fieldsRequired1c: 'are required.',
                beddingHeading: 'Bedding',
                beddingLabel: 'Select bedding option',
                beddingPlaceHolder: 'Select option',
                diningHeader: 'Dining',
                diningLabel: 'Select dining option',
                diningPlaceHolder: 'Select option',
                modalInformationKeys: {
                    deck: 'Deck',
                    location: 'Location',
                    obstructed: 'Obstructed',
                    accessibility: 'Accessibility',
                    roomSize: 'Room size',
                    amenities: 'Amenities',
                },
                submitButton: 'Continue',
                processingSpinner: 'Processing...',
                selectCabin: {
                    heading: 'Select Cabin',
                    description:
                        'To view available cabins for each deck, select a deck using the options below',
                },
                deckDropdownPlaceholder: 'Select Deck',
                guaranteedList: {
                    header: 'Guaranteed Cabins',
                    cabinHeader: 'Cabin',
                    guaranteedTooltip: 'Cabins without specific allocation.',
                    guaranteedLabel: 'Guaranteed Cabin',
                },
                selectList: {
                    heading: 'Cabins',
                    name: 'Cabin',
                    deck: 'Deck',
                    location: 'Location',
                    obstructed: 'Obstruction',
                    accessibility: 'Accessibility',
                },
            },
            decks: {
                placeholderAltText: 'Deck image placeholder',
                noSelected: 'No deck selected',
                notAvailable: 'Deck Plan Not Available',
            },
        },
    },
    order: {
        quoteModal: {
            title: 'Email Quote',
            emailLabel: 'Customer email address',
            emailError: 'Please check email format.',
            cancelButton: 'Cancel',
            submitButton: 'Send Email',
            errorSendEmail: 'Sorry, something has gone wrong sending your quote',
            templateFields: {
                content_traveltek_ref: 'Traveltek Reference',
                content_nights: 'Nights',
                content_passengers: 'Passenger',
                content_itinerary: 'Itinerary',
                content_ship: 'Cruise Ship',
                content_deck: 'Deck',
                content_cruise_line: 'Cruise Line',
                content_rate_code: 'Rate Code',
                content_cabin_number: 'Cabin No',
                content_voyage_code: 'Voyage Code',
                content_cabin_type: 'Cabin Type',
                content_summary: 'Summary',
                content_price_fare: 'Cruise Fare',
                content_price_total: 'Total',
            },
        },
        orderPreviewPage: {
            buttons: {
                import: 'Import',
                cancel: 'Cancel',
            },
            alerts: {
                importFailedMessage:
                    'Sorry, something has gone wrong during importing order, please try again later.',
                previewAlertMessage: 'Please validate all booking information before importing.',
                previewNotSupportedMessage:
                    'For now only cruise bookings supported for retrieving.',
            },
            errors: {
                queryParamsMissing:
                    'Missing query params, please try to follow booking retrieving process again.',
                failedToRetrieve: `Failed to retrieve reference, please try again.`,
            },
        },
        orderPage: {
            pricingDifferentInfo:
                'The price for this quote has changed since the last time this order was viewed.',
            fetchingLatestPricing: 'Fetching latest pricing from supplier...',
            noProductOnOrder: 'Sorry, order was lacking product to display',
            fetchingOrder: 'Fetching your order data...',
            errorRepricingApi: 'Sorry, something has gone wrong repricing the order, order id: ',
            errorOrderApi:
                'Sorry, something has gone wrong fetching the order data using order id:',
            errorOptionalExtrasApi:
                'Sorry, something has gone wrong fetching the optional extras data.',
            title: 'Cart',

            incorrectParams:
                'Your url is missing the correct parameters. Please check you have: orderId. \n Found:',
            noProduct: 'Product is not recognised.',
            addPassenger: 'Add Passenger',
            addContactDetails: 'Update Contact Details',
            continueButton: 'Continue',
            supplierAdvisoryMessages: { header: 'Important Message from Supplier' },
            flightOrderItem: {
                airline: 'Airline',
                cabinClass: 'Cabin Class',
                cancellationPolicy: 'Cancellation Policy',
                checkedBaggage: 'Checked Bag',
                copySupplierRefButton: 'Copy Supplier Reference number',
                flightNumber: 'Flight No',
                passengers: 'Passengers',
                pricingInfo: 'Pricing Breakdown',
                segmentStops: 'Stops',
                segmentDirect: 'Non-Stop',
                supplierOrderReference: 'Supplier Ref:',
            },
            stepper: {
                cartLabel: 'Cart & Optional Extras',
                leadPassengerLabel: 'Lead Passenger',
                leadPassengerButton: 'Add Lead Passenger',
                passengerLabel: 'Passenger', // + travelerNumber
                passengerButton: 'Add Passenger', // + travelerNumber
                contactLabel: 'Contact Details',
                contactButton: 'Add Contact',
                paymentLabel: 'Payment',
                paymentButton: 'Move To Payment',
            },
            importedBooking: {
                title: 'Import Summary',
                message: 'Your booking has been imported successfully.',
                additionInfo: {
                    header: 'Additional Information',
                    flightOutbound: 'Flight Outbound',
                    flightInbound: 'Flight Inbound',
                    dateField: 'Date:',
                    flightTimes: 'Flight Times:',
                    flightNumber: 'Flight No.:',
                    packageId: 'Package ID:',
                    preCruiseStay: 'Pre-Cruise Stay Hotel',
                    hotelRoomType: 'Room Type:',
                    transfer: 'Transfer',
                    transferPrivate: 'Private Transfer',
                    transferField: 'Transfer:',
                    shoreExcursions: 'Shore Excursions',
                    postCruiseStay: 'Post-Cruise Stay Hotel',
                    day: 'Day',
                },
            },
            cruiseOrderItem: {
                supplierOrderReference: 'Supplier Reference:',
                copySupplierRefButton: 'Copy Supplier Reference number',
                cabinNumber: 'Cabin No.',
                cabinType: 'Cabin Type',
                cancellationPolicy: 'Cancellation Policy',
                cruiseId: 'Voyage Code',
                deck: 'Deck',
                holdTime: 'Hold Time Info',
                itinerary: 'Itinerary',
                information: 'Information',
                nights: 'Nights',
                passengers: 'Passengers',
                pricingInfo: 'Pricing Breakdown',
                rateCode: 'Rate Code',
                voyageCode: 'Voyage Code',
                departureDate: 'Departure Date',
                returnDate: 'Return Date',
                duration: 'Duration',
                refresh: 'Refresh',
                supplierName: 'Cruise Line',
                shipName: 'Cruise Ship',
                priceBreakDown: {
                    header: 'Price Breakdown',
                },
                statuses: {
                    revertedToOnHold: '** On Hold ** (Payment failed)',
                    onHold: 'On Hold',
                    fullPayment: 'Booked as full payment',
                    deposit: 'Booked as deposit',
                },
                sendQuote: 'Send Quote',
                copyWithFormatting: 'Cruise Info',
                copyWithOutFormatting: 'Cruise Text',
                copiedAfterClickText: 'Copied!',
                pricingSummary: {
                    priceAccurateAsOf: 'Price accurate as of: ',
                    fetchingLatestUpdateDate: 'Fetching most up to date pricing...',
                    optionalExtras: 'Optional Extras',
                    none: 'None',
                    total: 'Total',
                },
            },
            optionalExtras: {
                fetchingExtras: 'Fetching optional extras...',
                heading: 'Optional Extras',
                noOptionalExtras: 'No optional extras available.',
                errorOptionalExtrasApi:
                    'Sorry, something has gone wrong submitting optional extras.',
            },
            missingPassengerDetailsBanner: {
                leadPassenger: 'Please provide lead passenger details.',
                leadPassengerButton: 'Add Lead Passenger',
                passenger: 'Please provide details of passenger',
                passengerButton: 'Add Passenger',
                contactDetails:
                    'Please provide your contact details to proceed to the payment page.',
                contactDetailsButton: 'Add Contact Details',
            },
            orderPricingSummary: {
                orderRef: 'Traveltek Reference:',
                heading: 'Summary',
                total: 'Total',
            },
            orderPaymentSchedule: {
                heading: 'Payment Schedule',
                due: 'Due',
            },
            passengerSummarySection: {
                passengerSummary: 'Passengers Summary',
                passenger: 'Passenger',
                leadPassenger: 'Lead Passenger',
                contactDetails: 'Contact Details',
                preferencesTitle: 'Preferences',
                militaryRates: 'Military Rates',
                residentialRates: 'Residential Rates',
                noPreferences: 'None specified',
                residencyLocation: 'Location:',
                preferencesSmallText:
                    'Information based on passenger configuration, not on selected rate code.',
                edit: 'Edit',
                add: 'Add',
                pastPassenger: 'Past Passenger',
            },
            travellerAddForm: {
                headingTitle: 'Passengers',
                heading: 'Passenger Details',
                edit: 'Edit',
                add: 'Add',
                backButton: 'Back to Order',
                continueWithoutSavingButton: 'Continue without saving',
                instructionText: 'Information must match passport details',
                leadPassenger: 'Lead Passenger',
                otherPassenger: 'Passenger',
                personalInformation: 'Personal Information',
                title: 'Title',
                titlePlaceHolder: 'Please select...',
                pastPassenger: 'Past Passenger',
                passengerFirstName: 'First Name',
                middleName: 'Middle Name',
                passengerLastName: 'Last Name',
                noMiddleName: 'No middle name',
                dateOfBirth: {
                    minAgeValidationError: 'Lead Passenger age should be greater than',
                    groupLabel: 'Date of Birth',
                    month: 'Month',
                    day: 'Day',
                    year: 'Year',
                },
                passportInfo: {
                    groupLabel: 'Passport Information',
                    nationality: 'Nationality',
                    nationalityPlaceholder: 'Select your nationality',
                    passportNumber: 'Passport Number',
                    startDate: {
                        groupLabel: 'Passport Start Date',
                        month: 'Month',
                        day: 'Day',
                        year: 'Year',
                    },
                    expiryDate: {
                        groupLabel: 'Passport Expiry Date',
                        month: 'Month',
                        day: 'Day',
                        year: 'Year',
                    },
                },
                accessibility: 'Accessibility',
                accessibilityCheckbox: 'Check if passenger has accessibility needs',
                fieldsAreRequired: 'Fields marked with * are required',
                emergencyContact: {
                    groupLabel: 'Emergency Contact Information',
                    firstName: 'First Name',
                    lastName: 'Last Name',
                    phoneNumber: {
                        groupLabel: 'Phone Number',
                        countryCallingCode: 'Country calling code',
                        countryCallingCodePlaceholder: 'Pick',
                        phoneNumberNoCountryCode: 'Phone number',
                    },
                    email: 'Email',
                    fillAllFieldsMessage:
                        'To add emergency contact information all fields below must be filled out.',
                },
                savePassengerButton: 'Save Passenger',
                errors: {
                    name: "Please check name characters only include a-z and ',.\\- ",
                    birthDate: 'Please check date of birth is valid.',
                    birthYear: 'Please check year (1900-2099).',
                    month: 'Please check month is between 1-12.',
                    day: 'Please check day is between 1-31.',
                    passportNumber:
                        'Please check passport format A-Z, 0-9, between 8-9 chars long.',
                    passportStartYear: 'Please check year (1900-2099).',
                    passportEndYear: 'Please check year (1900-2099).',
                    passportStartDate: 'Please check start date is valid.',
                    passportEndDate: 'Please check expiry date is valid.',
                    email: 'Please check email format.',
                    phoneNumber: 'Please check phone number (digits only).',
                    apiError: 'Sorry, something has gone wrong processing this request.',
                },
            },
            contactDetailsAddForm: {
                headingTitle: 'Contact Details',
                heading: 'Contact',
                sameAsLeadPassenger: 'Same as Lead Passenger',
                personalInformation: 'Personal Information',
                backButton: 'Back to Order',
                continueWithoutSavingButton: 'Continue without saving',
                title: 'Title',
                firstName: 'First Name',
                middleName: 'Middle Name',
                noMiddleName: 'No middle name',
                lastName: 'Last Name',
                contactInformation: 'Contact Information',
                addressLine1: 'Address line 1',
                addressLine2: 'Address line 2',
                city: 'City',
                stateProvince: 'State/Province',
                country: 'Country',
                zipPostalCode: 'Zip/Postal Code',
                email: 'Email',
                confirmEmail: 'Confirm Email Address',
                phoneNumber: 'Phone Number',
                countryCallingCode: 'Country Calling Code',
                phoneNumberNoCountryCode: 'Phone number',
                fieldsRequired: 'Fields marked with * are required.',
                titlePlaceHolder: 'Select title',
                submitContactForm: 'Save Contact',
                areaCodePlaceholder: '+...',
                genericError: 'Something went wrong',
                errors: {
                    name: "Please check name characters only include a-z and ',.\\- ",
                    email: 'Please check email format.',
                    phoneNumber: 'Please check phone number (digits only).',
                    apiError: 'Sorry, something has gone wrong processing this request.',
                },
            },
            paymentForm: {
                headingTitle: 'Payment',
                generalError: 'Sorry, something went wrong. Please try again later.',
                paymentOptions: {
                    heading1a: 'Select ',
                    heading1b: 'Deposit Only, Full Payment ',
                    heading1c: 'or ',
                    heading1d: 'On Hold',
                    dueBy: 'Due',
                },
                fullPaymentButton: 'Full Payment',
                onHoldPaymentButton: 'On Hold',
                depositButton: 'Deposit',
                cardForm: {
                    heading: 'Payment Details',
                    infoDepositPay: 'Deposit payment of: ',
                    infoFullPay: 'Full payment of: ',
                    nameOnCardInput: 'Name On Card',
                    cardNumberInput: 'Card Number',
                    expiryDateInput: 'Expiry Date',
                    expiryMonthInput: 'Month',
                    expiryYearInput: 'Year',
                    cvcInput: 'CVC',
                    cardTypeInput: 'Card Type',
                    cardTypePlaceholder: 'Select your card type',
                },
                submitPayment: 'Complete Booking',
                submittingPayment: 'Processing...',
                backButton: 'Back to Order',
                errors: {
                    cardValidationError: 'This credit card number is not valid',
                    cardNumberError:
                        'This credit card number is not valid, numbers only min 15 and max 16',
                    cardExpireMonthError: 'Month value is 1 to 12',
                    cardExpireYearError: 'Year value is 2 digits',
                    cardCvvError: 'CVC value is 3 or 4 digits',
                    cardTypeError: 'Incorrect card type selected for card number',
                    cardHolderNameError: "Letters and special characters ' . - only",
                    cardExpiredError: 'This card has expired',
                },
            },
            bookedPassengerDetails: {
                contactInformation: {
                    heading: 'Contact Information',
                    name: 'Name',
                    address: 'Address',
                    city: 'City',
                    stateProvince: 'State/Province',
                    zipPostalCode: 'ZIP/Postal Code',
                    country: 'Country',
                    email: 'Email Address',
                    phoneNumber: 'Phone Number',
                },
                passengerInformationHeading: 'Passenger Summary',
                passengerInformation: {
                    leadPassengerHeading: 'Lead Passenger',
                    nonLeadPassengerHeading: 'Passenger',
                    name: 'Name',
                    dateOfBirth: 'Date of Birth',
                    nationality: 'Nationality',
                    passportNumber: 'Passport Number',
                    passportStartDate: 'Passport Start Date',
                    passportExpiryDate: 'Passport Expiry Date',
                },
                paymentInformation: {
                    heading: 'Payment Information',
                    cardLastFourDigits: 'Visa card ending in ',
                    name: 'Name',
                    address: 'Address',
                    city: 'City',
                    stateProvince: 'State/Province',
                    zipPostalCode: 'ZIP/Postal Code',
                    country: 'Country',
                },
            },
            confirmationPage: {
                headingTitle: 'Booking Confirmation',
                bookingSuccessful: 'Your booking has been made successfully',
                paymentFailedButPutOnHold:
                    'Cruise line has put this booking on hold because the payment failed. Please contact the cruise line with the supplier reference to make a payment.',
            },
            bookedView: {
                headingTitle: 'Booked Order',
                bookedInfoBanner: 'This was booked on',
            },
        },
        allOrdersPage: {
            fetchingOrder: 'Fetching your orders...',
            fetchingQuote: 'Fetching your quotes...',
            errorOrderApi: 'Sorry, something has gone wrong fetching your orders',
            title: 'Orders and Quotes',
            quotesTab: 'Quotes',
            ordersTab: 'Orders',
            tableContent: {
                reference: 'Reference',
                dateBooked: 'Date Booked',
                dateCreated: 'Date Created',
                supplier: 'Supplier',
                departureDate: 'Dept. Date',
                passenger: 'Passenger',
                total: 'Total',
                status: 'Status',
                actions: 'Actions',
                viewQuote: 'View',
                emailQuote: 'Email',
                imported: 'Imported',
                orderLinkText: 'View',
                noUnfulfilledOrdersFoundText: 'No quotes found',
                noBookedOrdersFoundText: 'No orders found',
            },
        },
    },
    cognito: {
        checkingAuth: 'Checking authorisation...',
        unavailable: 'This page is unavailable.',
        cognitoErrors: {
            tooManyFailedAttempts: 'Too many failed attempts, please try again later.',
            notAuthorized: 'Incorrect username or password.',
            userNameNotFound: 'Email not found.',
            codeMismatch: 'Invalid confirmation code entered, please try again.',
            expiredCode: 'Confirmation code has expired, please request a new one.',
            unknown: 'Sorry, something went wrong, please try again later or contact us.',
        },
        loginForm: {
            title: 'Log In to CruiseConnect',
            tryNowButtonText: 'Try Now',
            showPasswordToggle: 'Show Password',
            submitButton: 'Log In',
            signUpLinkText: 'Sign Up',
            signUpText: "Don't have an account yet?",
            userEmailInput: 'Email',
            passwordInput: 'Password',
            forgotPasswordLinkText: 'Reset/Forgot password?',
            errors: {
                userEmailInput: 'Please check your email',
                passwordInput: 'Please check your password.',
                generic: 'We had a problem logging you in, please try again later.',
            },
        },
        newPasswordForm: {
            title: 'Choose password',
            showPasswordToggle: 'Show Password',
            submitButton: 'Submit New Password',
            submitting: 'Processing...',
            cancelButton: 'Cancel',
            subText:
                'Must be 8-30 characters, include one lowercase letter, uppercase letter, number and special character.',
            passInput: 'Password',
            sessionExpiredErrorButton: 'Log in again',
            errors: {
                passInput:
                    'Please enter between 10 and 30 characters, at least one lowercase letter, one uppercase letter, one number and a special character',
                apiError: 'Error setting new password, please try again later.',
                sessionExpiredError: 'Your session has expired, please reset your session.',
                resetSessionButton: 'Reset Session',
            },
        },
        forgotPasswordForm: {
            title: 'Forgot password',
            text: 'You will receive a code by email that you will need to choose a new password.',
            emailInput: 'Email',
            submitButton: 'Send confirmation code',
            submitting: 'Processing..',
            cancelButton: 'Cancel',
            errors: {
                emailInput: '(Is this a valid email? contact us if we are wrong)',
            },
        },
        resetPasswordForm: {
            title: 'Reset password',
            emailInput: 'Email',
            passInput: 'New Password',
            showPasswordToggle: 'Show Password',
            codeInput: 'Confirmation Code',
            submitButton: 'Reset Password',
            submitting: 'Processing...',
            cancelButton: 'Cancel',
            errors: {
                codeInput: 'Confirmation code is required.',
                emailInput: '(Is this a valid email? contact us if we are wrong)',
                passInput:
                    'Please enter between 10 and 30 characters, at least one lowercase letter, one uppercase letter, one number and a special character',
                resetPasswordError: 'Sorry, something went wrong, please try again later.',
            },
            goToForgotPasswordButton: 'Forgot Password',
            goToLogInButton: 'Log In',
        },
    },
    error: {
        userData: {
            noAccessToken: 'No access token!',
            noIdToken: 'No id token!',
        },
        network: {
            badRequest: 'Bad request. Please try again later.',
            unauthorized: 'Unauthorized error. Try to log in again.',
            forbidden: 'Forbidden error. Please try again later.',
            notFound: 'The requested resource does not exist on the server.',
            validation: 'Validation error occurred on the server. Check your input and try again.',
            internalServer:
                'An internal error occurred on the server. This may be because of an application error or configuration problem.',
            serviceUnavailable: 'The server is currently unavailable. Please try again later.',
            gatewayTimeout: 'The server did not respond in time. Please try again.',
            unknown: 'An error occurred, please try again later.', // NOTE: if you are seeing this error during tests locally, its likely your test file needs to mock datadoglogger.info: jest.fn()
        },
    },
}

export default content
