/** capitalize all words of a string
 * @param {string} string - a string of words, seperated by spaces, to each be capitalized.
 * @return {string} the input string now with each word capitalized letter */
export const capitalizeEachWord = (string: string): string => {
    // regex matches first character and every character after any white space, or word break characters not including ' but including - ( / &.
    return (
        string?.toLowerCase().replace(/(?:^|[\d\s\-(&/])([a-z])/g, (a) => a.toUpperCase()) || string
    )
}

/**
 * insertDecimal2CharsFromEnd is for price values in cents/pence etc. which need to be displayed as dollars/pounds etc.
 * ONLY FOR DISPLAY PURPOSE, ONLY FOR PENCE/CENTS VALUES WITHOUT CURRENCY SYMBOL
 * It simply converts to a string and inserts a decimal place 2 characters from the end, adding zeros if
 * for 10's or 1's pence/cents values.
 * @return {string | number} the input returned as a string or number with decimal place 2 characters from end
 * @param value
 */
export const insertDecimal2CharsFromEnd = (value: string | number): string => {
    if (value === null || value === undefined) return value
    const stringValue = `${value}`
    if (!isPurelyDigits(value)) return stringValue

    if (stringValue.length === 2) return '0.' + stringValue
    if (stringValue.length === 1) return '0.0' + stringValue
    return stringValue.slice(0, -2) + '.' + stringValue.slice(-2)
}

/**
 * isPurelyDigits returns true if value is number or string purely of digits
 * @return boolean
 * @param value
 */
export const isPurelyDigits = (value: string | number): boolean => {
    // check for null or undefined values, NOTE that 0 is valid but also returns false hence the !== 0 check
    if (value !== 0 && !value) return false

    const input = value.toString()
    const regex = /[^-\d]/
    const noneCentsPenceValueFound = input.match(regex)

    return !noneCentsPenceValueFound
}

export const combineCurrencyAndPrice = (value: string, currency: string): string => {
    if (!value.startsWith('-')) {
        return `${currency}${value}`
    } else {
        value = value.substring(1)
        return `-${currency}${value}`
    }
}

/**
 * createStringFromList return string for render from the list of multiple strings
 * @return string
 * @param array list of strings to join, anything item that not a string will be ignored
 * @param separator optional separator for using while joining
 */
export const createStringFromList = (array: any[], separator = ', '): string => {
    return array.filter((str) => typeof str === 'string').join(separator)
}

/**
 * formatPricePenceToPounds return formatted price like "1.00" from a pence/cents value
 * @return string
 * @param price price in pence/cents 2930 value to pounds/dollars 29.30
 */
export const formatPricePenceToPounds = (price?: string | number): string => {
    if (price) return `${Number(+price / 100).toFixed(2)}`
    return ''
}

/** Can only take number (not string) values, and rounds decimals to 3 places. */
export const insertNumberCommas = (number: number): string => {
    return number.toLocaleString()
}
