import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import Button from 'components/basics/Button/Button'
import FieldError from 'components/basics/FieldError/FieldError'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import Modal from 'components/blocks/Modal/Modal'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import { getRestErrorMessage } from 'utils/api-errors-helpers/get-rest-error-message'
import { HTTP_METHODS, PRODUCT_TYPES, REGEX, VITALLY_EVENTS } from 'utils/constants'
import CustomerSuccess from 'services/customerSuccess/customerSuccess.service'
import * as self from './SendQuoteModal'

import styles from './SendQuoteModal.module.css'
import allContent from 'content/content'
import callApi from 'services/callApi/callApi.service'

const content = allContent.order.quoteModal

export interface QuoteTemplateStaticFields {
    content_traveltek_ref: string
    content_nights: string
    content_passengers: string
    content_itinerary: string
    content_ship: string
    content_deck: string
    content_cruise_line: string
    content_rate_code: string
    content_cabin_number: string
    content_voyage_code: string
    content_cabin_type: string
    content_summary: string
    content_price_fare: string
    content_price_total: string
}

export interface QuoteTemplateDynamicFields {
    cruise_name: string
    traveltek_ref: string
    embark_date: string
    disembark_date: string
    duration: string
    passengers_count: string
    itinerary: string
    ship_name: string
    deck_number: string
    cruise_line: string
    rate_code: string
    cabin_number: string
    voyage_code: string
    cabin_type: string
    price_currency_sym: string
    price_fare: string
    price_total: string
    price_currency: string
}

export interface QuoteTemplateFields
    extends QuoteTemplateDynamicFields,
        QuoteTemplateStaticFields {}

const EMAIL_API_URL = process.env.REACT_APP_SEND_EMAIL_QUOTE_SERVICE

export const submitSendingQuoteEmail = (
    templateData: QuoteTemplateFields,
    recipientEmail: string,
    handleClose: () => void,
    setIsSubmitting: (value: React.SetStateAction<any>) => void,
    setError: (value: React.SetStateAction<any>) => void
): void => {
    if (EMAIL_API_URL) {
        setIsSubmitting(true)
        const variables = {
            recipient: recipientEmail,
            template_data: templateData,
            template_name: 'OrderQuote',
        }
        callApi({
            url: EMAIL_API_URL + '/quote-email',
            method: HTTP_METHODS.POST,
            variables,
            source: 'SendQuoteEmail',
        })
            .then(() => {
                handleClose()
            })
            .catch((error: any) => {
                const parsedError = getRestErrorMessage({
                    errors: error.cause,
                    source: 'SendQuoteEmail',
                })
                setError(parsedError)
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }
}

export interface SendQuoteModalProps {
    isOpen: boolean
    onClose: () => void
    returnFocusId: string
    templateData: QuoteTemplateDynamicFields | null
}

const SendQuoteModal: React.FC<SendQuoteModalProps> = ({
    isOpen,
    onClose,
    returnFocusId,
    templateData,
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [error, setError] = useState(null)
    useEffect(() => {
        if (isOpen) {
            const location = window.location.pathname === '/orders' ? 'All Orders' : 'Quote'
            CustomerSuccess.track({
                eventName: VITALLY_EVENTS.EMAIL_QUOTE_MODAL_OPEN,
                properties: {
                    page: location,
                    productType: PRODUCT_TYPES.CRUISE,
                    supplier: templateData?.cruise_line,
                },
            })
        }
    }, [isOpen, templateData?.cruise_line])
    const {
        control,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            customerEmail: '',
        },
    })

    const handleClose = (): void => {
        onClose()
        reset()
    }

    return (
        <Modal
            headerText={content.title}
            isOpen={isOpen}
            returnFocusId={returnFocusId}
            setClosed={handleClose}
            contentClassName={styles.modalContent}
        >
            {error && (
                <InfoBanner
                    id='api-error-banner'
                    bannerType='error'
                    text={`${content.errorSendEmail}`} // no need to log as api error is already logged
                    isCloseable={false}
                />
            )}
            <form
                className={styles.modalForm}
                onSubmit={handleSubmit(({ customerEmail }) => {
                    if (customerEmail && templateData)
                        self.submitSendingQuoteEmail(
                            { ...templateData, ...content.templateFields },
                            customerEmail,
                            handleClose,
                            setIsSubmitting,
                            setError
                        )
                })}
            >
                <Controller
                    control={control}
                    name='customerEmail'
                    rules={{ pattern: REGEX.EMAIL, required: true }}
                    render={({ field: { value, ref } }): React.ReactElement => (
                        <div>
                            <LabelledInput
                                htmlFor='customerEmail'
                                label={content.emailLabel}
                                disabled={isSubmitting}
                                required={true}
                                ref={ref}
                                isErrored={!!errors.customerEmail}
                            >
                                <TextInput
                                    value={value}
                                    inputMode='email' // for mobile keyboard selection
                                    onChangeCallback={(value): void => {
                                        setValue('customerEmail', value)
                                    }}
                                />
                            </LabelledInput>
                            <FieldError
                                errorMessage={content.emailError}
                                showError={!!errors.customerEmail}
                                inputId='customerEmail'
                            />
                        </div>
                    )}
                />
                <div className={styles.actions}>
                    <Button
                        text={'Cancel'}
                        disabled={isSubmitting}
                        onClick={onClose}
                        flavour='tertiary'
                        type='button'
                    />
                    <Button text={'Send Email'} showSpinner={isSubmitting} type='submit' />
                </div>
            </form>
        </Modal>
    )
}

export default SendQuoteModal
