import React, { SetStateAction, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Auth } from '@aws-amplify/auth'
import { datadogLogs } from '@datadog/browser-logs'
import { CognitoUser } from 'amazon-cognito-identity-js'

import Button from 'components/basics/Button/Button'
import FieldError from 'components/basics/FieldError/FieldError'
import FieldsRequired from 'components/basics/FieldsRequired/FieldsRequired'
import Heading from 'components/basics/Heading/Heading'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import Link from 'components/basics/Link/Link'
import PasswordInput from 'components/basics/Input/PasswordInput/PasswordInput'
import Spacing from 'components/basics/Spacing/Spacing'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { getCognitoErrorMessage } from 'utils/cognito-helpers/get-cognito-error-message'
import { extractCognitoFields } from 'utils/cognito-helpers/extract-cognito-fields'
import { COGNITO_ACTIONS, REGEX, USER_ROLES } from 'utils/constants'

import styles from './LoginForm.module.css'
import allContent from 'content/content'
import { useNavigate } from 'react-router-dom'

const content = allContent.cognito.loginForm

type LoginFormDataType = {
    userEmail: string
    password: string
}

type LoginFormProps = {
    setUserData: React.Dispatch<SetStateAction<GlobalContextUserData>>
    setNewPassCognitoUser: React.Dispatch<SetStateAction<CognitoUser | undefined>>
}

const LoginForm: React.FC<LoginFormProps> = ({ setUserData, setNewPassCognitoUser }) => {
    const navigate = useNavigate()
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [loginApiError, setLoginApiError] = useState<string>('')

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginFormDataType>({
        reValidateMode: 'onChange', // only comes into effect after submit has been pressed... doesn't work for revalidating otherwise so not that useful
        defaultValues: {
            userEmail: '',
            password: '',
        },
    })

    async function handleSubmitClick(formFields: LoginFormDataType): Promise<void> {
        setIsSubmitting(true)
        setLoginApiError('')
        await Auth.signIn(formFields.userEmail.trim(), formFields.password.trim()) // Use Trim to remove leading and trailing whitespace - common error from user copying and pasting code from email on first login
            .then((user) => {
                const userData = extractCognitoFields(user)
                datadogLogs.logger.info(`source: Login, userEmail: ${formFields.userEmail}`, {
                    userContext: { userEmail: formFields.userEmail },
                })
                if (!userData.isActiveTenant) {
                    datadogLogs.logger.info(
                        `source: Login InActiveTenant, userEmail: ${formFields.userEmail}`,
                        {
                            userContext: userData,
                        }
                    )
                    navigate(ROUTES.INACTIVE_TENANT, {
                        state: {
                            isInactiveTenant: true,
                        },
                    })
                } else if (userData.userRoles.includes(USER_ROLES.API_USER)) {
                    datadogLogs.logger.info(
                        `source: Login API USER, userEmail: ${formFields.userEmail}`,
                        {
                            userContext: userData,
                        }
                    )
                    navigate(ROUTES.API_USER_WARNING, {
                        state: {
                            isApiUser: true,
                            email: formFields.userEmail,
                        },
                    })
                } else if (user.challengeName === COGNITO_ACTIONS.new_password) {
                    /** NEW_PASSWORD challenge - happens after password reset login or during account signup */

                    setNewPassCognitoUser(user)
                } else {
                    setUserData(userData)
                    datadogLogs.setGlobalContext(userData)
                }
            })
            .catch((error) => {
                setIsSubmitting(false)
                setLoginApiError(getCognitoErrorMessage(error?.name || 'unknown'))
                datadogLogs.logger.warn(
                    `source: Login, error using email: ${formFields.userEmail}`,
                    { userEmail: formFields.userEmail },
                    error
                )
            })
    }

    return (
        <div className={styles.container}>
            <form
                className={styles['login-form']}
                onSubmit={handleSubmit(async (formFields: LoginFormDataType) => {
                    await handleSubmitClick(formFields)
                })}
            >
                <Heading heading='1'>{content.title}</Heading>
                <fieldset disabled={isSubmitting} className={styles['field-set']}>
                    <legend className='visually-hidden'>{`${content.userEmailInput} + ${content.passwordInput}`}</legend>
                    <Controller
                        control={control}
                        name='userEmail'
                        rules={{
                            required: true,
                            minLength: 1,
                            maxLength: 300,
                            pattern: REGEX.EMAIL,
                        }}
                        render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                            <div className={styles['inputs-wrapper']}>
                                <LabelledInput
                                    required={true}
                                    htmlFor='userEmail'
                                    label={content.userEmailInput}
                                    aria-describedby='userEmail-error-message'
                                >
                                    <TextInput value={value} onChange={onChange} onBlur={onBlur} />
                                </LabelledInput>
                                <FieldError
                                    inputId='userEmail'
                                    showError={!!errors.userEmail}
                                    errorMessage={content.errors.userEmailInput}
                                />
                            </div>
                        )}
                    />
                    <Controller
                        control={control}
                        name='password'
                        rules={{
                            required: true,
                            minLength: 6,
                            maxLength: 30,
                        }}
                        render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                            <div className={styles['inputs-wrapper']}>
                                <LabelledInput
                                    required={true}
                                    htmlFor='password'
                                    label={content.passwordInput}
                                    aria-describedby='password-error-message'
                                >
                                    <PasswordInput
                                        className={styles['password-input']}
                                        autoComplete='off'
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                    />
                                </LabelledInput>
                                <FieldError
                                    inputId='password'
                                    showError={!!errors.password}
                                    errorMessage={content.errors.passwordInput}
                                />
                            </div>
                        )}
                    />
                    <div className={styles['align-right']}>
                        <Link bold={true} to={ROUTES.FORGOT_PASSWORD}>
                            {content.forgotPasswordLinkText}
                        </Link>
                    </div>
                </fieldset>
                {!errors.password && <Spacing size='single' />}
                <FieldsRequired className={styles['align-left']} />
                <Button
                    className={styles['submit-button']}
                    type='submit'
                    flavour='primary'
                    text={isSubmitting ? 'Submitting' : content.submitButton}
                    showSpinner={isSubmitting}
                />
                {!!loginApiError && (
                    <InfoBanner
                        id='reset-password-api-error'
                        bannerType='error'
                        text={loginApiError}
                        isFocusable={true}
                    />
                )}
                <p className={styles['signup-text']}>
                    {content.signUpText}
                    <Link bold={true} to={ROUTES.SIGNUP}>
                        {content.signUpLinkText}
                    </Link>
                </p>
            </form>
        </div>
    )
}

export default LoginForm
