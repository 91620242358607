import React, { useEffect, useMemo } from 'react'

import {
    categoryOrderMap,
    DESTINATION_CATEGORIES,
    DestinationCategory,
} from 'components/sections/cruise/CategoryIndicator/CategoryIndicator'
import Heading from 'components/basics/Heading/Heading'
import Spacing from 'components/basics/Spacing/Spacing'
import SearchBar from 'components/sections/cruise/SearchBar/SearchBar'
import Text from 'components/basics/Text/Text'

import { usePassengersInfo } from 'components/hooks/usePassengersInfo'
import { OnSubmitSearchBarData } from 'components/pages/cruise/SearchPage'
import { CruisesMetaData } from 'api-data-models/CruisesContentModel'
import { capitalizeEachWord, insertNumberCommas } from 'utils/string-helpers'
import { extraDestinationsList } from 'data/extraDestinationsList'

import styles from './SearchLayout.module.css'
import allContent from 'content/content'

const content = allContent.cruise.searchPage

export type DestinationDataItem = {
    category: DestinationCategory
    name: string
    hits?: number | string
}

function createDestinationAutosuggestData(cruisesMetaData: CruisesMetaData): DestinationDataItem[] {
    const countryOptions = cruisesMetaData.allCountries.map((country) => {
        return {
            name: capitalizeEachWord(country.name),
            category: DESTINATION_CATEGORIES.COUNTRY,
            hits: country.hits,
        }
    })

    const extraDestinationOptions: DestinationDataItem[] = extraDestinationsList.map(
        (destination: string): DestinationDataItem => {
            return {
                name: destination,
                category: DESTINATION_CATEGORIES.DESTINATION,
            }
        }
    )

    const regionOptions: DestinationDataItem[] = cruisesMetaData.allRegions.map(
        (region): DestinationDataItem => {
            return {
                name: capitalizeEachWord(region.name),
                category: DESTINATION_CATEGORIES.REGION,
                hits: region.hits,
            }
        }
    )

    const shipNameOptions = cruisesMetaData.allShips.map((ship) => {
        /** Split the ship name from its code */
        const shipName = ship.name.split('|')[1]
        return {
            name: capitalizeEachWord(shipName),
            category: DESTINATION_CATEGORIES.SHIP,
            hits: ship.hits,
        }
    })

    const supplierOptions = cruisesMetaData.allSuppliers.map((supplier) => {
        /** Split the supplier name from its code */
        const supplierName = supplier.name.split('|')[1]
        return {
            name: capitalizeEachWord(supplierName),
            category: DESTINATION_CATEGORIES.SUPPLIER,
            hits: supplier.hits,
        }
    })

    const portOptions = cruisesMetaData.allUnPorts.map((port) => {
        return {
            name: capitalizeEachWord(port.name),
            category: DESTINATION_CATEGORIES.PORT,
            hits: port.hits,
        }
    })

    return [
        ...extraDestinationOptions,
        ...regionOptions,
        ...countryOptions,
        ...shipNameOptions,
        ...supplierOptions,
        ...portOptions,
    ]
}

/** SORTING OF AutoSuggest categories CategoryIndicator function */
export function getSortedDestinationOptions(
    cruisesMetaData: CruisesMetaData
): DestinationDataItem[] {
    const destinationData = createDestinationAutosuggestData(cruisesMetaData)
    destinationData.sort((a, b) => categoryOrderMap[a.category] - categoryOrderMap[b.category])
    return destinationData
}

type SearchLayoutProps = {
    /** For saved params (pre-populated form), need to know the category to submit string value to api - or use 'destination' as default */
    prePopulateDestinationCategory?: DestinationCategory
    onSubmit: (data: OnSubmitSearchBarData, allFormFields: Record<string, any>) => void
    /** Data for driving the autoSuggest */
    cruisesMetaData?: CruisesMetaData
}

/** SearchLayout: Layout for the Search page */
const SearchLayout = ({
    onSubmit,
    prePopulateDestinationCategory = DESTINATION_CATEGORIES.DESTINATION,
    cruisesMetaData,
}: SearchLayoutProps): JSX.Element => {
    const useAutoSuggest =
        !!cruisesMetaData?.numberOfResults && // can't use next check without first checking if cruisesMetaData exists
        cruisesMetaData.numberOfResults > 0 // only show auto suggest if there are results to populate suggestions

    /** Remove passengerConfiguration when landing on a search page, results page will use default passengerConfiguration */
    const { clearPassengers } = usePassengersInfo()

    useEffect(() => {
        clearPassengers()
    }, [clearPassengers])

    /** SORT THE DESTINATION CATEGORIES */
    const destinationData = useMemo(() => {
        if (cruisesMetaData) return getSortedDestinationOptions(cruisesMetaData)
        return []
    }, [cruisesMetaData])

    return (
        <div className='general-container'>
            <div className={styles.container}>
                <Spacing size='quadruple' />
                <div className={styles.header}>
                    <Heading heading='1HeroTitle' onDarkBackground={true}>
                        <span className={styles['not-bold']}>{content.headerTitlePrefix}</span>
                        <span>{content.headerTitleBold}</span>
                    </Heading>
                    {useAutoSuggest ? (
                        <Text weight='bold' color='white' size='M'>
                            {`${insertNumberCommas(cruisesMetaData.numberOfResults)} ${
                                content.subheading1
                            } ${cruisesMetaData.allSuppliers.length} ${content.subheading2a}, ${
                                cruisesMetaData.allShips.length
                            } ${content.subheading2b}, ${cruisesMetaData.allUnPorts.length} ${
                                content.subheading2c
                            }, ${cruisesMetaData.allCountries.length} ${content.subheading2d}`}
                        </Text>
                    ) : (
                        <Spacing size='triple' />
                    )}
                </div>
                <div className={styles.form}>
                    <SearchBar
                        useAutoSuggest={useAutoSuggest}
                        onSubmit={onSubmit}
                        prePopulateDestinationCategory={prePopulateDestinationCategory}
                        destinationData={destinationData} // The full sorted Auto-suggest options list
                    />
                </div>
            </div>
        </div>
    )
}

export default SearchLayout
