import React, { useState } from 'react'

import Card from 'components/blocks/Card/Card'
import Checkbox from 'components/basics/Input/Checkbox/Checkbox'
import ColoredLine from 'components/basics/ColoredLine/ColoredLine'
import ErrorList from 'components/sections/app/ErrorList/ErrorList'
import Heading from 'components/basics/Heading/Heading'
import InlineAccordion from 'components/blocks/Accordions/InlineAccordion/InlineAccordion'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import Text from 'components/basics/Text/Text'
import Spacing from 'components/basics/Spacing/Spacing'
import { OptionalExtra } from 'api-data-models/order/OrderContentModel'
import { OptionalExtrasModel } from 'api-data-models/cruise-detail/OptionalExtrasModel'

import styles from './OptionalExtras.module.scss'
import allContent from 'content/content'

const content = allContent.order.orderPage.optionalExtras

export interface OptionalExtrasProps {
    data?: OptionalExtrasModel
    optionalExtrasSelected: OptionalExtra[]
    handleSubmitOptionalExtras: (values: OptionalExtra[]) => void
    optionalExtrasSelectedError: null | any
    optionalExtrasLoading: boolean
    optionalExtrasSubmitting: boolean
}

const OptionalExtras: React.FC<OptionalExtrasProps> = ({
    optionalExtrasLoading,
    optionalExtrasSubmitting,
    data,
    optionalExtrasSelected,
    handleSubmitOptionalExtras,
    optionalExtrasSelectedError,
}) => {
    const [submittingCode, setSubmittingCode] = useState<string | null>(null)
    const activeExtrasKeys =
        data?.optionalExtras &&
        Object.keys(data?.optionalExtras).filter(
            (group: any) => (data?.optionalExtras as any)[group]?.length
        )

    const handleCheckboxChange =
        (extra: OptionalExtra) =>
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            const { checked } = event.target
            setSubmittingCode(extra.code)
            if (checked) handleSubmitOptionalExtras([...optionalExtrasSelected, extra])
            else
                handleSubmitOptionalExtras([
                    ...optionalExtrasSelected.filter(
                        (selectedExtra) => selectedExtra.code !== extra.code
                    ),
                ])
        }

    return (
        <Card header={content.heading}>
            <div className={styles['extra__container']}>
                {optionalExtrasLoading && <LargeSpinner text={content.fetchingExtras} />}
                {activeExtrasKeys?.length ? (
                    activeExtrasKeys.map((group: string) => (
                        <div key={group}>
                            <InlineAccordion
                                title={
                                    <Heading heading='2' size='3' colorOverride='tertiary-blue'>
                                        {group}
                                    </Heading>
                                }
                                baseId={`extra-group-${group}`}
                                startOpen={true}
                            >
                                <div className={styles['extra__checkbox-group']}>
                                    {(data?.optionalExtras as any)[group]?.map(
                                        (extra: OptionalExtra) => {
                                            const isInsurance = extra.subType === 'INSURANCE'
                                            const isChecked = optionalExtrasSelected
                                                ?.map(({ code }) => code)
                                                .includes(extra.code)
                                            const isDisabled =
                                                optionalExtrasSubmitting ||
                                                (isInsurance &&
                                                    !isChecked &&
                                                    optionalExtrasSelected?.some(
                                                        ({ subType }) => subType === 'INSURANCE'
                                                    ))
                                            return (
                                                <div
                                                    key={`option-${extra.code}`}
                                                    className={styles['extra__checkbox-item']}
                                                >
                                                    <Checkbox
                                                        key={`option-${extra.code}`}
                                                        text={extra.renderName}
                                                        name={extra.name}
                                                        checked={isChecked}
                                                        onChange={handleCheckboxChange(extra)}
                                                        disabled={isDisabled}
                                                        showSpinner={
                                                            optionalExtrasSubmitting &&
                                                            submittingCode === extra.code
                                                        }
                                                    />
                                                    <Text
                                                        size='S'
                                                        weight='bold'
                                                        data-name={extra.name}
                                                    >
                                                        {`$${extra.formattedPrice}`}
                                                    </Text>
                                                </div>
                                            )
                                        }
                                    )}
                                </div>
                            </InlineAccordion>
                            <ColoredLine />
                        </div>
                    ))
                ) : optionalExtrasLoading ? null : (
                    <Spacing>
                        <Text>{content.noOptionalExtras}</Text>
                    </Spacing>
                )}
                {optionalExtrasSelectedError && (
                    <ErrorList
                        errorsList={optionalExtrasSelectedError}
                        source='SetOptionalExtras'
                    />
                )}
            </div>
        </Card>
    )
}

export default OptionalExtras
