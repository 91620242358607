import React, { useEffect, useRef, useState } from 'react'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import { useRest, UseRestOptions } from 'components/hooks/useRest'
import { useLocation, useNavigate } from 'react-router-dom'
import { datadogLogs } from '@datadog/browser-logs'

import Breadcrumb from 'components/basics/Breadcrumb/Breadcrumb'
import CabinGrades from 'components/sections/cruise/SailingTabs/CabinGrades/CabinGrades'
import ErrorList from 'components/sections/app/ErrorList/ErrorList'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import Itinerary from 'components/sections/cruise/SailingTabs/Itinerary/Itinerary'
import Heading from 'components/basics/Heading/Heading'
import ModifySearchBar from 'components/sections/cruise/ModifySearchBar/ModifySearchBar'
import SailingInfo from 'components/sections/cruise/SailingInfo/SailingInfo'
import SpinnerCruiseLogo from 'components/blocks/SpinnerCruiseLogo/SpinnerCruiseLogo'
import {
    SailingInfoModel,
    SailingInfoModelBuild,
    CruiseDetailFromApi,
} from 'api-data-models/cruise-detail/SailingInfoModel'
import Spacing from 'components/basics/Spacing/Spacing'
import Tabs from 'components/basics/Tabs/Tabs'

import { ROUTES } from 'components/sections/app/AppRoutes'
import {
    CRUISE_SEARCH_DATA_KEY,
    getCruiseSearchQueryInput,
} from 'components/pages/cruise/SearchPage'

import { buildApiVariables } from '../buildApiVariables.utils'
import { checkSailingParams } from './SailingLayout.utils'
import { createQueryParamString } from 'utils/create-query-param-string'
import { getDataFromLocalStorage } from 'utils/use-local-storage'
import { usePassengersInfo } from 'components/hooks/usePassengersInfo'
import { SupplierCodes, VITALLY_EVENTS } from 'utils/constants'
import CustomerSuccess from 'services/customerSuccess/customerSuccess.service'
import { extractGlobalContextUserData } from 'utils/user-data-helpers/extract-user-data-fields'
import AlternativeSailingDates from 'components/sections/cruise/AlternativeSailingDates/AlternativeSailingDates'
import { CruisesMetaData } from 'api-data-models/CruisesContentModel'
import * as self from './SailingLayout'

import styles from './SailingLayout.module.css'
import allContent from 'content/content'

const sailingPageContent = allContent.cruise.sailingPage
const breadcrumbContent = allContent.app.breadcrumbs

type TabsItem = {
    label: string
    component: JSX.Element
}

export const getResultsUrls = (): any => {
    const previousSearchParams = getDataFromLocalStorage({ key: CRUISE_SEARCH_DATA_KEY })
    let resultsPageUrl = ROUTES.CRUISE_RESULTS
    let initialModifySearchParams

    if (previousSearchParams) {
        const queryInput = getCruiseSearchQueryInput(previousSearchParams)
        const cruiseSearchQueryParamsString = createQueryParamString(queryInput)
        resultsPageUrl = `${ROUTES.CRUISE_RESULTS}/?${cruiseSearchQueryParamsString}`
        initialModifySearchParams = getCruiseSearchQueryInput(previousSearchParams)
    }

    return { resultsPageUrl, initialModifySearchParams }
}

type SailingLayoutProps = {
    /** metadata used for auto suggest options for destination */
    cruisesMetaData: CruisesMetaData // this is FULL open search meta data with hits for auto suggest on modify search.
}

/** SailingLayout: Layout for the Sailing page */
const SailingLayout: React.FC<SailingLayoutProps> = ({ cruisesMetaData }: SailingLayoutProps) => {
    const { companyTier } = extractGlobalContextUserData(
        datadogLogs.getGlobalContext() as GlobalContextUserData
    )

    const [sailingInfoData, setSailingInfoData] = useState<SailingInfoModel>()
    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const { passengerConfigurationDataAsQueryVar } = usePassengersInfo()
    const queryVariables = buildApiVariables({
        passengerConfigurationDataAsQueryVar: passengerConfigurationDataAsQueryVar,
        queryParams: queryParams,
    })
    const storedCruiseId = useRef(queryVariables?.cruiseId)
    const hasCorrectParams = checkSailingParams(queryParams)
    const { resultsPageUrl, initialModifySearchParams } = self.getResultsUrls()

    const fetchOptions: UseRestOptions = {
        url:
            process.env.REACT_APP_CRUISE_DETAIL_SERVICE_URL +
            `/cruise/${queryVariables.cruiseId}/details?&supplier_code=${queryVariables.supplierCode}`,
        source: 'SailingPage - CruiseDetail',
        method: 'GET',
    }
    const { result, loading, error, refetch } = useRest(fetchOptions)

    useEffect(() => {
        if (result) {
            setSailingInfoData(SailingInfoModelBuild(result as CruiseDetailFromApi))
        }
        CustomerSuccess.track({
            properties: {
                cruiseId: queryVariables?.cruiseId,
                supplierCode: queryVariables?.supplierCode,
            },
            eventName: VITALLY_EVENTS.LIVE_PRICING_SAILING,
        })
    }, [result, queryVariables?.cruiseId, queryVariables?.supplierCode])

    // refetch when an alternative sailing was selected:
    useEffect(() => {
        if (queryVariables?.cruiseId && queryVariables.cruiseId !== storedCruiseId.current) {
            storedCruiseId.current = queryVariables.cruiseId
            refetch()
        }
    }, [queryVariables.cruiseId, refetch])

    const processingResultData = !!result?.data && !sailingInfoData && !error && hasCorrectParams

    const breadCrumbItems = [
        { text: breadcrumbContent.search, url: ROUTES.CRUISE_SEARCH },
        { text: breadcrumbContent.results, url: resultsPageUrl },
        { text: breadcrumbContent.sailing },
    ]

    let tabs: TabsItem[] = []

    if (sailingInfoData) {
        tabs = [
            {
                label: sailingPageContent.tabs.gradesTabTitle,
                component: (
                    <CabinGrades
                        key='cabin-grades'
                        sailingInfoData={sailingInfoData}
                        resultsPageUrl={resultsPageUrl}
                    />
                ),
            },
            {
                label: sailingPageContent.tabs.itineraryTabTitle,
                component: (
                    <Itinerary
                        key='itinerary'
                        cruiseId={queryVariables?.cruiseId}
                        supplierCode={queryVariables?.supplierCode}
                    />
                ),
            },
            {
                label: sailingPageContent.tabs.altSailingDatesTabTitle,
                component: (
                    <div key='alt-sail-dates' className={styles['alt-sail-dates-container']}>
                        <AlternativeSailingDates
                            cruiseAvailableToBook={true}
                            isStarterTier={false} // Hard coded because you can't get to sailing page on Starter Tier
                            originallyChosenDate={sailingInfoData.embarkDate}
                            cruiseId={queryVariables?.cruiseId}
                            supplierCode={queryVariables?.supplierCode}
                            roundedTopCorners
                        />
                    </div>
                ),
            },
        ]
    }

    function handleModifySearch(paramsString: string): void {
        navigate(`${ROUTES.CRUISE_RESULTS}/?${paramsString}`)
    }

    const renderError = (): React.ReactNode | null => {
        if (!hasCorrectParams)
            return (
                <InfoBanner
                    id='error-banner-params'
                    text={sailingPageContent.errors.incorrectParams}
                    bannerType='error'
                />
            )
        if (error) {
            return <ErrorList errorsList={error} source='sailing-page' />
        }
        return null
    }

    return (
        <div className='general-container'>
            <div className={styles.sections}>
                <Breadcrumb urlList={breadCrumbItems} />
                <ModifySearchBar
                    companyTier={companyTier as CompanyTierTypes} // can push type since page is only accessible if you have a valid companyTier
                    queryParams={initialModifySearchParams}
                    handleModifySearch={handleModifySearch}
                    cruisesMetaData={cruisesMetaData}
                />
                {(loading || processingResultData) && (
                    <SpinnerCruiseLogo
                        text={sailingPageContent.spinnerCruiseLogo}
                        supplierCode={queryVariables?.supplierCode as SupplierCodes}
                    />
                )}
                {sailingInfoData && <SailingInfo sailingInfoData={sailingInfoData} />}
                {!loading && (error || !hasCorrectParams) && renderError()}
                {sailingInfoData && !processingResultData && (
                    <div>
                        <Heading heading='1'>{sailingInfoData.name}</Heading>
                        <Spacing size='double' />
                        <div className={styles.sections}>
                            <StyledEngineProvider injectFirst>
                                <Tabs tabs={tabs} ariaLabel={'grades itinerary tabs'} />
                            </StyledEngineProvider>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default SailingLayout
