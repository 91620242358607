import React from 'react'
import { createRoot } from 'react-dom/client'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import * as Sentry from '@sentry/react'
import { hotjar } from 'react-hotjar'
import createApolloClient from 'utils/apollo-client/create-apollo-client'
import {
    BrowserRouter,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from 'react-router-dom'

import packageJson from '../package.json'
import {
    NON_PRODUCTION_ENVIRONMENTS,
    ENVIRONMENT_NAMES,
    EMAILS_NOT_TO_SESSION_RECORD,
} from './utils/constants'
import CustomerSuccess from './services/customerSuccess/customerSuccess.service'
import App from './App'
import './index.css'
import domainAuthInfo from './data/domainAuthInfo'
import { Auth } from '@aws-amplify/auth'

const APP_NAME = packageJson.name
const APP_VERSION = packageJson.version

// Only initiate these in development, stage and production - not local dev where they cause lots of noise.
if (process.env.NODE_ENV === ENVIRONMENT_NAMES.PRODUCTION) {
    try {
        if (process.env.REACT_APP_VITALLY_TOKEN) {
            CustomerSuccess.init()
        }

        // eslint-disable-next-line no-console
        console.log('Initializing Datadog, Sentry, Vitally and HotJar...')
        if (process.env.REACT_APP_DD_LOGS_CLIENT_TOKEN) {
            datadogLogs.init({
                clientToken: process.env.REACT_APP_DD_LOGS_CLIENT_TOKEN,
                site: 'datadoghq.eu',
                forwardErrorsToLogs: true,
                sessionSampleRate: 100,
                service: APP_NAME,
                version: APP_VERSION,
                env: process.env.REACT_APP_ENVIRONMENT_NAME, // development, stage and production are differentiated with this value (both use same token)
            })
        }

        if (
            process.env.REACT_APP_DD_RUM_APPLICATION_ID &&
            process.env.REACT_APP_DD_RUM_CLIENT_TOKEN
        ) {
            datadogRum.init({
                applicationId: process.env.REACT_APP_DD_RUM_APPLICATION_ID,
                clientToken: process.env.REACT_APP_DD_RUM_CLIENT_TOKEN,
                site: 'datadoghq.eu',
                service: APP_NAME,
                env: process.env.REACT_APP_ENVIRONMENT_NAME, // development, stage and production are differentiated with this value (both use same token)
                version: APP_VERSION,
                sessionSampleRate: 100,
                sessionReplaySampleRate: 100,
                trackUserInteractions: true,
                beforeSend: (event) => {
                    /** Do not record sessions replays on automated tests - return false if email is our test user */
                    return !EMAILS_NOT_TO_SESSION_RECORD.includes(event.usr?.email ?? '')
                },
                allowedTracingUrls: [
                    {
                        match: process.env.REACT_APP_CONNECT_MANAGER_SERVICE_URL ?? '',
                        propagatorTypes: ['datadog', 'tracecontext'],
                    },
                    {
                        match: process.env.REACT_APP_ORDER_SERVICE_URL ?? '',
                        propagatorTypes: ['datadog', 'tracecontext'],
                    },
                    {
                        match: process.env.REACT_APP_CRUISE_SEARCH_SERVICE_URL ?? '',
                        propagatorTypes: ['datadog', 'tracecontext'],
                    },
                    {
                        match: process.env.REACT_APP_L3_API_URL ?? '',
                        propagatorTypes: ['datadog', 'tracecontext'],
                    },
                    {
                        match: process.env.REACT_APP_BACKEND_SERVICE_URL ?? '',
                        propagatorTypes: ['datadog', 'tracecontext'],
                    },
                    {
                        match: process.env.REACT_APP_SEND_EMAIL_QUOTE_SERVICE ?? '',
                        propagatorTypes: ['datadog', 'tracecontext'],
                    },
                ],
            })

            /** Session recording only for production */
            if (process.env.REACT_APP_ENVIRONMENT_NAME === ENVIRONMENT_NAMES.PRODUCTION) {
                datadogRum.startSessionReplayRecording()
            }
        }

        if (process.env.REACT_APP_SENTRY_DSN) {
            Sentry.init({
                release: `${APP_NAME}-${APP_VERSION}`,
                environment: process.env.REACT_APP_ENVIRONMENT_NAME,
                dsn: process.env.REACT_APP_SENTRY_DSN,
                integrations: [
                    Sentry.browserTracingIntegration(),
                    Sentry.reactRouterV6BrowserTracingIntegration({
                        useEffect: React.useEffect,
                        useLocation,
                        useNavigationType,
                        createRoutesFromChildren,
                        matchRoutes,
                    }),
                    Sentry.replayIntegration(),
                ],
                // We recommend adjusting this value in production, or using tracesSampler
                // for finer control
                tracesSampleRate: 1.0,
                // Session Replay
                replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            })
        }

        if (process.env.REACT_APP_HOTJAR_USER_ID) {
            hotjar.initialize(Number(process.env.REACT_APP_HOTJAR_USER_ID), 6)
        }
    } catch (error) {
        //  eslint-disable-next-line no-console
        console.error('Error initializing plug-ins', error)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        datadogLogs.logger.error(`source: App, Error initializing Plug-ins`, {}, error) // do not know why it can't take undefined for error.
    }
}

// REACT_APP_ENVIRONMENT_NAME tells the app the environment its in. (Unlike NODE_ENV which is the app build type)
if (NON_PRODUCTION_ENVIRONMENTS.includes(process.env.REACT_APP_ENVIRONMENT_NAME ?? '')) {
    // eslint-disable-next-line no-console
    console.log('None prod environment detected. PROCESS.ENV:', process.env)
}

const API_CLIENT = createApolloClient(process.env.REACT_APP_BACKEND_SERVICE_URL)

const domain = window.location.origin
/** Look-up userPool data from domainAuthInfo. AMPLIFY or COGNITO UX login, userPoolId clientId etc. */
if (domain in domainAuthInfo) {
    const clientId = domainAuthInfo[domain].clientId
    const userPoolId = domainAuthInfo[domain].userPoolId

    /** Configure aws-amplify ready for login pages etc. */
    Auth.configure({
        region: 'eu-west-1',
        userPoolId: userPoolId,
        userPoolWebClientId: clientId,
        mandatorySignIn: true,
    })
}

const container = document.getElementById('app')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

root.render(
    <React.Fragment>
        <BrowserRouter>
            <App apiClient={API_CLIENT} />
        </BrowserRouter>
    </React.Fragment>
)
