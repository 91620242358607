import React from 'react'

import Text from 'components/basics/Text/Text'
import Image from 'components/basics/Image/Image'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import { SUPPLIER_LOGOS, SUPPLIER_NAMES, SupplierCodes } from 'utils/constants'
import styles from './SpinnerCruiseLogo.module.css'

type SpinnerWithLogoProps = {
    supplierCode: SupplierCodes
    text?: string
}

const SpinnerCruiseLogo: React.FC<SpinnerWithLogoProps> = ({
    supplierCode,
    text = 'Requesting data from',
}) => {
    return (
        <LargeSpinner>
            <Text>{`${text} ${SUPPLIER_NAMES[supplierCode]}`}</Text>
            <Image
                className={styles.logo}
                src={SUPPLIER_LOGOS[supplierCode]}
                alt={`${SUPPLIER_NAMES[supplierCode]} logo`}
            />
        </LargeSpinner>
    )
}

export default SpinnerCruiseLogo
