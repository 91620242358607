import React from 'react'
import classnames from 'classnames'

import styles from './ToggleSwitch.module.scss'

type ToggleSwitchProps = {
    /** Tell the toggle (checkbox) it's status; controlled by consumer via passing onChange function */
    isChecked: boolean
    /** Called when clicked */
    onChange(): void
    /** Text label for screen reader, visually not shown */
    label: string
    /** toggle to make unavailable */
    disabled?: boolean
    /** hide label */
    hideLabel?: boolean
    /** toggle to switch colours for dark backgrounds*/
    onDarkBackground?: boolean
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
    isChecked,
    onChange,
    label,
    disabled = false,
    hideLabel = false,
    onDarkBackground = false,
    ...rest
}: ToggleSwitchProps) => {
    const labelClassNames = classnames(styles['label'], {
        'visually-hidden': hideLabel,
        [styles['label--onDarkBackground']]: onDarkBackground,
    })

    return (
        <span>
            <label>
                <span className={labelClassNames}>{label}</span>
                <div className={styles['slider-container']}>
                    <input type='checkbox' checked={isChecked} onChange={onChange} {...rest} />
                    <span className={styles.slider} />
                </div>
            </label>
        </span>
    )
}

export default ToggleSwitch
