import React, { useEffect, useState } from 'react'

import AllOrdersLayout from 'components/layouts/order/AllOrdersLayout/AllOrdersLayout'

import { useRest, UseRestOptions } from 'components/hooks/useRest'
import { usePagination } from 'components/hooks/usePagination'
import { useTabNumber } from 'components/hooks/useTabNumber'
import { HTTP_METHODS, VITALLY_EVENTS } from 'utils/constants'
import CustomerSuccess from 'services/customerSuccess/customerSuccess.service'

import { AllOrdersContentModel, Order, ApiOrder } from 'api-data-models/order/AllOrdersContentModel'
import { orderServiceUrls } from 'utils/order-service-urls'
import Spacing from 'components/basics/Spacing/Spacing'
import Heading from 'components/basics/Heading/Heading'
import Tabs from 'components/basics/Tabs/Tabs'

import styles from './OrdersPage.module.scss'
import allContent from 'content/content'

const content = allContent.order.allOrdersPage

const OrdersPage: React.FC = (): JSX.Element => {
    const { tab, setTab } = useTabNumber(0)
    return (
        <div className='general-container'>
            <div className={styles.heading}>
                <Spacing>
                    <Heading heading='1'>{content.title}</Heading>
                </Spacing>
            </div>
            <Tabs
                initialTab={tab}
                onTabChange={setTab}
                tabs={[
                    { label: content.quotesTab, component: <OrdersWrapper isBooked={false} /> },
                    { label: content.ordersTab, component: <OrdersWrapper isBooked={true} /> },
                ]}
            />
        </div>
    )
}

export default OrdersPage

const OrdersWrapper: React.FC<{ isBooked: boolean }> = ({ isBooked }): JSX.Element => {
    const { pageSize, pageNumber, setPageSize, setPageNumber } = usePagination(10, 1)
    const [orders, setOrders] = useState<Order[] | undefined>(undefined)
    const [pagesTotal, setPagesTotal] = useState(1)

    const fetchOptions: UseRestOptions = {
        url:
            orderServiceUrls.getOrdersPaginated() +
            `?page_number=${pageNumber}&page_size=${pageSize}&is_booked=${isBooked}`,
        source: 'OrdersPage - GET_ORDERS',
        method: HTTP_METHODS.GET,
    }

    const { result, loading, error, refetch } = useRest(fetchOptions)

    useEffect(() => {
        CustomerSuccess.track({
            eventName: isBooked ? VITALLY_EVENTS.GET_ALL_ORDERS : VITALLY_EVENTS.GET_ALL_QUOTES,
        })
    }, [isBooked])

    useEffect(() => {
        if (result && !loading) {
            setOrders(AllOrdersContentModel(result.orders as ApiOrder[]))
            setPagesTotal(result.total_pages)
        }
    }, [loading, result])

    const onPageNumberChange = (pageNumber: number): void => {
        const newPageNumber = pageNumber
        setPageNumber(newPageNumber)
        refetch()
    }

    const onPageSizeChange = (pageSize: number): void => {
        setPageSize(pageSize)
        setPageNumber(1)
        refetch()
    }

    // If result object exists, error is false, and cruises is undefined --> then the model is processing
    const processingResultData = !!result && !orders && !error

    return (
        <AllOrdersLayout
            loading={loading || processingResultData}
            error={error}
            orders={orders ?? []}
            isBooked={isBooked}
            pageSize={pageSize}
            pageNumber={pageNumber}
            pagesTotal={pagesTotal}
            setPageSize={onPageSizeChange}
            setPageNumber={onPageNumberChange}
        />
    )
}
