import React, { ReactNode, useState } from 'react'
import classnames from 'classnames'

import Heading, { HeadingSizes } from 'components/basics/Heading/Heading'
import Icon from 'components/basics/Icon/Icon'
import Text from 'components/basics/Text/Text'
import styles from './CardAccordion.module.scss'

type CardAccordionProps = {
    /** Title/header of the accordion */
    title: string | ReactNode
    /** Font size to display the accordion title/header */
    titleSize?: HeadingSizes
    /** Title (heading tag) level, between <h1>-<h6> */
    titleLevel?: '1' | '2' | '3' | '4' | '5' | '6'
    /** Set to true if Accordion should start in Active state */
    startOpen?: boolean
    /** switch between dark and light header style */
    darkHeading?: boolean
    /** children always of type ReactNode */
    children?: React.ReactNode
    /** String to be used to form new unique IDs from whilst keeping a sense of common identity */
    baseId: string
    /** Text for toggle near Chevron icon */
    toggleText?: string
    className?: string
}

/** CardAccordion: Is the main self-contained in card with header accordion that can be clicked by a user to expand the contents passed as children */
const CardAccordion: React.FC<CardAccordionProps> = ({
    title,
    titleSize = '3',
    titleLevel = '2',
    startOpen = false,
    darkHeading = true,
    children,
    baseId,
    toggleText = '',
    className,
}: CardAccordionProps) => {
    const [isOpen, setIsOpen] = useState(startOpen)
    const toggleAccordion = (): void => {
        setIsOpen(!isOpen)
    }

    const containerClasses = classnames(styles.container, {
        [`${className}`]: !!className,
    })
    const contentClasses = classnames(
        styles.content,
        { [styles['content-hide']]: !isOpen },
        { [styles['content--border-bottom']]: isOpen },
        { [styles['content--border-top']]: !darkHeading && isOpen }
    )

    const headerClasses = classnames(styles.header, {
        [styles['header--closed']]: !isOpen,
        [styles['header--alt']]: !darkHeading,
    })
    const iconClasses = classnames(styles.icon, {
        [styles['icon--alt']]: !darkHeading,
    })

    const contentId = `${baseId}-content`
    const accordionId = `${baseId}-accordion`

    return (
        <div className={containerClasses}>
            <div className={headerClasses}>
                <button
                    type='button'
                    id={accordionId}
                    aria-expanded={isOpen}
                    aria-controls={contentId}
                    className={styles.button}
                    onClick={toggleAccordion}
                >
                    {typeof title === 'string' ? (
                        <Heading
                            heading={titleLevel}
                            onDarkBackground={!!darkHeading}
                            size={titleSize}
                        >
                            {title}
                        </Heading>
                    ) : (
                        title
                    )}
                    <div className={iconClasses}>
                        {toggleText && (
                            <Text color={darkHeading ? 'white' : 'primary-blue'} size='S'>
                                {toggleText}
                            </Text>
                        )}
                        <Icon
                            iconName={isOpen ? 'ChevronUp' : 'ChevronDown'}
                            iconSize='S'
                            iconColor={darkHeading ? 'white' : 'primary-midnight'}
                        />
                    </div>
                </button>
            </div>
            <section
                id={contentId}
                aria-labelledby={accordionId}
                aria-hidden={!isOpen}
                className={contentClasses}
            >
                {/* this div is needed so padded content is hidden when closed  */}
                <div>{children}</div>
            </section>
        </div>
    )
}

export default CardAccordion
