import React, { useEffect, useState } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom'

import Breadcrumb from 'components/basics/Breadcrumb/Breadcrumb'
import Heading from 'components/basics/Heading/Heading'
import CabinInfoSection from 'components/sections/cruise/CabinInfoSection/CabinInfoSection'
import CabinPickerFormSection from 'components/sections/cruise/CabinPickerFormSection/CabinPickerFormSection'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import SpinnerCruiseLogo from '../../../blocks/SpinnerCruiseLogo/SpinnerCruiseLogo'
import Spacing from 'components/basics/Spacing/Spacing'

import ErrorList from 'components/sections/app/ErrorList/ErrorList'
import { CabinContent } from 'api-data-models/CabinContentModel'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { GET_CABIN_BY_RATE_CODE_AND_CABIN_GRADE_CODE } from 'graphql-queries/cruise/cruise-queries'
import { REFUND_POLICIES, VITALLY_EVENTS, HTTP_METHODS, PRODUCT_TYPES } from 'utils/constants'
import {
    CRUISE_SEARCH_DATA_KEY,
    getCruiseSearchQueryInput,
} from 'components/pages/cruise/SearchPage'
import { checkAndPerformUserSessionRefreshIfNeeded } from 'utils/cognito-helpers/check-and-perform-user-session-refresh-if-needed'
import { checkCabinParams } from './CabinLayout.utils'
import { buildApiVariables } from '../buildApiVariables.utils'
import { createQueryParamString } from 'utils/create-query-param-string'
import { getDataFromLocalStorage } from 'utils/use-local-storage'
import CustomerSuccess from 'services/customerSuccess/customerSuccess.service'
import { usePassengersInfo } from 'components/hooks/usePassengersInfo'

import { useApolloQuery } from 'components/hooks/useApolloQuery'

import * as self from './CabinLayout'
import styles from './CabinLayout.module.css'
import allContent from 'content/content'
import callApi from 'services/callApi/callApi.service'
import { orderServiceUrls } from 'utils/order-service-urls'
import { getRestErrorMessage } from 'utils/api-errors-helpers/get-rest-error-message'
import { useRest, UseRestOptions } from '../../../hooks/useRest'
import {
    CabinFromApi,
    Cabins,
    CabinsModelTransformFunction,
} from 'api-data-models/cruise-detail/CabinsModel'
import {
    DiningOptionsModelTransformFunction,
    DiningOption,
    DiningData,
} from 'api-data-models/cruise-detail/DiningOptionsModel'

const content = allContent.cruise.cabinPage
const breadcrumbContent = allContent.app.breadcrumbs

export const getResultsAndSailingUrls = (cruiseId?: string, supplierCode?: string): any => {
    const previousSearchParams = getDataFromLocalStorage({ key: CRUISE_SEARCH_DATA_KEY })

    let resultsPageUrl = ROUTES.CRUISE_RESULTS
    if (previousSearchParams) {
        const queryInput = getCruiseSearchQueryInput(previousSearchParams)
        const cruiseSearchQueryParamsString = createQueryParamString(queryInput)
        resultsPageUrl = `${ROUTES.CRUISE_RESULTS}/?${cruiseSearchQueryParamsString}`
    }

    let sailingPageUrl = ROUTES.CRUISE_SAILING
    if (cruiseId && supplierCode) {
        sailingPageUrl = `${ROUTES.CRUISE_SAILING}/?cruiseId=${cruiseId}&supplierCode=${supplierCode}`
    }

    return { resultsPageUrl, sailingPageUrl }
}

export type CreateOrderVariables = {
    numberOfPassengers: number
    cabinBedConfigurationCode: string
    cabinGradeCode: string
    cabinNumber: string
    rateCode: string
    cruiseId: string
    supplierCode: string
    cabinDiningOption: string
    specialRequest?: Record<string, any>
    price: number
    passengers?: { age: number; travellerNumber: number }[]
}

export const onCabinSubmit = ({
    orderCreateServiceUrl,
    createOrderVariables,
    setSubmitting,
    navigate,
    clearPassengers,
    setCreateOrderApiError, // Add this argument
}: {
    orderCreateServiceUrl: string
    createOrderVariables: CreateOrderVariables
    setSubmitting: React.Dispatch<React.SetStateAction<boolean>>
    navigate: NavigateFunction
    clearPassengers: () => void
    setCreateOrderApiError: (value: React.SetStateAction<any>) => void // Add this argument
}): void => {
    const handleUserSession = checkAndPerformUserSessionRefreshIfNeeded(navigate)
    // Map passengers to snack case key names
    const passengers: { age: number; traveller_number: number }[] =
        createOrderVariables.passengers?.map(
            (passenger: {
                age: number
                travellerNumber: number
            }): { age: number; traveller_number: number } => ({
                traveller_number: passenger.travellerNumber,
                age: passenger.age,
            })
        ) ?? []
    handleUserSession.then(() => {
        const requestBody = {
            items: [
                {
                    product_type: 'Cruise',
                    attributes: [
                        {
                            Cruise: {
                                id: createOrderVariables.cruiseId,
                                rate_code: createOrderVariables.rateCode,
                                cabin_number: createOrderVariables.cabinNumber,
                                price: createOrderVariables.price, // number
                                cruise_line: createOrderVariables.supplierCode,
                                cabin_grade_code: createOrderVariables.cabinGradeCode,
                                number_of_passengers: createOrderVariables.numberOfPassengers, // number
                                cabin_bed_configuration_code:
                                    createOrderVariables.cabinBedConfigurationCode,
                                cabin_dining_option: createOrderVariables.cabinDiningOption,
                                // specialRequest: createOrderVariables.specialRequest, // not used on form so don't submit
                            },
                        },
                    ],
                },
            ],
            passengers: { passengers: passengers },
        }
        callApi({
            url: orderCreateServiceUrl,
            method: HTTP_METHODS.POST,
            variables: requestBody,
            source: 'OrderCreateCruise',
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                setCreateOrderApiError(null)
                if (data) {
                    const queryParamsString = `orderId=${data.id}`
                    navigate(`${ROUTES.ORDER}/?${queryParamsString}&new-order=yes`)
                }
            })
            .catch((error) => {
                const parsedError = getRestErrorMessage({
                    errors: error.cause,
                    source: 'OrderCreateCruise',
                    variables: requestBody,
                })
                setCreateOrderApiError(parsedError)
            })
            .finally(() => {
                setSubmitting(false)
                clearPassengers() // TODO: SHOULD WE DO THIS? yes because it contains past passenger numbers, that should not accidentally be used on the next booking.
                /** Log the action of adding to basket here so both failed and successful get logged. */
                CustomerSuccess.track({
                    eventName: VITALLY_EVENTS.ADD_TO_BASKET,
                    properties: {
                        product: PRODUCT_TYPES.CRUISE,
                        cruiseId: createOrderVariables.cruiseId,
                        supplierCode: createOrderVariables.supplierCode,
                        rateCode: createOrderVariables.rateCode,
                        cabinGradeCode: createOrderVariables.cabinGradeCode,
                        numberOfPassengers: createOrderVariables.numberOfPassengers,
                        cabinBedConfigurationCode: createOrderVariables.cabinBedConfigurationCode,
                        cabinNumber: createOrderVariables.cabinNumber,
                        cabinDiningOption: createOrderVariables.cabinDiningOption,
                        price: createOrderVariables.price,
                    },
                })
            })
    })
}

/** CabinLayout: Layout for the Cabin select page */
const CabinLayout: React.FC<{ apiClient: ApolloClient<NormalizedCacheObject> }> = ({
    apiClient,
}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)

    const [submitting, setSubmitting] = useState<boolean>(false)
    const [cabinGraphQLData, setCabinGraphQLData] = useState<CabinContent | undefined>(undefined)
    const [cabinRestData, setCabinRestData] = useState<Cabins | undefined>(undefined)
    const [diningData, setDiningData] = useState<DiningOption[] | undefined>(undefined)

    const [createOrderApiError, setCreateOrderApiError] = useState<CustomApiError[] | null>(null)
    const [selectedDeck, setSelectedDeck] = useState<string>('') // to track the value of selected deck
    const orderCreateServiceUrl = orderServiceUrls.createOrder()

    const { numberOfPassengers, passengerConfigurationDataAsQueryVar, clearPassengers } =
        usePassengersInfo()
    const queryVariables = buildApiVariables({ passengerConfigurationDataAsQueryVar, queryParams })
    const hasCorrectParams = checkCabinParams(queryParams)

    const cruiseId = queryVariables?.cruiseId
    const supplierCode = queryVariables?.supplierCode
    const cabinGradeCode = queryVariables?.cabinGradeCode
    const rateCode = queryVariables?.rateCode

    const {
        result,
        loading,
        error: errorFetching,
    } = useApolloQuery({
        client: apiClient,
        variables: queryVariables,
        query: GET_CABIN_BY_RATE_CODE_AND_CABIN_GRADE_CODE,
        source: 'CabinLayout - GET_CABIN_BY_RATE_CODE_AND_CABIN_GRADE_CODE',
        skip: !hasCorrectParams, // boolean to skip calling api when we know it'll fail due to lack of params
    })
    const { passengerConfigurationDataRestRequestBody } = usePassengersInfo()
    const cabinsFetchOptions: UseRestOptions = {
        url:
            process.env.REACT_APP_CRUISE_DETAIL_SERVICE_URL +
            `/cruise/${cruiseId}/rates/${rateCode}/cabin-grades/${cabinGradeCode}/cabins?supplier_code=${supplierCode}`,
        source: 'CabinPage - Cabins List',
        method: 'POST',
        variables: passengerConfigurationDataRestRequestBody,
    }
    const {
        result: cabinsResult,
        loading: cabinsLoading,
        error: cabinsError,
    } = useRest(cabinsFetchOptions)

    const diningFetchOptions: UseRestOptions = {
        url:
            process.env.REACT_APP_CRUISE_DETAIL_SERVICE_URL +
            `/cruise/${cruiseId}/rates/${rateCode}/cabin-grades/${cabinGradeCode}/dining-options?supplier_code=${supplierCode}`,
        source: 'CabinPage - Cabins List',
        method: 'POST',
        variables: passengerConfigurationDataRestRequestBody,
    }
    const {
        result: diningResult,
        loading: diningLoading,
        error: diningError,
    } = useRest(diningFetchOptions)

    useEffect(() => {
        if (cabinsResult) {
            setCabinRestData(CabinsModelTransformFunction(cabinsResult as CabinFromApi[]))
        }
    }, [cabinsResult])

    useEffect(() => {
        if (diningResult) {
            setDiningData(DiningOptionsModelTransformFunction(diningResult as DiningData))
        }
    }, [diningResult])

    useEffect(() => {
        if (result?.data?.cruise) {
            const cabinData = new CabinContent(result.data.cruise)
            setCabinGraphQLData(cabinData)

            // Display deck of default selected cabin number as a default deck if defaultSelectedCabinNumber is present otherwise display first deck in list
            const defaultDeck = cabinData?.defaultSelectedCabinNumber
                ? cabinData.cabins[cabinData?.defaultSelectedCabinNumber].deck.name
                : cabinData.decks[cabinData.deckNames?.[0]]?.name
            setSelectedDeck(defaultDeck)

            const vitallyProperties = {
                cruiseId: cruiseId,
                supplierCode: supplierCode,
                rateCode: rateCode,
                cabinGradeCode: cabinGradeCode,
            }

            CustomerSuccess.track({
                eventName: VITALLY_EVENTS.LIVE_PRICING_CABIN,
                properties: vitallyProperties,
            })
        }
    }, [cabinGradeCode, cruiseId, rateCode, supplierCode, result])

    const { resultsPageUrl, sailingPageUrl } = self.getResultsAndSailingUrls(
        cruiseId,
        queryVariables?.supplierCode
    )
    const breadcrumbItems = [
        { text: breadcrumbContent.search, url: ROUTES.CRUISE_SEARCH },
        { text: breadcrumbContent.results, url: resultsPageUrl },
        { text: breadcrumbContent.sailing, url: sailingPageUrl },
        { text: breadcrumbContent.cabin },
    ]

    const processingResultData = !!result && !cabinGraphQLData && errorFetching

    const anyCabins = !!cabinGraphQLData?.defaultSelectedCabinNumber // if default is null then there are no cabins

    const refundPolicy = cabinGraphQLData?.rateCodes[0]?.refundPolicy
    const isNotRefundable = refundPolicy === REFUND_POLICIES.NON_REFUNDABLE_FARE

    const renderError = (): React.ReactNode | null => {
        if (!hasCorrectParams) {
            return (
                <InfoBanner
                    id='error-missing-params'
                    text={content.incorrectParams}
                    bannerType='error'
                    logType='warn'
                    source='cabin-page'
                />
            )
        }
        if (cabinsError) {
            return <ErrorList errorsList={cabinsError} source='cabin-page' />
        }
        if (diningError) {
            return <ErrorList errorsList={diningError} source='cabin-page' />
        } else if (errorFetching) {
            return <ErrorList errorsList={errorFetching} source='cabin-page' />
        }
        if (cabinGraphQLData && !cabinGraphQLData?.defaultSelectedCabinNumber) {
            return (
                <InfoBanner
                    id='error-no-cabins'
                    text={content.noCabins}
                    bannerType='error'
                    logType='warn'
                    source='cabin-page_no-cabins' // this needs specific tag so we can track it for investigating
                />
            )
        }
        return null
    }

    return (
        <div className='general-container'>
            <div className={styles.container}>
                <div className={styles['cabin-content-wrapper']}>
                    <Breadcrumb urlList={breadcrumbItems} />
                    {(!!errorFetching ||
                        !hasCorrectParams ||
                        !cabinGraphQLData?.defaultSelectedCabinNumber) &&
                        renderError()}
                    {(loading || cabinsLoading || diningLoading || processingResultData) && (
                        <SpinnerCruiseLogo
                            text={content.spinnerCruiseLogo}
                            supplierCode={supplierCode}
                        />
                    )}
                    {cabinGraphQLData && anyCabins && !processingResultData && (
                        <>
                            <Spacing size='quadruple'>
                                <Heading heading='1'>{cabinGraphQLData.productName}</Heading>
                            </Spacing>
                            <div className={styles.sections}>
                                {isNotRefundable && (
                                    <InfoBanner
                                        id='non-refundable-warning'
                                        isCloseable={false}
                                        bannerType='info'
                                        text={content.nonRefundable}
                                    />
                                )}
                                {createOrderApiError && (
                                    <ErrorList
                                        errorsList={createOrderApiError}
                                        source='OrderCreateCruise'
                                    />
                                )}
                                <CabinInfoSection
                                    cruiseId={cruiseId}
                                    supplierCode={supplierCode}
                                    rateCode={rateCode}
                                    cabinGradeCode={cabinGradeCode}
                                />
                                {cabinRestData && diningData?.length && (
                                    <CabinPickerFormSection
                                        cabinsRestData={cabinRestData}
                                        diningData={diningData}
                                        numberOfPassengers={numberOfPassengers}
                                        cabinGraphQLData={cabinGraphQLData}
                                        setSelectedDeck={setSelectedDeck}
                                        defaultSelectedCabinNumber={
                                            cabinGraphQLData.defaultSelectedCabinNumber
                                        }
                                        submitting={submitting}
                                        setSubmitting={setSubmitting}
                                        selectedDeck={selectedDeck}
                                        onSubmit={(formData, setSubmitting): void => {
                                            self.onCabinSubmit({
                                                orderCreateServiceUrl,
                                                createOrderVariables: {
                                                    // Add passenger data and cruise id to form fields for api call body
                                                    ...formData,
                                                    cruiseId,
                                                    passengers:
                                                        passengerConfigurationDataAsQueryVar,
                                                },
                                                setSubmitting,
                                                navigate,
                                                clearPassengers,
                                                setCreateOrderApiError,
                                            })
                                        }}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default CabinLayout
