import content from '../content/content'

/** REGEX for input (each key press) and submit (final value) validation */
export const REGEX = {
    NAME: /^[a-zA-Z](([',.\- a-zA-Z])*[a-zA-Z])?$/, // Allows lower upper case letters, along with characters [',.\- ] except at start and end.
    CHAR_LETTERS_ONLY: /^[a-zA-Z]*$/,
    CHAR_LETTERS_AND_SYMBOLS: /^[',.\- a-zA-Z]*$/,
    COMPANY_NAME: /^[a-zA-Z\d][\w.!#£$&@%'*+=?^`, -]*[a-zA-Z\d]$/,
    DIGITS_ONLY: /^[0-9]*$/, // checks for any number of 0-9's. Don't use \d as this allows other ASCII characters that are numbers but not ones our backend would recognise!
    DIGITS_0_to_2: /^[0-9]{0,2}$/, // checks for any number of 0-9's. Don't use \d as this allows other ASCII characters that are numbers but not ones our backend would recognise!
    DIGITS_0_to_3: /^[0-9]{0,3}$/, // checks for any number of 0-9's. Don't use \d as this allows other ASCII characters that are numbers but not ones our backend would recognise!
    DIGITS_0_to_4: /^[0-9]{0,4}$/, // checks for any number of 0-9's. Don't use \d as this allows other ASCII characters that are numbers but not ones our backend would recognise!
    DAY: /^(([1-9]?|0[1-9])|(1[0-9])|(2[0-9])|(3[01]))$/, // 01 or 1 (02 or 2) up to 31
    MONTH: /^(([1-9]|0[1-9])|(1[0-2]))$/, // 01 or 1 (02 or 2) to 12, not 13 and above
    YEAR_1900_to_2099: /^(19|20)\d\d$/,
    EMAIL: /^[\w.!#$%'*+/=?^`{|}~-]+@[a-zA-Z\d-]+(?:\.[a-zA-Z\d-]+)*$/,
    // Valid characters in email: https://ladedu.com/valid-characters-for-email-addresses-the-complete-list/
    PASSPORT: /^[a-zA-Z0-9]*$/, // Not sure what patterns are valid
    CREDIT_CARD_DIGITS: /^[0-9]{0,16}$/, // 16 digits
    PASSWORD:
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[\^$*.[\]{}()?"!@#%&/,><':;|_~`=+-]).{10,30}$/,
    // cognito password policy: https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
    PASSWORD_TOOL_TIP:
        '10-30 long. Include lower/upper case, digit and special char: \\]{}()?"!@#%&/,><\':;|_~`=+-', //NOSONAR
}

/** PASSENGER CONFIG */
export const SESSION_STORAGE_PASSENGER_CONFIG_KEY = 'passengerConfigurationData'
export const MAX_PASSENGERS_PER_BOOKING = 4
export const MAX_CHILDREN_PER_BOOKING = MAX_PASSENGERS_PER_BOOKING - 1

export const DEFAULT_PASSENGER_CONFIGURATION_ARRAY = [
    { travellerNumber: 1, ageType: 'adult', ageTypeNumber: 1, age: 30, lead: true },
    { travellerNumber: 2, ageType: 'adult', ageTypeNumber: 2, age: 30 },
]
export const INITIAL_FULL_PASSENGER_CONFIGURATION = {
    passengerConfiguration: DEFAULT_PASSENGER_CONFIGURATION_ARRAY,
    adults: 2,
    children: 0,
}

/** Charebee strings for subscriptions */
export const CHARGEBEE_SUBSCRIPTION_NAMES: Record<CompanyTierTypes, string> = {
    CRUISESTARTER: 'CruiseStarter',
    CRUISEPLUS: 'CruisePlus',
    CRUISEPLUSTRIAL: 'CruisePlusTrial',
    CRUISEENTERPRISE: 'CruiseEnterprise',
    CRUISEAPI: 'CruiseAPI',
}

/** ALL PRE-SAAS users to be on CRUISE_ENTERPRISE */
export const COMPANY_TIER_NAMES: {
    CRUISE_STARTER: CompanyTierTypes
    CRUISE_PLUS: CompanyTierTypes
    CRUISE_PLUS_TRIAL: CompanyTierTypes
    CRUISE_ENTERPRISE: CompanyTierTypes
    CRUISE_API: CompanyTierTypes
} = {
    CRUISE_STARTER: 'CRUISESTARTER',
    CRUISE_PLUS: 'CRUISEPLUS',
    CRUISE_PLUS_TRIAL: 'CRUISEPLUSTRIAL',
    CRUISE_ENTERPRISE: 'CRUISEENTERPRISE',
    CRUISE_API: 'CRUISEAPI',
}

export const ALL_COMPANY_TIERS: CompanyTierTypes[] = [
    COMPANY_TIER_NAMES.CRUISE_STARTER,
    COMPANY_TIER_NAMES.CRUISE_PLUS,
    COMPANY_TIER_NAMES.CRUISE_PLUS_TRIAL,
    COMPANY_TIER_NAMES.CRUISE_ENTERPRISE,
    COMPANY_TIER_NAMES.CRUISE_API,
]

/** has all the tiers except the API tier */
export const APP_COMPANY_TIERS: CompanyTierTypes[] = [
    COMPANY_TIER_NAMES.CRUISE_STARTER,
    COMPANY_TIER_NAMES.CRUISE_PLUS,
    COMPANY_TIER_NAMES.CRUISE_PLUS_TRIAL,
    COMPANY_TIER_NAMES.CRUISE_ENTERPRISE,
]

export const ALL_PAID_COMPANY_TIERS = [
    COMPANY_TIER_NAMES.CRUISE_PLUS,
    COMPANY_TIER_NAMES.CRUISE_PLUS_TRIAL,
    COMPANY_TIER_NAMES.CRUISE_ENTERPRISE,
    COMPANY_TIER_NAMES.CRUISE_API,
]

/** has all APP tiers except CRUISE STARTER*/
export const APP_PAID_COMPANY_TIERS = [
    COMPANY_TIER_NAMES.CRUISE_PLUS,
    COMPANY_TIER_NAMES.CRUISE_PLUS_TRIAL,
    COMPANY_TIER_NAMES.CRUISE_ENTERPRISE,
]

export const ALLOWED_PROD_SIGN_UP_TIERS = [
    COMPANY_TIER_NAMES.CRUISE_STARTER,
    COMPANY_TIER_NAMES.CRUISE_PLUS_TRIAL,
    COMPANY_TIER_NAMES.CRUISE_API,
]

export const USER_ROLES: { AGENT: UserRole; ADMIN: UserRole; API_USER: UserRole } = {
    AGENT: 'AGENT',
    ADMIN: 'ADMIN',
    API_USER: 'API',
}

export const REFUND_POLICIES: {
    REFUNDABLE: 'REFUNDABLE'
    NON_REFUNDABLE_DEPOSIT: 'NON_REFUNDABLE_DEPOSIT'
    NON_REFUNDABLE_FARE: 'NON_REFUNDABLE_FARE'
    NOT_SUPPORTED: 'NOT_SUPPORTED'
} = {
    REFUNDABLE: 'REFUNDABLE',
    NON_REFUNDABLE_DEPOSIT: 'NON_REFUNDABLE_DEPOSIT',
    NON_REFUNDABLE_FARE: 'NON_REFUNDABLE_FARE',
    NOT_SUPPORTED: 'NOT_SUPPORTED',
}

export const ENVIRONMENTS = {
    LOCAL: 'local',
    DEVELOPMENT: 'development',
    STAGE: 'stage',
    PROD: 'production',
}

/** COGNITO */
export const COGNITO_ACTIONS = { new_password: 'NEW_PASSWORD_REQUIRED' }

/** Order */
export const PAYMENT_OPTIONS: {
    FULL: PaymentOptionType
    DEPOSIT: PaymentOptionType
    ONHOLD: PaymentOptionType
} = {
    FULL: 'Full',
    DEPOSIT: 'Deposit',
    ONHOLD: 'On Hold',
}

/** MISC/GENERAL */
export type SupplierNames =
    | 'Carnival Cruise Line'
    | 'Celebrity Cruises'
    | 'Cunard'
    | 'Holland America'
    | 'MSC Cruise Line'
    | 'Norwegian Cruise Line'
    | 'Princess'
    | 'Royal Caribbean'
    | 'Seabourn'
    | 'Virgin Voyages'
    | 'Viking'
    | 'Disney Cruise Line'
    | 'Azamara'
    | 'Explora Journeys'
    | 'SilverSea'
    | 'AMA Waterways'
    | 'Regent Seven Seas Cruises'
    | 'Oceania Cruises'
// WHEN ADDING NEW CRUISE LINES - MAKE SURE THE SPELLING IS EXACTLY THE SAME AS THE API VALUE!

export type SupplierCodes =
    | 'CCL'
    | 'CEL'
    | 'CUN'
    | 'HAL'
    | 'MSC'
    | 'NCL'
    | 'PCL'
    | 'RCL'
    | 'SBN'
    | 'VVC'
    | 'VKG'
    | 'DCL'
    | 'AZA'
    | 'EXP'
    | 'SSC'
    | 'AMA'
    | 'RSSC'
    | 'OCI'

export const SUPPLIER_NAMES: Record<SupplierCodes, SupplierNames> = {
    AMA: 'AMA Waterways',
    AZA: 'Azamara',
    CCL: 'Carnival Cruise Line',
    CEL: 'Celebrity Cruises',
    CUN: 'Cunard',
    DCL: 'Disney Cruise Line',
    EXP: 'Explora Journeys',
    HAL: 'Holland America',
    MSC: 'MSC Cruise Line',
    NCL: 'Norwegian Cruise Line',
    OCI: 'Oceania Cruises',
    PCL: 'Princess',
    RCL: 'Royal Caribbean',
    RSSC: 'Regent Seven Seas Cruises',
    SBN: 'Seabourn',
    SSC: 'SilverSea',
    VVC: 'Virgin Voyages',
    VKG: 'Viking',
}

export const availableToBookCruiseLines = [
    'CCL',
    'CEL',
    'CUN',
    'HAL',
    'MSC',
    'NCL',
    'PCL',
    'RCL',
    'SBN',
]

const notBookableCruiseLineCodes: SupplierCodes[] = [
    'VKG',
    'VVC',
    'DCL',
    'AZA',
    'EXP',
    'SSC',
    'AMA',
    'RSSC',
    'OCI',
]
export const notBookableCruiseLineNames = notBookableCruiseLineCodes.map(
    (code) => SUPPLIER_NAMES[code]
)

const logoUrls = content.app.supplierLogos
export const SUPPLIER_LOGOS: Record<SupplierCodes, string> = {
    AMA: logoUrls.AMAlogoUrl,
    AZA: logoUrls.AZAlogoUrl,
    CCL: logoUrls.CCLlogoUrl,
    CEL: logoUrls.CELlogoUrl,
    CUN: logoUrls.CUNlogoUrl,
    DCL: logoUrls.DCLlogoUrl,
    EXP: logoUrls.EXPlogoUrl,
    HAL: logoUrls.HALlogoUrl,
    MSC: logoUrls.MSClogoUrl,
    NCL: logoUrls.NCLlogoUrl,
    OCI: logoUrls.OCIlogoUrl,
    PCL: logoUrls.PCLlogoUrl,
    RCL: logoUrls.RCLlogoUrl,
    RSSC: logoUrls.RSSClogoUrl,
    SBN: logoUrls.SBNlogoUrl,
    SSC: logoUrls.SSClogoUrl,
    VVC: logoUrls.VVClogourl,
    VKG: logoUrls.VKGlogoUrl,
}

export const CARD_NAMES = { VISA: 'VI', MASTERCARD: 'MC', DISCOVER: 'DS', AMERICAN_EXPRESS: 'AX' }

export const DATE_FORMAT_D_M_Y_SLASH = 'dd/MM/yyyy' // 31/12/2024
export const DATE_FORMAT_Y_M_D_DASH = 'yyyy-MM-dd' // used in query params parser
export const DATE_FORMAT_USA_SHORT = 'MM/dd/yyyy' // 12/31/2024
export const DATE_FORMAT_USA_LONG = 'MMM d, yyyy' // 'Jan 27, 2024'
export const DATE_FORMAT_Y_M_D_HYPHEN = 'yyyy-MM-dd' // 2024-01-07
export const DATE_FORMAT_USA_LONG_WITH_TIME = 'MMM dd yyyy HH:mm a'
export const DATE_FORMAT_USA_TIME = 'HH:mm'
export const CURRENCY_CODES: Record<string, string> = { USD: '$', GBP: '£', EUR: '€' } // todo: source these from ? in future
export const FEATUREBASE_ORG = 'TravelTek'

export const PRODUCT_TYPES: {
    CRUISE: ProductType
    FLIGHT: ProductType
} = {
    CRUISE: 'CRUISE',
    FLIGHT: 'FLIGHT',
}

export const VITALLY_EVENTS = {
    APP_LOAD: 'Connect - App load',
    SEARCH_CRUISES: 'Connect - Search Cruises',
    LIVE_PRICING_SAILING: 'Connect - Live prices sailing',
    LIVE_PRICING_SAILING_BREAKDOWN: 'Connect - Live prices sailing - breaking-down',
    LIVE_PRICING_CABIN: 'Connect - Live pricing cabin',
    ADD_TO_BASKET: 'Connect - Add product to basket',
    ADD_TRAVELLER_DETAILS: 'Connect - Add traveller details',
    UPDATE_TRAVELLER_DETAILS: 'Connect - Update traveller details',
    UPDATE_CONTACT_DETAILS: 'Connect - Update contact details',
    GET_ALL_ORDERS: 'Connect - All orders - Booked',
    GET_ALL_QUOTES: 'Connect - All orders - Quotes',
    BOOK_SUCCESS: 'Connect - Book success',
    BOOK_FAIL: 'Connect - Book fail',
    RETRIEVE_BOOKING: 'Connect - Retrieve booking',
    IMPORT_BOOKING: 'Connect - Import booking',
    EMAIL_QUOTE_MODAL_OPEN: 'Connect - Email quote modal opened',
    IMPORT_CRUISE_MODAL_OPEN: 'Connect - Import cruise modal opened',
    FEATURE_PORTAL_OPEN: 'Connect - Feature portal opened',
    FEATURE_PORTAL_BANNER_OPEN: 'Connect - Feature portal opened from banner',
}

export const ENVIRONMENT_NAMES = {
    DEVELOPMENT: 'development',
    STAGE: 'stage',
    PRODUCTION: 'production',
    LOCAL: 'local',
}

export const NON_PRODUCTION_ENVIRONMENTS = [
    ENVIRONMENT_NAMES.DEVELOPMENT,
    ENVIRONMENT_NAMES.STAGE,
    ENVIRONMENT_NAMES.LOCAL,
]

export const HTTP_METHODS: {
    GET: HTTPMethods
    POST: HTTPMethods
    DELETE: HTTPMethods
    UPDATE: HTTPMethods
    PATCH: HTTPMethods
} = {
    GET: 'GET',
    POST: 'POST',
    DELETE: 'DELETE',
    UPDATE: 'UPDATE',
    PATCH: 'PATCH',
}

export const EMAILS_NOT_TO_SESSION_RECORD = ['engineering+bugbug+agentconnect+prod@traveltek.net']
