import { datadogLogs } from '@datadog/browser-logs'
import { v4 } from 'uuid'

import { getCurrentAuthData } from 'utils/auth-header'

import allContent from 'content/content'

const content = allContent.error.userData

export type CallApiOptions = {
    /** url */
    url: string
    /** object containing variables to be passed in body of methods other than GET */
    variables?: Record<string, any>
    /** method type */
    method: HTTPMethods
    /** used to trigger browser cache of response */
    cache?: boolean
    /** sets max age in milliseconds allowed for cache of response */
    cacheMaxAge?: number
    /** skipAuth = true allows skipping of auth headers - needed for api calls before user is logged in (i.e. signup!) */
    skipAuth?: boolean
    /** String to identify api method in logging purposes */
    source: string
}

/**
 * callApi: is a service to call Rest API
 * Takes params of method, url and variables in order to call fetch API
 * @param { CallApiOptions }  options
 * @returns { Promise<Response> | Error } Returns a promise that resolves to a Response object or throws an Error
 */
async function callApi({
    url,
    method,
    variables,
    cache = false,
    cacheMaxAge = 1000 * 60 * 10, // 10 minutes
    skipAuth = false,
    source,
}: CallApiOptions): Promise<Response> {
    const userContext = datadogLogs.getGlobalContext()
    const { accessToken, idToken } = await getCurrentAuthData()

    let tokenErrorMessage

    if (!accessToken && !skipAuth) tokenErrorMessage = content.noAccessToken
    if (!idToken && !skipAuth) tokenErrorMessage = content.noIdToken

    if (tokenErrorMessage) {
        throw new Error(tokenErrorMessage)
    }

    // don't pass body to the post request if there is none
    const shouldSendTheRequestBody =
        (method !== 'GET' && variables && Object.keys(variables).length > 0) ||
        Array.isArray(variables)

    const correlationId = v4() // Generate correlation ID for the request
    const options = {
        method: method,
        headers: {
            'x-correlation-id': correlationId,
            // TODO: Why do ours services have 3 ways to get the same authData in the headers? lol
            ...(accessToken && { authorization: accessToken }), // EKS
            ...(accessToken && { authorizationToken: accessToken }), // connect-manager-service
            ...(accessToken &&
                idToken && {
                    tokens: JSON.stringify({
                        authorization_token: accessToken, // jarvis
                        id_token: idToken, // EKS
                    }),
                }),
            'Content-Type': 'application/json',
            ...(cache &&
                method === 'GET' && { 'Cache-Control': `private, max-age=${cacheMaxAge}` }),
        }, // Browsers only cache GET requests
        ...(shouldSendTheRequestBody && { body: JSON.stringify(variables) }), // body can not exist
    }

    /** Log Fetch request with correlation ID */
    datadogLogs.logger.info(
        `Call-Api-Service Request, source: ${source}, method: ${method}, url: ${url} variables: ${JSON.stringify(
            variables
        )}, x-correlation-id: ${correlationId}`,
        {
            'x-correlation-id': correlationId,
            url: url,
            method: method,
            variables: variables,
            userContext: userContext,
        }
    )
    return await fetch(url, options)
        .then(async (response) => {
            const headers = response.headers
            if (response.ok) {
                /** Log Fetch response with correlation ID */
                datadogLogs.logger.info(
                    `Call-Api-Service Response, source: ${source}, status: ${
                        response.status
                    }, url: ${response.url} variables: ${JSON.stringify(
                        variables
                    )}, x-correlation-id: ${headers.get('x-correlation-id')}`,
                    {
                        'x-correlation-id': headers.get('x-correlation-id'),
                        correlation_id: headers.get('x-correlation-id'), // NOTE - this is to try to match api loggin
                        url: url,
                        status: response.status,
                        variables: variables,
                        userContext: userContext,
                    }
                )
                return response
            }

            // TODO: Improve what we get back from HTTP REST api errors, are the more fields we can get, which are optional?
            const parsedResponse = await response.json()
            const error = parsedResponse.error // some REST responses (payment) don't have .error but return:
            // [{
            //     "loc": [
            //         "body",
            //         "credit_card_expire_year"
            //     ],
            //       "msg": "credit card expired",
            //       "type": "value_error"
            // }]
            // but can't really handle all these error response variations. BE will align them to all have .error

            datadogLogs.logger.error(
                `Call-Api-Service Error, source: ${source}, status: ${response.status}, url: ${
                    response.url
                } variables: ${JSON.stringify(variables)}, x-correlation-id: ${headers.get(
                    'x-correlation-id'
                )}`,
                {
                    'x-correlation-id': headers.get('x-correlation-id'),
                    correlation_id: headers.get('x-correlation-id'), // NOTE - this is to try to match api logging
                    url: url,
                    status: response.status,
                    variables: variables,
                    userContext: userContext,
                },
                error
            )

            // TODO: What is the expected REST error response from all our apis... this needs confirming better
            const errorMessage = error?.message ?? 'Something went wrong.'
            // Create custom error that our app expects the shape of + has extra details such as error cause
            throw new Error(errorMessage, {
                cause: {
                    body: error?.error ? error : { error },
                    status: response.status,
                },
            })
        })
        .catch((err) => {
            if (err?.cause?.body?.error) {
                const errorMessage = err?.message ?? 'Something went wrong.'
                const errorBody = err?.cause?.body
                const errorStatus = err?.cause?.status
                throw new Error(errorMessage, {
                    cause: {
                        body: {
                            detail:
                                errorBody?.error?.details ??
                                errorBody?.error?.detail ??
                                errorBody?.error?.message,
                            error: errorBody?.error ? errorBody?.error : errorBody,
                        },
                        status: errorStatus,
                    },
                })
            } else throw err
        })
}

export default callApi
