import React from 'react'
import { Menu as MuiMenu, MenuItem as MuiMenuItem, StyledEngineProvider } from '@mui/material'
import Icon from 'components/basics/Icon/Icon'
import Text from 'components/basics/Text/Text'
import styles from './SortByMenu.module.scss'
import allContent from 'content/content'

const content = allContent.cruise.resultsPage

type SortOption = {
    text: string
    value: string
}

export type CRUISE_RESULT_SORT_OPTION_VALUES =
    | 'sailDateAsc'
    | 'sailDateDesc'
    | 'priceAsc'
    | 'priceDesc'
    | 'durationAsc'
    | 'durationDesc'

export const CRUISE_RESULT_SORT_OPTIONS: {
    SAIL_DATE_ASC: CRUISE_RESULT_SORT_OPTION_VALUES
    SAIL_DATE_DES: CRUISE_RESULT_SORT_OPTION_VALUES
    PRICE_ASC: CRUISE_RESULT_SORT_OPTION_VALUES
    PRICE_DES: CRUISE_RESULT_SORT_OPTION_VALUES
    DURATION_ASC: CRUISE_RESULT_SORT_OPTION_VALUES
    DURATION_DES: CRUISE_RESULT_SORT_OPTION_VALUES
} = {
    SAIL_DATE_ASC: 'sailDateAsc',
    SAIL_DATE_DES: 'sailDateDesc',
    PRICE_ASC: 'priceAsc',
    PRICE_DES: 'priceDesc',
    DURATION_ASC: 'durationAsc',
    DURATION_DES: 'durationDesc',
}

type SortByMenuProps = {
    value: string
    onChange: (value: CRUISE_RESULT_SORT_OPTION_VALUES) => void
}

const SortByMenu: React.FC<SortByMenuProps> = ({ value, onChange }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (): void => {
        setAnchorEl(null)
    }

    const sortOptions: SortOption[] = [
        {
            text: content.sortOptionsText.sailDateAscending,
            value: CRUISE_RESULT_SORT_OPTIONS.SAIL_DATE_ASC,
        },
        {
            text: content.sortOptionsText.sailDateDescending,
            value: CRUISE_RESULT_SORT_OPTIONS.SAIL_DATE_DES,
        },
        {
            text: content.sortOptionsText.priceAscending,
            value: CRUISE_RESULT_SORT_OPTIONS.PRICE_ASC,
        },
        {
            text: content.sortOptionsText.priceDescending,
            value: CRUISE_RESULT_SORT_OPTIONS.PRICE_DES,
        },
        {
            text: content.sortOptionsText.durationAscending,
            value: CRUISE_RESULT_SORT_OPTIONS.DURATION_ASC,
        },
        {
            text: content.sortOptionsText.durationDescending,
            value: CRUISE_RESULT_SORT_OPTIONS.DURATION_DES,
        },
    ]

    return (
        <StyledEngineProvider injectFirst>
            <div className={styles['menu__container']}>
                <Text size='S'>{content.sortLabelText}</Text>
                <button
                    className={styles['menu__button']}
                    aria-controls={open ? 'sort-by-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleOpen}
                >
                    <Text size='S'>
                        {sortOptions.find((option) => option.value === value)?.text}
                    </Text>
                    <Icon iconName='ChevronDown' iconSize='S' />
                </button>
            </div>
            <MuiMenu
                id='sort-by-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'sort-by-menu' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {sortOptions.map((option) => (
                    <MuiMenuItem
                        key={option.value}
                        className={styles['menu__menu-item']}
                        onClick={(): void => {
                            onChange(option.value as CRUISE_RESULT_SORT_OPTION_VALUES)
                            handleClose()
                        }}
                    >
                        <Text>{option.text}</Text>
                    </MuiMenuItem>
                ))}
            </MuiMenu>
        </StyledEngineProvider>
    )
}

export default SortByMenu
