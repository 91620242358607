import React from 'react'
import { datadogLogs } from '@datadog/browser-logs'

import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import Text from 'components/basics/Text/Text'
import { capitalizeEachWord } from 'utils/string-helpers'
import { copyToClipboardHandler } from 'utils/file-system-helpers'

import allContent from 'content/content'
import styles from './ErrorList.module.css'
import InlineAccordion from '../../../blocks/Accordions/InlineAccordion/InlineAccordion'

const content = allContent.components.errorList

type ErrorListProps = {
    /** List of errors from API */
    errorsList: CustomApiError[] | string
    /** String used to identify component where logged errors are coming from */
    source: string
    isCloseable?: boolean
}

/** ErrorListSection: Returns a component that displays a list of API errors */
const ErrorList: React.FC<ErrorListProps> = ({ errorsList, source, isCloseable = false }) => {
    const userContext = datadogLogs.getGlobalContext()
    const getErrorDetailsText = (error: any): string => {
        if (!error.error_details) {
            return ''
        }
        return Object.entries(error.error_details[0])
            .map(([key, value]) => `${capitalizeEachWord(key)}: ${value}`)
            .join('\n')
    }
    return (
        <div className={styles['container']}>
            {Array.isArray(errorsList) &&
                errorsList?.map((error, index) => {
                    datadogLogs.logger.error(
                        // TODO: Remove this if callApi logging errors means this is duplicated
                        `source: ${source}(banner array), error-${index + 1}`,
                        { userContext },
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        error
                    )
                    const errorDetailsText = getErrorDetailsText(error)
                    const errorDetails = errorDetailsText ? (
                        <div id={`error-details-${error.id}`}>
                            {errorDetailsText.split('\n').map((detail, i) => (
                                <div key={`detail-${i}`}>{detail}</div>
                            ))}
                        </div>
                    ) : null
                    /* if there are no error details then at least display error code if present */
                    const errorCode =
                        !errorDetails && error.error_code
                            ? `${content.errorCode}: ${error.error_code}`
                            : null
                    return (
                        <InfoBanner
                            id={`api-error-${source}-${error.id}`}
                            key={error.id}
                            isFocusable={true}
                            isCloseable={isCloseable}
                            text={error.error_description}
                            bannerType='error'
                            buttonText={errorDetails ? content.copyButton : undefined}
                            buttonIcon='Copy'
                            logType={error.error_type}
                            onButtonClick={(): void => {
                                const copyText = errorDetailsText
                                copyToClipboardHandler(
                                    copyText
                                        ? `${copyText}\nURL: ${
                                              window.location.href
                                          }\nDate: ${new Date().toUTCString()}`
                                        : ''
                                )
                            }}
                        >
                            {!!errorDetails && (
                                <InlineAccordion
                                    title={
                                        <Text size='S' weight='bold'>
                                            {`${content.errorCode} ${error.error_code} ${content.details}`}
                                        </Text>
                                    }
                                    baseId={`api-error-accordion${error.id}`}
                                >
                                    {errorDetails}
                                </InlineAccordion>
                            )}
                            {!!errorCode && <Text>{errorCode}</Text>}
                        </InfoBanner>
                    )
                })}
            {typeof errorsList === 'string' && (
                <InfoBanner
                    id={`api-error-${errorsList}`}
                    key={errorsList}
                    isFocusable={true}
                    isCloseable={false}
                    text={`${source} api call: ${errorsList}`}
                    bannerType='error'
                    buttonText={content.copyButton}
                    buttonIcon='Copy'
                    logType={'error'}
                    source={source}
                    onButtonClick={(): void => {
                        const copyText = errorsList
                        copyToClipboardHandler(
                            copyText
                                ? `${copyText}\nURL: ${
                                      window.location.href
                                  }\nDate: ${new Date().toUTCString()}`
                                : ''
                        )
                    }}
                />
            )}
        </div>
    )
}

export default ErrorList
