import React, { ReactNode, useState } from 'react'
import classnames from 'classnames'

import Icon from 'components/basics/Icon/Icon'
import Text from 'components/basics/Text/Text'
import styles from './InlineAccordion.module.scss'

type InlineAccordionProps = {
    /** Title/header of the accordion */
    title: ReactNode
    /** Set to true if Accordion should start in Active state */
    startOpen?: boolean
    /** children always of type ReactNode */
    children?: React.ReactNode
    /** id used for aria tags */
    baseId: string
    /** Text for toggle near Chevron icon */
    toggleText?: string
    className?: string
}

/** InlineAccordionProps: is for us inside other content - has no borders and headers and colour */
const InlineAccordion: React.FC<InlineAccordionProps> = ({
    title,
    startOpen = false,
    children,
    baseId,
    toggleText = '',
    className,
}: InlineAccordionProps) => {
    const [isOpen, setIsOpen] = useState(startOpen)
    const toggleAccordion = (): void => {
        setIsOpen(!isOpen)
    }

    const containerClasses = classnames(styles.container, {
        [`${className}`]: !!className,
    })
    const contentClasses = classnames(styles.content, { [styles['content-hide']]: !isOpen })

    const contentId = `${baseId}-content`
    const accordionId = `${baseId}-accordion`

    return (
        <div className={containerClasses}>
            <div className={styles.header}>
                <button
                    type='button'
                    id={accordionId}
                    aria-expanded={isOpen}
                    aria-controls={contentId}
                    className={styles.header}
                    onClick={toggleAccordion}
                >
                    {title}
                    <div className={styles['header-toggle']}>
                        {toggleText && (
                            <Text color='white' size='S'>
                                {toggleText}
                            </Text>
                        )}
                        <Icon
                            iconName={isOpen ? 'ChevronUp' : 'ChevronDown'}
                            iconSize='S'
                            iconColor='primary-midnight'
                            className={styles['header-icon']}
                        />
                    </div>
                </button>
            </div>
            <section
                id={contentId}
                aria-labelledby={accordionId}
                aria-hidden={!isOpen}
                className={contentClasses}
            >
                {/* this div is needed so padded content is hidden when closed  */}
                <div>{children}</div>
            </section>
        </div>
    )
}

export default InlineAccordion
