import React, { useCallback } from 'react'
import { AutosuggestProps } from 'react-autosuggest'
import { debounce } from 'lodash'
import Autosuggest from '../Autosuggest/Autosuggest'
import callApi from 'services/callApi/callApi.service'

type makeApiCallProps = {
    /** url */
    url: string
    /** method */
    method: HTTPMethods
    /** logging */
    source: string
    /** any variables for the request */
    variables?: Record<string, any>
    /** error handling callback */
    handleError?(error: ErrorEvent): void
    /** result handling callback */
    handleResults?(result: void): void
    /** debounceDelay - default 500ms*/
    debounceDelay?: number
    /** min input value length to trigger api call - default 1 */
    minInputLength?: number
}

type AutosuggestInputWithApiCallProps = {
    /** params for making the api call */
    apiCallOptions: makeApiCallProps
    /** for styling the input container (width etc.) */
    className?: string
    /** function that knows the shape of the suggestions data and returns the value from the suggestion object */
    getSuggestionValue(dataItem: any): string
    /** needed if more than one Autosuggest is used on the same page */
    id?: string
    /** returns current value of input */
    inputValue: string
    /** input field name */
    name: string
    /** allows user to free type their input and skip picking from suggestion list */
    onBlur?: (currentInputValue: string, highlightedSuggestion: any) => void
    /** called after selecting a suggestion from the list (if youneed to update something on that action) */
    onSuggestionSelected(value: any): void
    /** opens the suggestion list (if present) on focus */
    openSuggestionsOnFocus?: boolean
    /** function that should return JSX of input, takes standard form props */
    renderInputComponent: AutosuggestProps<any, any>['renderInputComponent']
    /** function that should return JSX of suggestion when passed each suggestion */
    renderSuggestion(dataItem: any): React.ReactElement
    /** callback used to track state of the input value */
    setInputValue(value: string): void
    /** state setter for the current suggestions in the autosuggest list */
    setSuggestionsData: React.Dispatch<React.SetStateAction<any[]>>
    /** state getter for current suggestions in the autosuggest list */
    suggestionsData: any[]
}

export const AutosuggestWithApiCall = ({
    apiCallOptions,
    className,
    getSuggestionValue,
    inputValue,
    name,
    id = name,
    onBlur,
    onSuggestionSelected,
    openSuggestionsOnFocus = false,
    renderInputComponent,
    renderSuggestion,
    setSuggestionsData,
    suggestionsData,
    setInputValue,
}: AutosuggestInputWithApiCallProps): JSX.Element => {
    function handleApiRequest({
        inputString,
        apiCallOptions,
    }: {
        inputString: string
        apiCallOptions: makeApiCallProps
    }): void {
        const {
            url,
            source,
            method,
            variables,
            handleError,
            handleResults,
            minInputLength = 1,
        } = apiCallOptions
        if (minInputLength <= inputString.length)
            callApi({
                url: url,
                method: method,
                variables: { ...variables, subString: inputString },
                source: source,
            })
                .then((response) => {
                    return response.json()
                })
                .then((result) => {
                    if (result && handleResults) handleResults(result)
                })
                .catch((error) => {
                    if (handleError) handleError(error)
                })
    }

    // Debounced API request handler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedFetchSuggestions = useCallback(
        debounce((inputString: string) => {
            handleApiRequest({ inputString, apiCallOptions })
        }, apiCallOptions.debounceDelay ?? 500),
        [apiCallOptions.debounceDelay]
    )

    return (
        <Autosuggest
            className={className}
            getSuggestionValue={getSuggestionValue}
            name={name}
            id={id}
            inputValue={inputValue}
            onBlur={onBlur}
            onSuggestionsClearRequested={(): void => setSuggestionsData([])}
            onSuggestionSelected={onSuggestionSelected}
            openSuggestionsOnFocus={openSuggestionsOnFocus}
            renderInputComponent={renderInputComponent}
            renderSuggestion={renderSuggestion}
            setInputValue={setInputValue}
            suggestionsData={suggestionsData}
            suggestionsFetchRequest={debouncedFetchSuggestions}
        />
    )
}

export default AutosuggestWithApiCall
