import React, { useState, useEffect, useMemo, useCallback, ChangeEvent } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { datadogLogs } from '@datadog/browser-logs'
import { useNavigate } from 'react-router-dom'

import { checkAndPerformUserSessionRefreshIfNeeded } from 'utils/cognito-helpers/check-and-perform-user-session-refresh-if-needed'
import { countryData } from 'data/country-codes'
import { ContactDetails } from 'api-data-models/order/OrderContentModel'
import {
    LeadTravellerPersonalInformation,
    Order_Keys,
    ORDER_VIEWS,
} from 'components/layouts/order/OrderLayout/OrderLayout'
import { ORDER_ACTIONS } from 'components/reducers/orderReducer'
import { PHONE_TYPE } from '../TravellerAddForm/TravellerAddForm'
import { REGEX, VITALLY_EVENTS, HTTP_METHODS } from 'utils/constants'
import callApi from 'services/callApi/callApi.service'

import Button from 'components/basics/Button/Button'
import Card from 'components/blocks/Card/Card'
import Checkbox from 'components/basics/Input/Checkbox/Checkbox'
import ColoredLine from 'components/basics/ColoredLine/ColoredLine'
import FieldError from 'components/basics/FieldError/FieldError'
import Heading from 'components/basics/Heading/Heading'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import PhoneInput from 'components/blocks/PhoneInput/PhoneInput'
import Select from 'components/basics/Input/Select/Select'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import { getRestErrorMessage } from 'utils/api-errors-helpers/get-rest-error-message'
import CustomerSuccess from 'services/customerSuccess/customerSuccess.service'
import ErrorList from 'components/sections/app/ErrorList/ErrorList'

import styles from './ContactDetailsAddForm.module.css'
import allContent from 'content/content'
import { orderServiceUrls } from 'utils/order-service-urls'

const content = allContent.order.orderPage.contactDetailsAddForm

type APIContactFormData = {
    title: string
    firstName: string
    middleName?: string
    lastName: string
    addressLine1: string
    addressLine2: string
    cityRegion: string
    stateProvince: string
    zipPostalCode: string
    country: string
    email: string
    phoneType: string
    phoneNumber: string
    orderId: string
}

const validateContactForm = (contactFormData: APIContactFormData): boolean => {
    // eslint-disable-next-line no-console
    console.log('Contact details form still has no validation, data being passed:', contactFormData)
    // TODO: Add some validation!!!!!
    return true
}

type ContactFormProps = {
    contactTitle: string
    contactFirstName: string
    contactMiddleName: string
    contactLastName: string
    contactAddressLine1: string
    contactAddressLine2: string
    contactCityRegion: string
    contactAddressStateProvince: string
    contactCountry: string
    contactZipPostalCode: string
    contactEmail: string
    contactCountryCallingCode: string
    contactPhoneNumber: string
}

type ContactDetailsAddFormProps = {
    dispatchOrderState(props: any): void
    orderId: string
    contactDetails: ContactDetails
    setView: React.Dispatch<React.SetStateAction<(typeof ORDER_VIEWS)[Order_Keys]>>
    extractedLeadTravellerContactDetails?: LeadTravellerPersonalInformation
    backToOrder(): void
    setCompletedStep(): void
}

const ContactDetailsAddForm = ({
    dispatchOrderState,
    orderId,
    contactDetails,
    setView,
    extractedLeadTravellerContactDetails,
    backToOrder,
    setCompletedStep,
}: ContactDetailsAddFormProps): JSX.Element => {
    const [disableMiddleNameInput, setDisableMiddleNameInput] = useState(false)
    const [apiError, setApiError] = useState<CustomApiError[] | null>(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isEditing, setIsEditing] = useState(false)

    const [contactDetailsSameAsLeadPassenger, setContactDetailsSameAsLeadPassenger] =
        useState(false)

    const defaultValues = {
        contactCityRegion: '',
        contactCountry: '',
        contactAddressLine1: '',
        contactAddressLine2: '',
        contactAddressStateProvince: '',
        contactZipPostalCode: '',
        contactEmail: '',
        contactFirstName: '',
        contactLastName: '',
        contactMiddleName: '',
        contactNoMiddleName: false,
        contactTitle: '',
        contactCountryCallingCode: '',
        contactPhoneNumber: '',
    }
    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: defaultValues,
    })

    const userContext = datadogLogs.getGlobalContext()
    const navigate = useNavigate()

    useEffect(() => {
        let noMiddleName = true // TODO: get api to take this... till then needs to be inferred with this crap
        if (contactDetails.middleName.length > 0) noMiddleName = false // set checkbox to gray out middle name to unchecked if there is a middle name
        if (contactDetails.firstName === '' && contactDetails.middleName.length <= 0)
            noMiddleName = false // also set checkbox to gray out middle name to unchecked if there is no first name, because there is no data yet
        setDisableMiddleNameInput(noMiddleName)
        const newInitialValues = {
            contactCityRegion: contactDetails.address.cityRegion,
            contactCountry: contactDetails.address.country,
            contactAddressLine1: contactDetails.address.line1,
            contactAddressLine2: contactDetails.address.line2,
            contactAddressStateProvince: contactDetails.address.stateProvince,
            contactZipPostalCode: contactDetails.address.zipPostalCode,
            contactEmail: contactDetails.email,
            contactFirstName: contactDetails.firstName,
            contactLastName: contactDetails.lastName,
            contactMiddleName: contactDetails.middleName ?? '',
            contactNoMiddleName: noMiddleName,
            contactTitle: contactDetails.title,
            contactPhoneNumber: contactDetails.phoneNumber.number,
        }
        setIsEditing(!!contactDetails.firstName)
        reset({ ...newInitialValues })
    }, [contactDetails, reset])

    const titleOptions = [
        { value: '', text: 'Select title' },
        { value: 'MR', text: 'Mr' },
        { value: 'MS', text: 'Ms' },
    ]
    const handleSameAsLeadPassengerChange = (): void => {
        setContactDetailsSameAsLeadPassenger(!contactDetailsSameAsLeadPassenger) // Toggle checkbox state
        if (!contactDetailsSameAsLeadPassenger) {
            // If checkbox is checked, fill out form fields with lead traveller details
            setValue('contactTitle', extractedLeadTravellerContactDetails?.contactTitle ?? '')
            setValue(
                'contactFirstName',
                extractedLeadTravellerContactDetails?.contactFirstName ?? ''
            )
            setValue(
                'contactNoMiddleName',
                extractedLeadTravellerContactDetails?.noMiddleNameChecked ?? false
            )

            setValue(
                'contactMiddleName',
                extractedLeadTravellerContactDetails?.contactMiddleName ?? ''
            )
            setValue('contactLastName', extractedLeadTravellerContactDetails?.contactLastName ?? '')
            setDisableMiddleNameInput(getValues('contactNoMiddleName'))
        } else {
            // If checkbox is unchecked, reset form fields to default values
            reset(defaultValues)
            setDisableMiddleNameInput(false)
        }
    }

    const countryCallingCodeOptions = useMemo(() => {
        return countryData
            .filter((country) => country.iso2Code !== 'UM') // Exclude the object with iso2Code 'UM' because the value won't be unique and is not needed here
            .map((country) => ({
                value: country.phoneCode,
                text: country.flag + ' ' + country.name + ' +' + country.phoneCode,
            }))
    }, [])

    const countryOptions = useMemo(() => {
        const options = new Set(
            countryData
                .map((country) => {
                    return {
                        value: country.iso2Code,
                        text: country.flag + ' ' + country.name,
                    }
                })
                .sort()
        )
        return [{ value: '', text: 'Please select...' }, ...options]
    }, [])

    const countryCallingCodeOnChangeCallback = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            setValue('contactCountryCallingCode', e.target.value)
        },
        [setValue]
    )
    const contactCountryOnChangeCallback = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            setValue('contactCountry', e.target.value)
        },
        [setValue]
    )

    const handleSelectTitle = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            setValue('contactTitle', e.target.value)
        },
        [setValue]
    )

    const handleCheckNoMiddleName = (): void => {
        setDisableMiddleNameInput(!getValues('contactNoMiddleName'))
        setValue('contactNoMiddleName', !getValues('contactNoMiddleName'))
    }

    return (
        <>
            <Card header={content.heading}>
                <div className={styles.container}>
                    {apiError && <ErrorList errorsList={apiError} source='ContactDetailsAddForm' />}
                    <form
                        id='contactDetailsAddForm'
                        className={styles.form}
                        onSubmit={handleSubmit(
                            async ({
                                contactTitle,
                                contactFirstName,
                                contactMiddleName,
                                contactLastName,
                                contactAddressLine1,
                                contactAddressLine2,
                                contactCityRegion,
                                contactAddressStateProvince,
                                contactZipPostalCode,
                                contactCountry,
                                contactEmail,
                                contactCountryCallingCode,
                                contactPhoneNumber,
                            }: ContactFormProps) => {
                                setApiError(null)
                                const contactFormData: APIContactFormData = {
                                    title: contactTitle,
                                    firstName: contactFirstName,
                                    lastName: contactLastName,
                                    ...(contactMiddleName && { middleName: contactMiddleName }),
                                    addressLine1: contactAddressLine1,
                                    addressLine2: contactAddressLine2,
                                    cityRegion: contactCityRegion,
                                    stateProvince: contactAddressStateProvince,
                                    country: contactCountry,
                                    email: contactEmail,
                                    phoneType: PHONE_TYPE,
                                    phoneNumber: `+${contactCountryCallingCode}${contactPhoneNumber}`,
                                    zipPostalCode: contactZipPostalCode,
                                    orderId: orderId,
                                }

                                const isValid = validateContactForm(contactFormData)
                                if (isValid) {
                                    setIsSubmitting(true)
                                    const handleUserSession =
                                        checkAndPerformUserSessionRefreshIfNeeded(navigate)
                                    handleUserSession.then(() => {
                                        const orderContactDetailsUpdateUrl =
                                            orderServiceUrls.updateOrderContact(orderId)

                                        const requestBody = {
                                            title: contactTitle,
                                            first_name: contactFirstName,
                                            middle_name: contactMiddleName,
                                            last_name: contactLastName,
                                            address: {
                                                line_1: contactAddressLine1,
                                                line_2: contactAddressLine2,
                                                city_region: contactCityRegion,
                                                state_province: contactAddressStateProvince,
                                                zip_postal_code: contactZipPostalCode,
                                                country: contactCountry,
                                            },
                                            phone_number: {
                                                type: PHONE_TYPE,
                                                number: `+${contactCountryCallingCode}${contactPhoneNumber}`,
                                            },
                                            email: contactEmail,
                                        }
                                        callApi({
                                            url: orderContactDetailsUpdateUrl,
                                            method: HTTP_METHODS.POST,
                                            variables: requestBody,
                                            source: 'OrderContactDetailsUpdate',
                                        })
                                            .then((response) => {
                                                return response.json()
                                            })
                                            .then((data) => {
                                                datadogLogs.logger.info(
                                                    `source: OrderContactDetailsUpdate`,
                                                    { userContext }
                                                )
                                                if (data) {
                                                    dispatchOrderState({
                                                        type: ORDER_ACTIONS.UPDATE_CONTACT_DETAILS,
                                                        payload: contactFormData,
                                                    })
                                                    setView(ORDER_VIEWS.ORDER)
                                                    setCompletedStep()
                                                }
                                            })
                                            .catch((error) => {
                                                const errorMessage = getRestErrorMessage({
                                                    errors: error.cause,
                                                    source: orderContactDetailsUpdateUrl,
                                                    variables: requestBody,
                                                })

                                                setApiError(errorMessage)
                                            })
                                            .finally(() => {
                                                setIsSubmitting(false)
                                                CustomerSuccess.track({
                                                    eventName:
                                                        VITALLY_EVENTS.UPDATE_CONTACT_DETAILS,
                                                })
                                            })
                                    })
                                } else {
                                    // todo: add validation failure handling
                                }
                            }
                        )}
                    >
                        <fieldset
                            disabled={isSubmitting}
                            className={styles['inputs-group--vertical']}
                        >
                            <Heading heading='3'>{content.personalInformation}</Heading>
                            <Checkbox
                                text={content.sameAsLeadPassenger}
                                onChange={handleSameAsLeadPassengerChange}
                            />
                            <Controller
                                control={control}
                                name='contactTitle'
                                render={({ field: { value } }): React.ReactElement => (
                                    <LabelledInput
                                        htmlFor='contactTitle'
                                        label={content.title}
                                        required={true}
                                    >
                                        <Select
                                            value={value}
                                            className={styles['short-input']}
                                            options={titleOptions}
                                            onChange={handleSelectTitle}
                                            isErrored={!!errors.contactTitle}
                                        />
                                    </LabelledInput>
                                )}
                            />
                            <Controller
                                control={control}
                                name='contactFirstName'
                                rules={{
                                    required: true,
                                    minLength: 1,
                                    maxLength: 200,
                                    pattern: REGEX.NAME,
                                }}
                                render={({ field: { value, ref } }): React.ReactElement => (
                                    <div>
                                        <LabelledInput
                                            htmlFor='contactFirstName'
                                            label={content.firstName}
                                            required={true}
                                            ref={ref}
                                            isErrored={!!errors.contactFirstName}
                                        >
                                            <TextInput
                                                value={value}
                                                className={styles['short-input']}
                                                regExp={/^[',.\- a-zA-Z]*$/} // limit what can be typed to characters the final validation patten uses.
                                                onChangeCallback={(value): void => {
                                                    setValue('contactFirstName', value)
                                                }}
                                                required={true}
                                            />
                                        </LabelledInput>
                                        <FieldError
                                            errorMessage={content.errors.name}
                                            showError={!!errors.contactFirstName}
                                            inputId='contactFirstName'
                                        />
                                    </div>
                                )}
                            />
                            <div>
                                <div className={styles['middle-name--inputs-group']}>
                                    <Controller
                                        control={control}
                                        name='contactMiddleName'
                                        render={({ field: { value, ref } }): React.ReactElement => (
                                            <LabelledInput
                                                htmlFor='contactMiddleName'
                                                label={content.middleName}
                                                disabled={disableMiddleNameInput}
                                                required={!disableMiddleNameInput}
                                                isErrored={!!errors.contactMiddleName}
                                                ref={ref}
                                            >
                                                <TextInput
                                                    value={value}
                                                    className={styles['short-input']}
                                                    regExp={/^[',.\- a-zA-Z]*$/} // limit what can be typed to characters the final validation patten uses.
                                                    onChangeCallback={(value): void => {
                                                        setValue('contactMiddleName', value)
                                                    }}
                                                />
                                            </LabelledInput>
                                        )}
                                    />
                                    <div className={styles['middle-name--checkbox']}>
                                        <Controller
                                            control={control}
                                            name='contactNoMiddleName'
                                            render={({ field: { value } }): React.ReactElement => (
                                                <Checkbox
                                                    text={content.noMiddleName}
                                                    value={content.noMiddleName}
                                                    checked={value}
                                                    onChange={handleCheckNoMiddleName}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <FieldError
                                    errorMessage={content.errors.name}
                                    showError={!!errors.contactMiddleName}
                                    inputId='contactMiddleName'
                                />
                            </div>
                            <Controller
                                control={control}
                                name='contactLastName'
                                rules={{
                                    required: true,
                                    minLength: 1,
                                    maxLength: 200,
                                    pattern: REGEX.NAME,
                                }}
                                render={({ field: { value, ref } }): React.ReactElement => (
                                    <div>
                                        <LabelledInput
                                            htmlFor='contactLastName'
                                            label={content.lastName}
                                            required={true}
                                            ref={ref}
                                            isErrored={!!errors.contactLastName}
                                        >
                                            <TextInput
                                                value={value}
                                                className={styles['short-input']}
                                                regExp={/^[',.\- a-zA-Z]*$/} // limit what can be typed to characters the final validation patten uses.
                                                onChangeCallback={(value): void => {
                                                    setValue('contactLastName', value)
                                                }}
                                            />
                                        </LabelledInput>
                                        <FieldError
                                            errorMessage={content.errors.name}
                                            showError={!!errors.contactLastName}
                                            inputId='contactLastName'
                                        />
                                    </div>
                                )}
                            />
                        </fieldset>
                        <ColoredLine padding='non' />
                        <fieldset
                            disabled={isSubmitting}
                            className={styles['inputs-group--vertical']}
                        >
                            <Heading heading='3'>{content.contactInformation}</Heading>
                            <Controller
                                control={control}
                                name='contactAddressLine1'
                                rules={{ required: true }}
                                render={({ field: { value } }): React.ReactElement => (
                                    <LabelledInput
                                        htmlFor='contactAddressLine1'
                                        label={content.addressLine1}
                                        required={true}
                                        isErrored={!!errors.contactAddressLine1}
                                    >
                                        <TextInput
                                            value={value}
                                            onChangeCallback={(value): void => {
                                                setValue('contactAddressLine1', value)
                                            }}
                                            required={true}
                                        />
                                    </LabelledInput>
                                )}
                            />
                            <Controller
                                control={control}
                                name='contactAddressLine2'
                                render={({ field: { value } }): React.ReactElement => (
                                    <LabelledInput
                                        htmlFor='contactAddressLine2'
                                        label={content.addressLine2}
                                        isErrored={!!errors.contactAddressLine2}
                                    >
                                        <TextInput
                                            value={value}
                                            onChangeCallback={(value): void => {
                                                setValue('contactAddressLine2', value)
                                            }}
                                        />
                                    </LabelledInput>
                                )}
                            />
                            <div className={styles['inputs-group--horizontal']}>
                                <Controller
                                    control={control}
                                    name='contactCountry'
                                    rules={{ required: true }}
                                    render={({ field: { value } }): React.ReactElement => (
                                        <LabelledInput
                                            htmlFor='contact-country'
                                            label={content.country}
                                            className={styles['input-half']}
                                            required={true}
                                        >
                                            <Select
                                                value={value}
                                                options={countryOptions}
                                                onChange={contactCountryOnChangeCallback}
                                            />
                                        </LabelledInput>
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name='contactAddressStateProvince'
                                    render={({ field: { value } }): React.ReactElement => (
                                        <LabelledInput
                                            htmlFor='contactAddressStateProvince'
                                            label={content.stateProvince}
                                            className={styles['input-half']}
                                            required={true} // TODO remove because this was added in a refactor PR that had no requirement to make this required.
                                        >
                                            <TextInput
                                                value={value}
                                                onChangeCallback={(value): void => {
                                                    setValue('contactAddressStateProvince', value)
                                                }}
                                                required={false}
                                            />
                                        </LabelledInput>
                                    )}
                                />
                            </div>
                            <div className={styles['inputs-group--horizontal']}>
                                <Controller
                                    control={control}
                                    name='contactCityRegion'
                                    render={({ field: { value } }): React.ReactElement => (
                                        <LabelledInput
                                            htmlFor='contactCityRegion'
                                            label={content.city}
                                            className={styles['input-half']}
                                            required={true}
                                        >
                                            <TextInput
                                                value={value}
                                                onChangeCallback={(value): void => {
                                                    setValue('contactCityRegion', value)
                                                }}
                                                required={true}
                                            />
                                        </LabelledInput>
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name='contactZipPostalCode'
                                    render={({ field: { value } }): React.ReactElement => (
                                        <LabelledInput
                                            htmlFor='contactZipPostalCode'
                                            label={content.zipPostalCode}
                                            className={styles['input-half']}
                                            required={true}
                                        >
                                            <TextInput
                                                value={value}
                                                onChangeCallback={(value): void => {
                                                    setValue('contactZipPostalCode', value)
                                                }}
                                                required={true}
                                            />
                                        </LabelledInput>
                                    )}
                                />
                            </div>
                            <div className={styles['inputs-group--horizontal']}>
                                <PhoneInput
                                    countryCodeFieldName={'contactCountryCallingCode'}
                                    inputFieldName={'contactPhoneNumber'}
                                    generalError={
                                        !!errors.contactPhoneNumber ||
                                        !!errors.contactCountryCallingCode
                                    }
                                    disabled={isSubmitting}
                                    groupLabel={content.phoneNumber}
                                    countryCodeLabel={content.countryCallingCode}
                                    inputLabel={content.phoneNumberNoCountryCode}
                                    control={control}
                                    countryCodeOptions={countryCallingCodeOptions}
                                    onCountryCodeChange={countryCallingCodeOnChangeCallback}
                                    onChange={setValue}
                                    required
                                />
                                <Controller
                                    control={control}
                                    name='contactEmail'
                                    rules={{ pattern: REGEX.EMAIL, required: true }}
                                    render={({ field: { value, ref } }): React.ReactElement => (
                                        <div>
                                            <LabelledInput
                                                htmlFor='contactEmail'
                                                label={content.email}
                                                className={styles['input-half']}
                                                isErrored={!!errors.contactEmail}
                                                required={true}
                                                ref={ref}
                                            >
                                                <TextInput
                                                    value={value}
                                                    onChangeCallback={(value): void => {
                                                        setValue('contactEmail', value)
                                                    }}
                                                    required={true}
                                                />
                                            </LabelledInput>
                                            <FieldError
                                                errorMessage={content.errors.email}
                                                showError={!!errors.contactEmail}
                                                inputId='contactEmail'
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                        </fieldset>
                    </form>
                </div>
            </Card>
            <div className={styles['buttons-wrapper']}>
                <Button
                    text={content.backButton}
                    flavour='tertiary'
                    onClick={backToOrder}
                    iconName='ChevronLeft'
                    type='button'
                />
                {isEditing && (
                    <Button
                        text={content.continueWithoutSavingButton}
                        flavour='tertiary'
                        onClick={(): void => setCompletedStep()}
                        type='button'
                    />
                )}
                <Button
                    form='contactDetailsAddForm'
                    className={styles['submit-button']}
                    text={content.submitContactForm}
                    type='submit'
                    showSpinner={isSubmitting}
                />
            </div>
        </>
    )
}
export default ContactDetailsAddForm
