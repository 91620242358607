import React, { forwardRef } from 'react'
import classnames from 'classnames'

import Icon, { IconNames, IconColours, IconSizes } from '../Icon/Icon'
import InlineSpinner from '../Spinners/InlineSpinner'
import styles from './Button.module.scss'

type ButtonStyles = 'primary' | 'secondary' | 'tertiary' | 'text' | 'icon'

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    text: string
    className?: string
    iconName?: IconNames
    onClick?(): void
    flavour?: ButtonStyles
    onDarkBackground?: boolean
    /** type of button can only be 'button' or 'submit' (!reset type not allowed!) */
    type?: 'button' | 'submit'
    /** toggle to disable button show small spinner */
    showSpinner?: boolean
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const {
        text,
        className,
        showSpinner = false,
        disabled = showSpinner,
        onClick,
        type = 'submit',
        flavour = 'primary',
        iconName,
        onDarkBackground = false,
        ...rest
    } = props
    const iconOnly = flavour === 'icon'

    const buttonClassNames = classnames(
        styles.button,
        [styles[`button__${flavour}`]],
        { [styles[`button__${flavour}--alt`]]: onDarkBackground },
        className
    )

    let iconColor: IconColours = 'white'
    if (flavour === 'tertiary' && !onDarkBackground) iconColor = 'primary-midnight'
    if (flavour === 'text' || flavour === 'icon') iconColor = 'tertiary-blue'
    if ((flavour === 'text' || flavour === 'icon') && onDarkBackground)
        iconColor = 'tertiary-blue-25'

    let iconSize: IconSizes = 'S'
    if (iconOnly) iconSize = 'M'

    const LabelText = iconOnly ? (
        <span className={'visually-hidden'}>{text}</span>
    ) : (
        <span>{text}</span>
    )

    return (
        <button
            ref={ref}
            type={type}
            className={buttonClassNames}
            onClick={onClick}
            disabled={disabled}
            {...rest}
        >
            {iconName && <Icon iconName={iconName} iconSize={iconSize} iconColor={iconColor} />}
            {showSpinner ? (
                <InlineSpinner text={text} onDarkBackground={onDarkBackground} />
            ) : (
                LabelText
            )}
        </button>
    )
})

Button.displayName = 'Button'

export default Button
