/** error returned from the REST response - usually API returns an array of these errors **/
type RestApiResponseErrorDetail = {
    name: string
    message: string
    /** input field that caused an error **/
    input: string
    /** error location in API services **/
    loc: string[]
    /** error message **/
    msg: string
    /** error type **/
    type: string
}

// based on the example of optional_extras_set in order service
// sometimes error returns this type
// need to be sorted out by BE
type RestApiResponseErrorDetail2 = {
    source: string
    code: string
    message: string
    MessageId?: string
}

export type RestApiResponseError = {
    /** error body **/
    body: {
        detail: RestApiResponseErrorDetail[] | RestApiResponseErrorDetail2[] | string
        error: {
            message: string
            code: number | string
            details: string
        }
    }
    /** HTTP status code **/
    status?: number
}

export function getRestErrorMessage({
    errors,
    source,
    variables = {},
    correlationId,
}: {
    errors: RestApiResponseError
    source: string
    variables?: Record<string, any>
    correlationId?: string
}): CustomApiError[] {
    const errorCode = errors?.body?.error?.code ?? errors?.body?.error?.code ?? 0
    const errorType =
        errors?.status === 422 || (errorCode && errorCode !== 'UNHANDLED') ? 'error' : 'warn'

    if (Array.isArray(errors?.body?.detail)) {
        return errors?.body?.detail?.map((error, index) => {
            if ((error as RestApiResponseErrorDetail)?.msg) {
                const v1Error = error as RestApiResponseErrorDetail
                return {
                    name: source,
                    id: index++,
                    error_description: v1Error.msg,
                    error_code: errorCode || index,
                    error_type: errorType,
                    error_details: [
                        {
                            source: v1Error.input,
                            code: (errors.status ?? index).toString(),
                            message: v1Error.type,
                            path: v1Error?.loc?.join(', '),
                        },
                    ],
                }
            }
            if ((error as RestApiResponseErrorDetail2)?.message) {
                const v2Error = error as RestApiResponseErrorDetail2
                return {
                    name: source,
                    id: index++,
                    error_description: errors.body.error.message,
                    error_code: errorCode || index,
                    error_type: errorType,
                    error_details: [
                        {
                            source: v2Error.source,
                            code: v2Error.code,
                            message: v2Error.message,
                            MessageId: v2Error.MessageId,
                        },
                    ],
                }
            }
            return {
                name: source,
                id: index++,
                error_description: errors.body.error.message,
                error_code: errorCode || index,
                error_type: errorType,
                error_details: [],
            }
        })
    } else if (typeof errors?.body?.error?.message === 'string') {
        return [
            {
                name: source,
                id: 0,
                error_description: errors.body.error.message,
                error_code: errorCode,
                error_type: errorType,
                error_details: [
                    {
                        source: source,
                        code: errorCode ? String(errorCode) : '0',
                        message: errors.body.error.message,
                    },
                ],
            },
        ]
    }
    return [
        {
            name: source,
            id: 0,
            error_description: errors?.body?.detail ?? errors?.body?.error?.message,
            error_code: 0,
            error_type: errorType,
            error_details: [
                {
                    source: source,
                    code: errorCode ? String(errorCode) : '0',
                    message: errors?.body?.detail ?? errors?.body?.error?.message,
                },
            ],
        },
    ]
}
