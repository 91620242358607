import React from 'react'
import classnames from 'classnames'

import styles from './InlineSpinner.module.css'

type SpinnerProps = {
    text?: string
    onDarkBackground?: boolean
}
const InlineSpinner: React.FC<SpinnerProps> = ({ text, onDarkBackground = false }) => {
    const pathClassName = classnames(styles.path, {
        [styles[`path--alt`]]: onDarkBackground,
    })

    return (
        <div className={styles.spinner}>
            <svg className={styles.circle} viewBox='0 0 50 50'>
                <title>Spinner</title>
                <circle
                    className={pathClassName}
                    cx='25'
                    cy='25'
                    r='20'
                    fill='none'
                    strokeWidth='4'
                />
            </svg>
            {text && <span>{text}</span>}
        </div>
    )
}

export default InlineSpinner
