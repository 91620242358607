export const emptyUserData: GlobalContextUserData = {
    isActiveTenant: true,
    challengeName: '',
    companyName: '',
    companyTier: undefined,
    financeSystemId: '',
    firstName: '',
    lastName: '',
    tenantId: '',
    userEmail: '',
    userExpiry: undefined,
    userId: '',
    userName: '',
    userRoles: [],
}

export const apiUserData: GlobalContextUserData = {
    challengeName: '',
    companyName: 'Stage Traveltek Engineering',
    companyTier: 'CRUISEENTERPRISE',
    financeSystemId: '92d94e48ed6a489ea6577052de4d956f',
    firstName: 'api user',
    lastName: 'access',
    tenantId: '92d94e48ed6a489ea6577052de4d956f',
    userEmail: 'engineering+stage_api_user@traveltek.net',
    userExpiry: 17324178264,
    userId: 'c2b5b404-e051-709c-f51c-6c239f164bc3',
    userName: 'api user access',
    userRoles: ['AGENT', 'API'],
    // userGroups:[{title: "Default User Group", userGroupId: "c3fa90ac-5ab9-477c-afad-376bf151f91d",…}]
}
export const extractCognitoFields = (
    userCognitoData: Record<string, any> | undefined
): GlobalContextUserData => {
    let isActiveTenant = true
    let challengeName = ''
    let companyName = ''
    let companyTier: CompanyTierTypes | undefined = undefined
    let financeSystemId = ''
    let firstName = ''
    let lastName = ''
    let tenantId = ''
    let userEmail = ''
    let userExpiry = undefined
    let userId = ''
    let userName = ''
    const userRoles: UserRole[] = []

    /** Data structure from Auth.signIn or Auth.getCurrentAuthenticatedUser */
    if (userCognitoData?.signInUserSession?.idToken?.payload?.email) {
        isActiveTenant =
            userCognitoData.signInUserSession.idToken.payload?.['tenant:tenant_status'] !==
            'INACTIVE'
        userEmail = userCognitoData.signInUserSession.idToken.payload?.email
        companyName = userCognitoData.signInUserSession.idToken.payload?.['tenant:company_name']
        companyTier = userCognitoData.signInUserSession.idToken.payload?.[
            'tenant:company_tier'
        ] as CompanyTierTypes
        financeSystemId =
            userCognitoData.signInUserSession.idToken.payload?.['tenant:finance_system_id']
        firstName = userCognitoData.signInUserSession.idToken.payload?.given_name
        lastName = userCognitoData.signInUserSession.idToken.payload?.family_name
        tenantId = userCognitoData.signInUserSession.idToken.payload?.['custom:tenantid']
        userId = userCognitoData.signInUserSession.idToken.payload?.['custom:userid']
        userName =
            userCognitoData.signInUserSession.idToken.payload?.given_name +
            ' ' +
            userCognitoData.signInUserSession.idToken.payload?.family_name
        if (userCognitoData.signInUserSession.idToken.payload['cognito:groups'])
            userRoles.push(...userCognitoData.signInUserSession.idToken.payload['cognito:groups'])
        userExpiry = userCognitoData.signInUserSession.idToken.payload?.exp
    } else if (userCognitoData?.challengeParam) {
        /** Data structure from Auth.signIn when PASSWORD_CHALLENGE */
        challengeName = userCognitoData?.challengeName
        firstName = userCognitoData.challengeParam.userAttributes?.given_name
        lastName = userCognitoData.challengeParam.userAttributes?.family_name
        tenantId = userCognitoData.challengeParam.userAttributes?.['custom:tenantid']
        userEmail = userCognitoData.challengeParam.userAttributes?.email
        userName =
            userCognitoData.challengeParam.userAttributes?.given_name +
            ' ' +
            userCognitoData.challengeParam.userAttributes?.family_name
        userId = userCognitoData.username
    }

    return {
        isActiveTenant,
        challengeName,
        companyName,
        companyTier,
        financeSystemId,
        firstName,
        lastName,
        tenantId,
        userEmail,
        userExpiry,
        userId,
        userName,
        userRoles,
    }
}
