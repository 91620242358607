import React, { useRef, useEffect, useState, useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { datadogLogs } from '@datadog/browser-logs'
import { CognitoUser } from 'amazon-cognito-identity-js'

import Button from 'components/basics/Button/Button'
import Checkbox from 'components/basics/Input/Checkbox/Checkbox'
import FieldError from 'components/basics/FieldError/FieldError'
import FieldsRequired from 'components/basics/FieldsRequired/FieldsRequired'
import Heading from 'components/basics/Heading/Heading'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import Link from 'components/basics/Link/Link'
import Select from 'components/basics/Input/Select/Select'
import Text from 'components/basics/Text/Text'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import TempPasswordForm from 'components/sections/app/SaaSSignupForm/SaasTempPasswordForm/TempPasswordForm'
import NewPasswordForm from 'components/sections/cognito/NewPasswordForm/NewPasswordForm'
import { ROUTES } from 'components/sections/app/AppRoutes'
import callApi from 'services/callApi/callApi.service'
import { ALL_COMPANY_TIERS, ALLOWED_PROD_SIGN_UP_TIERS, HTTP_METHODS, REGEX } from 'utils/constants'
import { countryData } from 'data/country-codes'

import styles from './SaaSSignupForm.module.css'
import allContent from 'content/content'
const links = allContent.app.links

const content = allContent.signUpPage

type SaaSSignUpFormDataType = {
    firstName: string
    lastName: string
    emailAddress: string
    companyName: string
    companyLocation: string
}

type SassSignUpVariablesType = {
    user: {
        firstName: string
        lastName: string
        emailAddress: string
    }
    tenant: {
        companyName: string
        companyLocation: string
        companyTier: string
        companyType: string
    }
}

type SaaSSignupFormProps = {
    setUserData: React.Dispatch<React.SetStateAction<GlobalContextUserData>>
}

const signupUrl = process.env.REACT_APP_CONNECT_MANAGER_SERVICE_URL + '/signup'

const SaaSSignUpForm: React.FC<SaaSSignupFormProps> = ({ setUserData }) => {
    const [successfulSignup, setSuccessfulSignup] = useState<boolean>(false)
    const [signUpError, setSignUpError] = useState<string | null>(null)
    const [isSubmittingSignUp, setIsSubmittingSignUp] = useState(false)
    const [confirmationCheck, setConfirmationCheck] = useState<boolean>(false)
    const submittedEmailAddress = useRef('')
    const [newPassCognitoUser, setNewPassCognitoUser] = useState<CognitoUser | undefined>()

    /** Get the tier passed from TravelTek website link, map value to chargbee constant */
    const params = new URLSearchParams(window.location.search)
    const tierParam = params.get('tier')?.toUpperCase()
    const companyTier = useRef('')

    useEffect(() => {
        /** SETS COMPANY TIER FROM PARAM IN URL, or sends user back to marketing page if got here via login link etc.
         * useEffect is to prevent the logging happening on every render */
        if (process.env.REACT_APP_ENVIRONMENT_NAME === 'production') {
            if (
                /** Only the two current subscriptions that are on the marketing site are allowed in prod */
                tierParam &&
                ALLOWED_PROD_SIGN_UP_TIERS.includes(tierParam as CompanyTierTypes)
            ) {
                companyTier.current = tierParam as CompanyTierTypes
            } else {
                // No tier param, in prod GO TO MARKETING WEBSITE
                datadogLogs.logger.info('SaaS Sign up page, loaded without tier param')
                window.location.href = links.marketingTierChoiceWebsite
            }
        }

        if (process.env.REACT_APP_ENVIRONMENT_NAME !== 'production') {
            if (tierParam && ALL_COMPANY_TIERS.includes(tierParam as CompanyTierTypes)) {
                /** Any tier is allowed in Development and stage */
                companyTier.current = tierParam as CompanyTierTypes
            } else {
                window.alert(
                    'NO TIER PARAM - in prod you get bumped to you to marketing website (just add /?tier=CRUISESTARTER etc)'
                )
            }
        }
    }, [tierParam])

    const countryOptions = useMemo(() => {
        return countryData.map((country) => {
            return {
                value: country.iso2Code,
                text: country.flag + ' ' + country.name,
            }
        })
    }, [])

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<SaaSSignUpFormDataType>({
        mode: 'onBlur',
        reValidateMode: 'onChange', // only comes into effect after submit has been pressed... doesn't work for revalidating otherwise so not that useful
        defaultValues: {
            firstName: '',
            lastName: '',
            emailAddress: '',
            companyName: '',
            companyLocation: '',
        },
    })
    const SaaSSignUpForm = (
        <form
            className={styles.form}
            onSubmit={handleSubmit(
                async ({
                    firstName,
                    lastName,
                    emailAddress,
                    companyName,
                    companyLocation,
                }: SaaSSignUpFormDataType) => {
                    setIsSubmittingSignUp(true)
                    setSignUpError(null)
                    const SaaSSignupVariables: SassSignUpVariablesType = {
                        user: {
                            firstName: firstName,
                            lastName: lastName,
                            emailAddress: emailAddress,
                        },
                        tenant: {
                            companyName: companyName,
                            companyLocation: companyLocation,
                            companyTier: companyTier.current,
                            companyType: 'TRAVELAGENT',
                        },
                    }

                    submittedEmailAddress.current = emailAddress

                    callApi({
                        url: signupUrl,
                        variables: SaaSSignupVariables,
                        method: HTTP_METHODS.POST,
                        skipAuth: true,
                        source: 'SassSignup',
                    })
                        .then((response) => response.json())
                        .then((response) => {
                            if (response) {
                                setSuccessfulSignup(true)
                            }
                            setIsSubmittingSignUp(false)
                            reset() // clear form data on successfulSignup.
                        })
                        .catch((error) => {
                            let errorMessage
                            switch (error.message) {
                                case 'This company name is already in use. Please select another one.':
                                    errorMessage = content.signUpForm.errors.companyError
                                    break
                                case 'An Account with this email already exists.':
                                    errorMessage = content.signUpForm.errors.emailError
                                    break
                                default:
                                    errorMessage = content.signUpForm.errors.signupError
                            }
                            setSignUpError(errorMessage)
                            setIsSubmittingSignUp(false)
                        })
                }
            )}
        >
            <Controller
                control={control}
                name='firstName'
                rules={{
                    required: true,
                    minLength: 1,
                    maxLength: 30,
                    pattern: REGEX.NAME,
                }}
                render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                    <div className={styles['input-wrapper']}>
                        <LabelledInput
                            required={true}
                            htmlFor='first-name'
                            label={content.signUpForm.firstNameInput}
                            isErrored={!!errors.firstName}
                            disabled={isSubmittingSignUp}
                            aria-describedby='first-name-error-message'
                        >
                            <TextInput value={value} onChange={onChange} onBlur={onBlur} />
                        </LabelledInput>
                        <FieldError
                            inputId='first-name'
                            showError={!!errors.firstName}
                            errorMessage={content.signUpForm.errors.firstNameInput}
                        />
                    </div>
                )}
            />
            <Controller
                control={control}
                name='lastName'
                rules={{
                    required: true,
                    minLength: 1,
                    maxLength: 30,
                    pattern: REGEX.NAME,
                }}
                render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                    <div className={styles['input-wrapper']}>
                        <LabelledInput
                            required={true}
                            htmlFor='last-name'
                            label={content.signUpForm.lastNameInput}
                            aria-describedby='last-name-error-message'
                            isErrored={!!errors.lastName}
                            disabled={isSubmittingSignUp}
                        >
                            <TextInput value={value} onChange={onChange} onBlur={onBlur} />
                        </LabelledInput>
                        <FieldError
                            inputId='last-name'
                            showError={!!errors.lastName}
                            errorMessage={content.signUpForm.errors.lastNameInput}
                        />
                    </div>
                )}
            />
            <Controller
                control={control}
                name='companyName'
                rules={{
                    required: true,
                    minLength: 1,
                    maxLength: 30,
                    pattern: REGEX.COMPANY_NAME,
                }}
                render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                    <div className={styles['input-wrapper']}>
                        <LabelledInput
                            required={true}
                            htmlFor='company-name'
                            label={content.signUpForm.companyNameInput}
                            aria-describedby='company-name-error-message'
                            isErrored={!!errors.companyName}
                            disabled={isSubmittingSignUp}
                        >
                            <TextInput value={value} onChange={onChange} onBlur={onBlur} />
                        </LabelledInput>
                        <FieldError
                            inputId='company-name'
                            showError={!!errors.companyName}
                            errorMessage={content.signUpForm.errors.companyNameInput}
                        />
                    </div>
                )}
            />
            <Controller
                control={control}
                name='companyLocation'
                rules={{
                    required: true,
                    minLength: 2,
                    maxLength: 2,
                    pattern: /^[a-zA-Z]*$/,
                }}
                render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                    <div className={styles['input-wrapper']}>
                        <LabelledInput
                            htmlFor='company-location'
                            label={content.signUpForm.companyLocationInput}
                            required={true}
                            aria-describedby='company-location-error-message'
                            isErrored={!!errors.companyLocation}
                            disabled={isSubmittingSignUp}
                        >
                            <Select
                                placeholder={content.signUpForm.companyLocationInputPlaceHolder}
                                options={countryOptions}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        </LabelledInput>
                        <FieldError
                            inputId='company-location'
                            showError={!!errors.companyLocation}
                            errorMessage={content.signUpForm.errors.companyLocationInput}
                        />
                    </div>
                )}
            />
            <Controller
                control={control}
                name='emailAddress'
                rules={{
                    required: true,
                    minLength: 1,
                    maxLength: 300,
                    pattern: REGEX.EMAIL,
                }}
                render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                    <div className={styles['input-wrapper']}>
                        <LabelledInput
                            required={true}
                            htmlFor='email'
                            label={content.signUpForm.emailInput}
                            aria-describedby='email-error-message'
                            isErrored={!!errors.emailAddress}
                            disabled={isSubmittingSignUp}
                        >
                            <TextInput value={value} onChange={onChange} onBlur={onBlur} />
                        </LabelledInput>
                        <FieldError
                            inputId='email'
                            showError={!!errors.emailAddress}
                            errorMessage={content.signUpForm.errors.emailInput}
                        />
                    </div>
                )}
            />
            <div className={styles['input-wrapper']}>
                <LabelledInput htmlFor='company-tier' label={content.signUpForm.subscriptionTier}>
                    <TextInput
                        disabled={true}
                        value={companyTier.current || 'In Dev/Stage add url param!'}
                    />
                </LabelledInput>
            </div>
            <Checkbox
                value={confirmationCheck ? 'yes' : undefined}
                required={true}
                checked={confirmationCheck}
                text={
                    <span>
                        {content.signUpForm.confirmationCheckLabel}{' '}
                        <Link rel='noopener' target='_blank' href={content.signUpForm.tcsLinkUrl}>
                            {content.signUpForm.tcsLinkText}
                        </Link>
                    </span>
                }
                onChange={(): void => setConfirmationCheck(!confirmationCheck)}
                disabled={isSubmittingSignUp}
            />
            <div className={styles['submit-login-wrapper']}>
                <div className={styles['submit-login-container']}>
                    <FieldsRequired />
                    <Button
                        type='submit'
                        flavour='primary'
                        disabled={!confirmationCheck}
                        text={
                            isSubmittingSignUp
                                ? content.signUpForm.submitButtonProcessing
                                : content.signUpForm.submitButton
                        }
                        showSpinner={isSubmittingSignUp}
                    />
                    <p className={styles['login-text']}>
                        {content.signUpForm.loginText}
                        <Link to={ROUTES.LOGIN} bold={true}>
                            {content.signUpForm.loginLinkText}
                        </Link>
                    </p>
                </div>
            </div>
            <div className={styles['signup-error-message']}>
                {signUpError && (
                    <InfoBanner bannerType='error' text={signUpError} id='submit-api-error' />
                )}
            </div>
        </form>
    )

    return (
        <>
            {!successfulSignup || !newPassCognitoUser ? (
                <div className={styles.container}>
                    <Heading heading='1'>
                        {successfulSignup
                            ? content.tempPasswordForm.successSignUpMessage1
                            : content.title}
                    </Heading>
                    {successfulSignup && !newPassCognitoUser && (
                        <Text>{content.tempPasswordForm.successSignUpMessage2}</Text>
                    )}
                    {!successfulSignup && SaaSSignUpForm}
                    {successfulSignup && !newPassCognitoUser && (
                        <TempPasswordForm
                            userEmail={submittedEmailAddress.current}
                            setNewPassCognitoUser={setNewPassCognitoUser}
                        />
                    )}
                </div>
            ) : (
                <NewPasswordForm
                    setUserData={setUserData}
                    newPassCognitoUser={newPassCognitoUser}
                    setNewPassCognitoUser={setNewPassCognitoUser}
                />
            )}
        </>
    )
}

export default SaaSSignUpForm
