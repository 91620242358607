import React, { useState, useCallback, ChangeEvent } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { RenderInputComponentProps } from 'react-autosuggest'

import Autosuggest from 'components/blocks/Autosuggest/Autosuggest'
import Button from 'components/basics/Button/Button'
import DateRangePicker from 'components/blocks/DateRangePicker/DateRangePicker'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import Select from 'components/basics/Input/Select/Select'
import Text from 'components/basics/Text/Text'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import { OnSubmitSearchBarData } from 'components/pages/cruise/SearchPage'
import CategoryIndicator, {
    DESTINATION_CATEGORIES,
    DestinationCategory,
} from 'components/sections/cruise/CategoryIndicator/CategoryIndicator'
import { DestinationDataItem } from 'components/layouts/cruise/SearchLayout/SearchLayout'
import { objectArrayFilterAndSortByMatcher } from 'utils/objectArrayFilterAndSortByMatcher'

import styles from './SearchBar.module.css'
import allContent from 'content/content'

const content = allContent.cruise.searchPage.searchBar

const NUMBER_OF_DESTINATIONS_TO_SUGGEST = 99999
export const durationOptions = [
    { text: 'Any', value: '' },
    { text: '1 - 4 Nights', value: '1-4' },
    { text: '5 - 10 Nights', value: '5-10' },
    { text: '11 - 16 Nights', value: '11-16' },
    { text: '17 - 31 Nights', value: '17-31' },
    { text: '+32 Nights', value: '32-999' },
]

export const magicSearchWords = {
    ALL_PORTS: 'ports',
    ALL_DESTINATIONS: 'destinations',
    ALL_REGIONS: 'regions',
    ALL_COUNTRIES: 'countries',
    ALL_CRUISELINES: 'cruiselines',
    ALL_SHIPS: 'ships',
}

export const getAutoSuggestions = ({
    searchString,
    destinationData,
    setDestinationSuggestionsData,
}: {
    searchString: string
    destinationData: DestinationDataItem[]
    setDestinationSuggestionsData(value: any): void
}): void => {
    /** THESE if statements all user to pick from the full list of countries, ship names etc. by typing these keys words
     * Just a POC, as better to use a checkbox or something simpler, and knowable. */
    if (searchString.toLowerCase() === magicSearchWords.ALL_PORTS) {
        const justPorts = destinationData.filter(
            (destination) => destination.category === DESTINATION_CATEGORIES.PORT
        )
        setDestinationSuggestionsData(justPorts)
    } else if (searchString.toLowerCase() === magicSearchWords.ALL_DESTINATIONS) {
        const justDestinations = destinationData.filter(
            (destination) => destination.category === DESTINATION_CATEGORIES.DESTINATION
        )
        setDestinationSuggestionsData(justDestinations)
    } else if (searchString.toLowerCase() === magicSearchWords.ALL_REGIONS) {
        const justRegions = destinationData.filter(
            (destination) => destination.category === DESTINATION_CATEGORIES.REGION
        )
        setDestinationSuggestionsData(justRegions)
    } else if (searchString.toLowerCase() === magicSearchWords.ALL_COUNTRIES) {
        const justCountries = destinationData.filter(
            (destination) => destination.category === DESTINATION_CATEGORIES.COUNTRY
        )
        setDestinationSuggestionsData(justCountries)
    } else if (searchString.toLowerCase() === magicSearchWords.ALL_CRUISELINES) {
        const justSuppliers = destinationData.filter(
            (destination) => destination.category === DESTINATION_CATEGORIES.SUPPLIER
        )
        setDestinationSuggestionsData(justSuppliers)
    } else if (searchString.toLowerCase() === magicSearchWords.ALL_SHIPS) {
        const justShips = destinationData.filter(
            (destination) => destination.category === DESTINATION_CATEGORIES.SHIP
        )
        setDestinationSuggestionsData(justShips)
    } else {
        const filteredSuggestionsBySearchString = destinationData.filter((destination) => {
            const name = destination.name.toLowerCase()
            return name.includes(searchString.toLowerCase())
        })
        const weightedOrderedDestinations: DestinationDataItem[] =
            objectArrayFilterAndSortByMatcher({
                objectArray: filteredSuggestionsBySearchString,
                searchString: searchString,
                key: 'name',
            }) as DestinationDataItem[]
        const topSliceOfDestinations = weightedOrderedDestinations.slice(
            0,
            NUMBER_OF_DESTINATIONS_TO_SUGGEST
        )
        setDestinationSuggestionsData(topSliceOfDestinations)
    }
}

export type SearchBarProps = {
    /** callback for handleSubmit to call that captures our custom behaviour for submit
     * which is to pass just the search bar fields in first variable and all the form
     * which includes advanced option inputs in the second variable.
     */
    onSubmit: (data: OnSubmitSearchBarData, allFormFields: Record<string, any>) => void
    destinationData: { name: string; category: DestinationCategory }[]
    /** boolean to hide auto suggest when data no returned, feature toggle is off or user email is not on allowed list (traveltek and appvales) */
    useAutoSuggest: boolean
    /** prePopulateDestinationCategory is used to pre-populate the destination category type for api submission when data is pulled from storage rather than autoSuggest select */
    prePopulateDestinationCategory?: DestinationCategory
}

const SearchBar: React.FC<SearchBarProps> = ({
    onSubmit,
    destinationData,
    useAutoSuggest = false,
    prePopulateDestinationCategory = DESTINATION_CATEGORIES.DESTINATION,
}) => {
    const { control, getValues, handleSubmit, setValue, watch } = useFormContext()
    const [destinationSuggestionsData, setDestinationSuggestionsData] = useState<
        DestinationDataItem[]
    >([])
    const initialEmbarkEarliestDate = getValues('embarkEarliestDate')
    const initialEmbarkLatestDate = getValues('embarkLatestDate')
    const [defaultStartDate] = useState<Date | null>(initialEmbarkEarliestDate)
    const [defaultEndDate] = useState<Date | null>(initialEmbarkLatestDate)
    const [destinationInputCategory, setDestinationInputCategory] = useState<DestinationCategory>(
        prePopulateDestinationCategory
    )
    const fetchDestinationOptions = (searchString: string): void => {
        return getAutoSuggestions({
            searchString,
            destinationData,
            setDestinationSuggestionsData,
        })
    }

    const renderDestinationItem = (destination: DestinationDataItem): React.ReactElement => (
        <div className={styles['destination-list-item']}>
            <Text>
                {destination.name}
                {destination?.hits && <Text color='disabled'>{` (${destination?.hits})`}</Text>}
            </Text>
            <CategoryIndicator category={destination.category} />
        </div>
    )

    const setDestinationInputString = (value: string): string => {
        setValue('destination', value)
        return value
    }

    const getDestinationSuggestionValue = (location: DestinationDataItem): string => {
        // TODO: TEMP(ish) FIX - submit region values as destination (fuzzy search) while the country mapping isn't perfect
        if (location?.category === DESTINATION_CATEGORIES.REGION) {
            setDestinationInputCategory(DESTINATION_CATEGORIES.REGION)
        } else {
            setDestinationInputCategory(location?.category || DESTINATION_CATEGORIES.DESTINATION)
        }
        return location.name
    }

    function handleDepartureOnChange(startDate: Date | null, endDate: Date | null): void {
        setValue('embarkEarliestDate', startDate)
        setValue('embarkLatestDate', endDate)
    }

    const durationOnChange = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            setValue('duration', e.target.value)
        },
        [setValue]
    )

    return (
        <search title='cruises'>
            <form
                className={styles['search-bar']}
                onSubmit={handleSubmit((fieldValues) => {
                    const { embarkEarliestDate, embarkLatestDate, destination, duration } =
                        fieldValues
                    const allFormFields = watch()
                    const searchFieldsToSaveFor15Mins = {
                        destinationInputCategory: destinationInputCategory,
                        ...(destination && { destination }),
                        ...(embarkEarliestDate && { embarkEarliestDate }),
                        ...(embarkLatestDate && { embarkLatestDate }),
                        ...(duration && { duration }),
                    }
                    const allData = {
                        ...allFormFields,
                        destinationInputCategory: destinationInputCategory,
                    }
                    // BOTH VARS ARE THE SAME VALUE, WHY SUBMIT SAME THING TWICE?
                    onSubmit(searchFieldsToSaveFor15Mins, allData)
                })}
            >
                <div className={styles.destination}>
                    <Controller
                        control={control}
                        name='destination'
                        render={({ field: { value } }): React.ReactElement => {
                            if (useAutoSuggest) {
                                return (
                                    <Autosuggest
                                        getSuggestionValue={getDestinationSuggestionValue}
                                        id='supplier-autosuggest'
                                        inputValue={getValues('destination')}
                                        maxHeight={600}
                                        minWidth={450}
                                        name='destination'
                                        onBlur={setDestinationInputString}
                                        openSuggestionsOnFocus={destinationData.length > 0}
                                        onSuggestionsClearRequested={(): void =>
                                            setDestinationSuggestionsData([])
                                        }
                                        onSuggestionSelected={setDestinationInputString}
                                        overflowY={true}
                                        positionAbsolute={true}
                                        renderSuggestion={renderDestinationItem}
                                        setInputValue={setDestinationInputString}
                                        suggestionsData={destinationSuggestionsData}
                                        suggestionsFetchRequest={fetchDestinationOptions}
                                        renderInputComponent={({
                                            ...rest
                                        }: RenderInputComponentProps): React.ReactElement => (
                                            <LabelledInput
                                                autoComplete='off'
                                                label={content.destination}
                                                placeholder={content.destinationPlaceHolder}
                                                labelColor='white'
                                                htmlFor='destination'
                                                {...rest}
                                            >
                                                <TextInput clearable={true} />
                                            </LabelledInput>
                                        )}
                                    />
                                )
                            } else {
                                return (
                                    <LabelledInput
                                        className={styles.destination}
                                        label={content.destination}
                                        htmlFor='destination'
                                        labelColor='white'
                                    >
                                        <TextInput
                                            value={value}
                                            autoComplete='off'
                                            name='destination'
                                            placeholder={content.destinationPlaceHolder}
                                            onChange={(e: any): void =>
                                                setValue('destination', e.target.value)
                                            }
                                        />
                                    </LabelledInput>
                                )
                            }
                        }}
                    />
                </div>
                <Controller
                    name='departureDateRange'
                    control={control}
                    render={(): React.ReactElement => (
                        <DateRangePicker
                            className={styles['date-range']}
                            placeholderText={content.durationPlaceHolder}
                            autoComplete='off'
                            customInput={
                                <LabelledInput
                                    htmlFor='departure-date-range'
                                    label={content.departure}
                                    labelColor='white'
                                >
                                    <TextInput
                                        iconName='Calendar'
                                        clearable={true}
                                        onKeyDown={(e): void => {
                                            e.preventDefault() // this stops typing into input - manually typing the date doesn't set the value so shouldn't let people try.
                                        }}
                                    />
                                </LabelledInput>
                            }
                            initialStartDate={defaultStartDate}
                            initialEndDate={defaultEndDate}
                            onChangeCallBack={handleDepartureOnChange}
                        />
                    )}
                />
                <Controller
                    name='duration'
                    control={control}
                    render={({ field: { value } }): React.ReactElement => (
                        <LabelledInput
                            htmlFor='duration'
                            label={content.duration}
                            labelColor='white'
                        >
                            <Select
                                value={value}
                                options={durationOptions}
                                onChange={(e): void => {
                                    durationOnChange(e)
                                }}
                            />
                        </LabelledInput>
                    )}
                />
                <Button
                    onDarkBackground={true}
                    iconName='Search'
                    type='submit'
                    text={content.searchButton}
                />
            </form>
        </search>
    )
}

export default SearchBar
