import React, { Dispatch, SetStateAction } from 'react'
import classnames from 'classnames'

import Button from 'components/basics/Button/Button'
import DescriptionList from 'components/basics/DescriptionList/DescriptionList'
import DescriptionListItemKey from 'components/basics/DescriptionList/DescriptionListItemKey'
import DescriptionListItemValue from 'components/basics/DescriptionList/DescriptionListItemValue'
import Link from 'components/basics/Link/Link'
import Text from 'components/basics/Text/Text'

import { ROUTES } from 'components/sections/app/AppRoutes'
import { CURRENCY_CODES } from 'utils/constants'
import { calculateHourDifference } from 'utils/date-helpers'
import { Pricing, CabinTypePricing } from 'api-data-models/CruisesContentModel'

import styles from './PricingSection.module.scss'
import allContent from 'content/content'

const content = allContent.cruise.resultsPage.resultsListItem.pricingSection
const cabinCategories = allContent.cruise.resultsPage.resultsListItem.cabinCategory
const alternativeSailingDatesContent = allContent.cruise.sharedSections.alternativeSailingDates

type PricingProps = {
    /** pricing data from cruise */
    pricing: Pricing
    /** unique key on each cruise used for navigation to details page */
    cruiseId: string
    /** the supplier code, used to populate query params on navigate */
    supplierCode: string
    /** are alternative sailing available for this cruise */
    hasAlternativeSailings: boolean
    /** the embarkDate to validate if it more than 48 hours to it */
    embarkDate: string
    /** alternative sailing dates section open state */
    alternativeSailDatesOpen: boolean
    /** set alternative sailing dates section open stae*/
    setAlternativeSailDatesOpen: Dispatch<SetStateAction<boolean>>
    /** used to not show cruise select button */
    isStarterTier: boolean
    /** also used to not show cruise book button */
    cruiseAvailableToBook: boolean
}

const PricingSection: React.FC<PricingProps> = ({
    pricing,
    cruiseId,
    supplierCode,
    hasAlternativeSailings,
    embarkDate,
    alternativeSailDatesOpen,
    setAlternativeSailDatesOpen,
    isStarterTier,
    cruiseAvailableToBook,
}): JSX.Element => {
    const noPrices = pricing.cabinTypePricing.length === 0
    const inAvailableTimeRange = calculateHourDifference(embarkDate) > 48
    const selectButtonText = noPrices ? content.selectButton.call : content.selectButton.select
    const pricingList = []

    const showButtonToNextPage =
        cruiseAvailableToBook && !isStarterTier && !noPrices && inAvailableTimeRange

    if (!noPrices) {
        pricing.cabinTypePricing.forEach((cabinTypePricing: CabinTypePricing) => {
            if (cabinTypePricing.available)
                pricingList.push(
                    <div key={cabinTypePricing.cabinType} className={styles['pricing-item']}>
                        <DescriptionListItemKey>
                            <Text color='white'>
                                {cabinCategories[`${cabinTypePricing.cabinType}`]}
                            </Text>
                        </DescriptionListItemKey>
                        <DescriptionListItemValue className={styles.fares}>
                            <Text weight='bold' color='white'>
                                {CURRENCY_CODES[`${pricing.currencyCode}`]}
                            </Text>
                            <Text weight='bold' color='white'>
                                {`${cabinTypePricing.fareStringRounded}`}{' '}
                            </Text>{' '}
                            <Text color='white' weight='bold' size='XS'>
                                {pricing.currencyCode}
                            </Text>
                        </DescriptionListItemValue>
                    </div>
                )
        })
    } else {
        pricingList.push(
            <p key='call-to-book' className={styles['center-align']}>
                <Text color='white' weight='bold'>
                    {content.callToBook}
                </Text>
            </p>
        )
    }

    const containerClassNames = classnames(styles.container, {
        [styles['no-bottom-border-radius']]: alternativeSailDatesOpen,
    })

    return (
        <div className={containerClassNames}>
            <DescriptionList className={styles['pricing-items']}>{pricingList}</DescriptionList>
            {!inAvailableTimeRange ? (
                <p key='call-to-book' className={styles['center-align']}>
                    <Text color='white' weight='bold'>
                        {content.callToBookWithin48hours}
                    </Text>
                </p>
            ) : null}
            <div className={styles['button-container']}>
                {showButtonToNextPage &&
                    (!cruiseAvailableToBook || noPrices ? (
                        <Button
                            text={selectButtonText}
                            type='button'
                            disabled={true}
                            className={styles['select-button']}
                        />
                    ) : (
                        <Link
                            noUnderLine={true}
                            to={`${ROUTES.CRUISE_SAILING}/?cruiseId=${cruiseId}&supplierCode=${supplierCode}`}
                        >
                            <Button
                                text={selectButtonText}
                                type='button'
                                className={styles['select-button']}
                            />
                        </Link>
                    ))}
                {hasAlternativeSailings && (
                    <Button
                        text={
                            alternativeSailDatesOpen
                                ? alternativeSailingDatesContent.buttons.hideDates
                                : alternativeSailingDatesContent.buttons.showDates
                        }
                        type='button'
                        flavour='tertiary'
                        onDarkBackground
                        onClick={(): void => setAlternativeSailDatesOpen(!alternativeSailDatesOpen)}
                        className={styles['alternative-sailings-button']}
                    />
                )}
            </div>
        </div>
    )
}

export default PricingSection
