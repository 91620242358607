import React, { useRef, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { datadogLogs } from '@datadog/browser-logs'

import Button from 'components/basics/Button/Button'
import FieldError from 'components/basics/FieldError/FieldError'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import InlineSpinner from 'components/basics/Spinners/InlineSpinner'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import Modal from 'components/blocks/Modal/Modal'
import Select from 'components/basics/Input/Select/Select'
import Text from 'components/basics/Text/Text'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import { Group } from 'api-data-models/admin/GroupsContentModel'
import * as self from './AddUserButton'

import { AGENT_CONNECT_USER_ADD } from 'graphql-queries/admin/admin-queries'
import { REGEX, USER_ROLES } from 'utils/constants'

import styles from './AddUserButton.module.css'
import allContent from 'content/content'

const content = allContent.admin.userManagementPage.addUserButton

type AddUserData = {
    emailAddress: string
    firstName: string
    lastName: string
    userGroupId?: string
    groupId?: string
}

type OnSubmitProps = {
    formData: AddUserData
    apiClient: ApolloClient<NormalizedCacheObject>
    setSubmitting: React.Dispatch<React.SetStateAction<boolean>>
    setApiResponseStatus: React.Dispatch<React.SetStateAction<string | null>>
    setApiError: React.Dispatch<React.SetStateAction<any>>
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setNewUserEmail: React.Dispatch<React.SetStateAction<string | null>>
    isApiUser: boolean
}
export const onSubmit = ({
    formData,
    apiClient,
    setSubmitting,
    setApiResponseStatus,
    setApiError,
    setIsModalOpen,
    setNewUserEmail,
    isApiUser,
}: OnSubmitProps): void => {
    setSubmitting(true)
    apiClient // CONNECT_MANAGER_SERVICE
        .mutate({
            mutation: AGENT_CONNECT_USER_ADD,
            variables: {
                emailAddress: formData.emailAddress,
                firstName: formData.firstName,
                lastName: formData.lastName,
                userGroupId: formData.userGroupId,
                userRoles: isApiUser ? [USER_ROLES.API_USER] : [USER_ROLES.AGENT],
            },
        })
        .then((response) => {
            setNewUserEmail(formData.emailAddress)
            if (response.data) {
                const status = response.data?.agentConnectUserAdd?.status
                setApiResponseStatus(status)
                datadogLogs.logger.info(
                    `Mutate AGENT_CONNECT_USER_ADD Successfully created user: ${formData.emailAddress}`
                )
            }
            setIsModalOpen(false)
            setSubmitting(false)
        })
        .catch((error) => {
            datadogLogs.logger.error(
                `Mutate AGENT_CONNECT_USER_ADD AddUserButton - error: ${JSON.stringify({ error })}`
            )
            const graphErrors = error?.graphQLErrors ?? undefined
            const errorMessage =
                graphErrors?.length > 0 ? graphErrors[0]?.message : content.errors.defaultApiError
            setApiError(errorMessage)
            setIsModalOpen(false)
            setSubmitting(false)
        })
}

type AddUseButtonProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
    setApiError: React.Dispatch<React.SetStateAction<any>>
    setApiResponseStatus: React.Dispatch<React.SetStateAction<string | null>>
    groupsListData: Group[]
    fetchingGroups: boolean
    groupsApiErrorMessage: null | string
    setNewUserEmail: React.Dispatch<React.SetStateAction<string | null>>
    isApiUser?: boolean
}

const AddUserButton: React.FC<AddUseButtonProps> = ({
    apiClient,
    setApiError,
    setApiResponseStatus,
    groupsListData,
    fetchingGroups,
    groupsApiErrorMessage,
    setNewUserEmail,
    isApiUser = false,
}): React.ReactElement => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [submitting, setSubmitting] = useState<boolean>(false)
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            emailAddress: '',
            firstName: isApiUser ? 'Api' : '',
            lastName: isApiUser ? 'Access' : '',
            userGroupId: '',
        },
    })

    const groupsOptions = groupsListData.map((group) => {
        return {
            text: group.groupTitle,
            value: group.groupId,
        }
    })

    const addUserButtonRef = useRef<HTMLButtonElement | null>(null)
    const addUserButtonId = 'open-add-user-modal-button-id'

    const handleOnAddUserClick = (): void => {
        setApiError(null)
        setApiResponseStatus(null)
        setIsModalOpen(true)
        reset()
    }

    const handleCloseModal = (): void => {
        setIsModalOpen(false)
        reset()
        if (addUserButtonRef.current) {
            // This is for when the user clicks the cancel button which the modal has no knowledge of (can't use ID passed in to set focus)
            addUserButtonRef.current.focus()
        }
    }

    return (
        <div>
            <Button
                id={addUserButtonId}
                text={isApiUser ? content.addApiUserButton : content.addUserButton}
                type='button'
                onClick={(): void => handleOnAddUserClick()}
                ref={addUserButtonRef}
            />

            <Modal
                headerText={isApiUser ? content.modal.titleApi : content.modal.title}
                isOpen={isModalOpen}
                returnFocusId={addUserButtonId}
                setClosed={handleCloseModal}
                className={styles.wrapper}
            >
                <form
                    className={styles.form}
                    onSubmit={handleSubmit((formData): void =>
                        self.onSubmit({
                            formData,
                            apiClient,
                            setSubmitting,
                            setApiResponseStatus,
                            setApiError,
                            setIsModalOpen,
                            setNewUserEmail,
                            isApiUser,
                        })
                    )}
                >
                    <p>
                        <Text>{content.text1}</Text>
                    </p>
                    <p>
                        <Text>{content.text2}</Text>
                    </p>
                    <p>
                        <Text>{content.text3}</Text>
                    </p>
                    {groupsApiErrorMessage && (
                        <InfoBanner
                            bannerType='error'
                            text={groupsApiErrorMessage}
                            id='groups-api-error-banner'
                        />
                    )}
                    <Controller
                        control={control}
                        name='firstName'
                        rules={{
                            required: true,
                            minLength: 1,
                            maxLength: 30,
                            pattern: REGEX.NAME,
                        }}
                        render={({ field: { onBlur, onChange, value } }): React.ReactElement => (
                            <div>
                                <LabelledInput
                                    required={true}
                                    htmlFor='first-name'
                                    label={content.modal.firstName}
                                    aria-describedby={content.modal.firstName}
                                    disabled={submitting || isApiUser}
                                    isErrored={!!errors.firstName}
                                >
                                    <TextInput
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        required={true}
                                    />
                                </LabelledInput>
                                <FieldError
                                    inputId='first-name'
                                    showError={!!errors.firstName}
                                    errorMessage={content.errors.firstNameInput}
                                />
                            </div>
                        )}
                    />
                    <Controller
                        control={control}
                        name='lastName'
                        rules={{
                            required: true,
                            minLength: 1,
                            maxLength: 30,
                            pattern: REGEX.NAME,
                        }}
                        render={({ field: { onBlur, onChange, value } }): React.ReactElement => (
                            <div>
                                <LabelledInput
                                    required={true}
                                    htmlFor='last-name'
                                    label={content.modal.lastName}
                                    aria-describedby={content.modal.lastName}
                                    disabled={submitting || isApiUser}
                                    isErrored={!!errors.lastName}
                                >
                                    <TextInput
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        required={true}
                                    />
                                </LabelledInput>
                                <FieldError
                                    inputId='last-name'
                                    showError={!!errors.lastName}
                                    errorMessage={content.errors.lastNameInput}
                                />
                            </div>
                        )}
                    />

                    <Controller
                        control={control}
                        name='emailAddress'
                        rules={{
                            required: true,
                            minLength: 1,
                            maxLength: 300,
                            pattern: REGEX.EMAIL,
                        }}
                        render={({ field: { onBlur, onChange, value } }): React.ReactElement => (
                            <div>
                                <LabelledInput
                                    required={true}
                                    htmlFor='email-address'
                                    label={content.modal.email}
                                    aria-describedby={content.modal.email}
                                    disabled={submitting}
                                    isErrored={!!errors.emailAddress}
                                >
                                    <TextInput
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        required={true}
                                    />
                                </LabelledInput>
                                <FieldError
                                    inputId='email-address'
                                    showError={!!errors.emailAddress}
                                    errorMessage={content.errors.emailInput}
                                />
                            </div>
                        )}
                    />
                    <Controller
                        control={control}
                        name='userGroupId'
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onBlur, onChange, value } }): React.ReactElement => {
                            return (
                                <div>
                                    <LabelledInput
                                        required={true}
                                        htmlFor='group-id'
                                        label={content.modal.groupsLabelText}
                                        aria-describedby={content.modal.groupsLabelText}
                                        disabled={submitting}
                                        isErrored={!!errors.userGroupId}
                                    >
                                        {!fetchingGroups ? (
                                            <Select
                                                placeholder={content.modal.groupsPlaceHolderText}
                                                options={groupsOptions}
                                                value={value}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                required={true}
                                            />
                                        ) : (
                                            <div className={styles['spinner']}>
                                                <InlineSpinner
                                                    text={content.modal.loadingGroupsData}
                                                />
                                            </div>
                                        )}
                                    </LabelledInput>
                                    <FieldError
                                        inputId='last-name'
                                        showError={!!errors.userGroupId}
                                        errorMessage={content.errors.groupId}
                                    />
                                </div>
                            )
                        }}
                    />
                    <div className={styles['button-wrapper']}>
                        <Button
                            type='button'
                            flavour='tertiary'
                            text={content.modal.cancelButton}
                            onClick={handleCloseModal}
                            disabled={submitting}
                        />
                        <Button
                            text={
                                submitting
                                    ? content.modal.submittingButton
                                    : content.modal.confirmButton
                            }
                            type='submit'
                            showSpinner={submitting}
                        />
                    </div>
                </form>
            </Modal>
        </div>
    )
}
export default AddUserButton
